import React, { useState } from 'react';
import { FaBullhorn } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';

// HOOKS
import useFiles from '../../hooks/useFiles';
import Web3 from 'web3';
import { useEffect } from 'react';
import useStream from '../../hooks/useStream';
import { useTranslation } from 'react-i18next';

function SetPromotionPrice() {
    const { promotionPrice, setTransactionLoading, loadPromotionPrice } = useFiles();
    const { streamContract, streamAbi } = useStream();
    const [settedPromotionPrice, setPromotionPrice] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (streamContract) {
            loadPromotionPrice(streamContract, streamAbi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamContract, streamAbi]);

    /* --------------------------------------------- 
              TRANSFER OWNERSHIP FUNCTION
     --------------------------------------------- */
    const { write: web3SetPromotionPrice } = useContractWrite({
        address: streamContract?.address,
        abi: streamAbi,
        functionName: 'setPromotionPrice',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                setSubmitted(false);
                setPromotionPrice('');
                loadPromotionPrice(streamContract, streamAbi);
                toast.success(t('promotionPriceAdded'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    function setPromotionPriceHandler(e) {
        e.preventDefault();
        setSubmitted(true);
        if (settedPromotionPrice !== '' && settedPromotionPrice) {
            const formattedPrice = Web3.utils.toWei(settedPromotionPrice.toString(), 'ether');
            web3SetPromotionPrice({
                recklesslySetUnpreparedArgs: [formattedPrice],
            });
        }
    }

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-turquoise'>
                        <FaBullhorn size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('setPromotionPrice')}</h2>
                        <p className='text-muted fw-normal mb-0'>
                            {t('currentPriceIs')} <strong className='fw-bold text-primary'>{promotionPrice} USD</strong>
                        </p>
                    </div>
                </div>

                <form onSubmit={setPromotionPriceHandler}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='d-flex align-items-end'>
                                <div className='me-2 w-100'>
                                    <label className='form-label' htmlFor='promotionPrice'>
                                        {t('promotionPrice')}
                                    </label>
                                    <input
                                        type='number'
                                        id='promotionPrice'
                                        className={`form-control ${
                                            submitted && settedPromotionPrice === '' ? 'is-invalid' : ''
                                        }`}
                                        placeholder={`${t('promotionPricePlaceholder')} in USD`}
                                        value={settedPromotionPrice}
                                        onChange={(e) => setPromotionPrice(e.target.value)}
                                    />
                                </div>
                                <button className='btn btn-primary flex-shrink-0' type='submit'>
                                    {t('setPrice')}
                                </button>
                            </div>
                            {submitted && settedPromotionPrice === '' && (
                                <div className='invalid-feedback d-block'>{t('promotionPriceErrorMsg')}</div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SetPromotionPrice;
