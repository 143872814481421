import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import { useTranslation } from 'react-i18next';

function BuyDifiPage() {
    const { t } = useTranslation();

    return (
        <>
            <PageBanner heading={t('buy')} text=' SOLE'></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-12 mx-auto'>
                            <div className='text-center'>
							                                {/* Mantieni il vecchio iframe del form di acquisto e vendita */}
                                {/* Aggiungi il nuovo codice per il grafico */}
                                <style>
                                    {`
                                        #dexscreener-embed {
                                            position: relative;
                                            width: 100%;
                                            padding-bottom: 125%;
                                        }
                                        @media (min-width: 1400px) {
                                            #dexscreener-embed {
                                                padding-bottom: 65%;
                                            }
                                        }
                                        #dexscreener-embed iframe {
                                            position: absolute;
                                            width: 100%;
                                            height: 700px;
                                            top: 0;
                                            left: 0;
                                            border: 0;
                                        }
                                    `}
                                </style>
                                <div id="dexscreener-embed">
                                    <iframe src="https://dexscreener.com/ethereum/0xCd835CfaC5d158A7b11Ac713b64446E8AdB3D7A8?embed=1&theme=dark&info=0"></iframe>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BuyDifiPage;
