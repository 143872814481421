import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Web3Provider from './providers/Web3Provider';
import FilesProvider from './providers/FilesProvider';
import StreamProvider from './providers/StreamProvider';
import AppProvider from './providers/AppProvider';
import './helpers/localization.js';

ReactDOM.render(
    <Web3Provider>
        <AppProvider>
            <FilesProvider>
                <StreamProvider>
                    <App />
                </StreamProvider>
            </FilesProvider>
        </AppProvider>
    </Web3Provider>,
    document.getElementById('root')
);
