/* ------------------------------------------------------------------------------------------ 
      RUSSIAN TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const russianTranslation = {
    recentlyAdded: 'Недавно добавленные',
    topTrending: 'Топ Трендов',
    featuredItems: 'Рекомендуемые файлы',
    noCategoryMatch: 'Нет файлов, соответствующих этой категории',
    noReviewsYet: 'Пока нет отзывов',
    accessNow: 'Получить доступ сейчас',
    downloadNow: 'Скачать сейчас',
    accessFileFor: 'Получите доступ к этому файлу всего за',
    youHaveAccess: 'У вас уже есть доступ к этому файлу',
    viewScreenshots: 'Просмотреть скриншоты',
    promoted: 'Рекламируется',
    addToList: 'Добавить в список',
    inList: 'В вашем списке',
    fileReviews: 'Отзывы о файле',
    fileGallery: 'Галерея файлов',
    pending: 'Ожидает',
    pendingMsg: 'Этот файл ожидает подтверждения администратором',
    from: 'От',
    ratings: 'Рейтинги',
    promoteThisFile: 'Продвигать этот файл',
    promoteFor: 'Продвигать за',
    youHaveReview: 'Вы уже оставили отзыв об этом файле',
    rateThisFile: 'Оценить этот файл',
    fileHasNoReviews: 'У этого файла пока нет отзывов',
    discoverAll: 'Открыть все файлы',
    filterByCategory: 'Фильтровать по категории',
    sortByDate: 'Сортировать по дате',
    sortByPrice: 'Сортировать по цене',
    loadMore: 'Загрузить еще',
    select: 'Выбрать',
    all: 'Все',
    noMatches: 'Нет файлов, соответствующих этому фильтру',
    newest: 'Сначала новые',
    oldest: 'Сначала старые',
    highPrice: 'Цена: от высокой к низкой',
    lowPrice: 'Цена: от низкой к высокой',
    headerLinks: 'Ссылки',
    headerDiscover: 'Открытие',
    headerActivity: 'Активность',
    headerFaqs: 'Часто задаваемые вопросы',
    headerContactUs: 'Свяжитесь с нами',
    trackTransaction: 'Отслеживание транзакций',
    headerMyAccount: 'Моя учетная запись',
    headerMyWishList: 'Мой список желаний',
    headerAdminPanel: 'Панель администратора',
    headerAdminOnly: 'Только для администратора',
    weAreSocial: 'Мы в социальных сетях',
    createdBy: 'Создано',
    copyRights: 'Все права защищены',
    activityHeading: 'Активность',
    tableActivityHeading: 'Последняя активность',
    tableNoRecords: 'Пока нет активности для отображения',
    tableUser: 'Пользователь',
    tableTime: 'Время',
    tableAction: 'Действие',
    tableMintFile: 'Загруженный файл',
    tableApproveMint: 'Утвержденный файл',
    tableBuyFile: 'Купленный файл',
    tableWishList: 'Добавлен файл в список отслеживаемых',
    tableNewSubscriber: 'Новый подписчик',
    tablePromote: 'Файл добавлен в продвижение',
    contactPageHeading: 'Свяжитесь с нами по любому вопросу',
    contactPageSubHeading:
        'Заполните форму с необходимыми данными, и мы свяжемся с вами по электронной почте в течение 24 часов',
    followUs: 'Следите за нами, чтобы быть в курсе последних новостей.',
    yourNameLabel: 'Ваше имя',
    yourNamePlaceholder: 'Введите ваше имя',
    yourNameErrorMsg: 'Пожалуйста, введите ваше имя',
    yourEmail: 'Ваш Email',
    yourEmailPlaceholder: 'Введите адрес вашей электронной почты',
    yourEmailErrorMsg: 'Пожалуйста, введите корректный адрес электронной почты',
    yourEmailInvalidPattern: 'Неверный адрес электронной почты',
    yourMessage: 'Ваше сообщение',
    yourMessagePlaceholder: 'Ваше сообщение или запрос',
    yourMessageErrorMsg: 'Пожалуйста, введите ваше сообщение',
    submitRequest: 'Отправить запрос',
    submitting: 'Отправка...',
    successSubmitHeading: 'Спасибо',
    successSubmitMsg: 'Мы свяжемся с вами в ближайшее время',
    returnHome: 'Вернуться на главную',
    utility: 'Утилита',
    title: 'Название',
    titleErrorMsg: 'Пожалуйста, введите название вашего видео',
    titlePlaceHolder: 'Введите название продукта',
    description: 'Описание',
    descriptionErrorMsg: 'Пожалуйста, введите описание файла',
    descriptionPlaceholder: 'Добавьте краткое описание вашего файла',
    category: 'Категория',
    categoryPlaceholder: 'Выберите категорию',
    compatibleWith: 'Совместимо с',
    compatibleWithPlaceholder: 'Выберите совместимость с ОС',
    compatibleWithErrorMsg:
        'Пожалуйста, выберите, с какой операционной системой совместимо это программное обеспечение',
    price: 'Цена',
    pricePlaceholder: 'Введите желаемую цену файла',
    priceErrorMsg: 'Пожалуйста, введите цену файла',
    uploadFile: 'Загрузите файл',
    uploadFileErrorMsg: 'Пожалуйста, загрузите ваш файл',
    uploadFileThumb: 'Загрузите обложку файла',
    uploadFileThumbErrorMsg: 'Пожалуйста, загрузите обложку вашего файла',
    uploadFileScreenshots: 'Загрузите скриншоты файла',
    uploadFileScreenshotsErrorMsg: 'Пожалуйста, загрузите скриншоты вашего файла',
    wishlistPageHeading: 'Мой список отслеживаемых файлов',
    myAccountHeading: 'Мой аккаунт',
    sellNewProduct: 'Продать новый продукт',
    salesProfits: 'Прибыль от продаж',
    noProfitsYet: 'У вас пока нет прибыли',
    claimProfits: 'Запросить прибыль',
    accTableFile: 'Файл',
    accTableCreator: 'Создатель',
    accTableCreatedAt: 'Дата создания',
    filesIHaveUploaded: 'Файлы, которые я загрузил',
    accNoUploadedFiles: 'Нет загруженных файлов для отображения',
    accPendingFiles: 'Мои файлы в ожидании',
    accNoPendingFiles: 'Нет файлов в ожидании для отображения',
    accPurchasedFiles: 'Мои купленные файлы',
    accNoPurchasedFiles: 'Нет купленных файлов для отображения',
    adminPanel: 'Панель администратора',
    appProfits: 'Прибыль приложения',
    approveSelected: 'Одобрить выбранные',
    blockSelected: 'Заблокировать выбранные',
    noFilesToDisplay: 'Нет файлов для отображения',
    allPendingFiles: 'Все файлы в ожидании',
    status: 'Статус',
    active: 'Активно',
    setPromotionPrice: 'Установить цену продвижения',
    currentPriceIs: 'Текущая цена',
    promotionPrice: 'Цена продвижения',
    promotionPricePlaceholder: 'Введите цену продвижения',
    promotionPriceErrorMsg: 'Пожалуйста, введите цену продвижения',
    setPrice: 'Установить цену',
    transferContractOwnership: 'Передача владения контрактами',
    newOwner: 'Новый владелец',
    newOwnerPlaceholder: 'Введите адрес нового владельца',
    newOwnerErrorMsg: 'Неверный адрес! Пожалуйста, проверьте введенный адрес',
    transfer: 'Передать',
    newOwnerSuccessHeading: 'Вы больше не являетесь владельцем этого контракта',
    newOwnerSuccessText: 'Владение переходит к другому аккаунту, теперь у вас нет доступа к этой странице. Удачи',
    updatePrice: 'Обновить цену',
    newPrice: 'Новая цена',
    updateProductPrice: 'Обновить цену продукта',
    equals: 'Равно',
    txLoadingHeading: 'Обычно это занимает некоторое время',
    txLoadingMsg: 'Мы обрабатываем вашу транзакцию, пожалуйста, не перезагружайте браузер',
    txLoadingSmText: 'Обработка транзакции',
    rating: 'Рейтинг',
    reason: 'Причина',
    enterRatingReason: 'Пожалуйста, введите причину рейтинга',
    reasonPleaceholder: "'например: качество кода'",
    message: 'Сообщение',
    messagePlaceholder: 'Введите сообщение для отзыва',
    ratingMsgError: 'Пожалуйста, введите сообщение рейтинга',
    submitRating: 'Отправить ваш рейтинг',
    close: 'Закрыть',
    uploadingYourAsset: 'Загрузка ваших активов',
    ipfsMsg: 'Это может занять некоторое время, пока мы сохраняем ваши активы в IFPS...',
    cover: 'Обложка',
    avatar: 'Аватар',
    screenshot: 'Снимок экрана',
    appRunOn: 'Это приложение предназначено для работы на',
    connectToNetwork: 'Пожалуйста, подключитесь к этой сети',
    switchNetwork: 'Переключить сеть',
    oneOfYourItem: 'Один из ваших элементов',
    err: 'Упс! Что-то пошло не так',
    youHaveAccessNow: 'Отлично! Теперь у вас есть доступ',
    fillTheFields: 'Пожалуйста, заполните поля, чтобы отправить',
    addedToWatchlist: 'Отлично! Вы добавили этот файл в свой список ожидания',
    claimedProfits: 'Отлично! Вы получили свою прибыль',
    filePriceUpdated: 'Отлично! Цена вашего файла была обновлена',
    approvedSelectedFiles: 'Отлично! Вы одобрили выбранные файлы',
    blockedSelectedFiles: 'Отлично! Вы заблокировали выбранные файлы',
    promotionPriceAdded: 'Отлично! Установлена цена продвижения',
    ownerShipChanged: 'Отлично! Собственность передана другому аккаунту',
    fileIsPromoted: 'Отлично! Ваш файл продвигается',
    fileUploaded: 'Отлично! Вы успешно загрузили файл',
    filesTooLarge: 'Файлы слишком большие',
    buy: 'Купить',
    charts: 'Графики',
    audit: 'Аудит',
    lottery: 'Лотерея',
    frequAskedQuestions: 'Часто задаваемые вопросы',
    whoAreWe: 'Кто мы?',
    whoWeAreAnsw:
        'Мы команда разработчиков Web3, реализующих то, что станет будущим интернета, блокчейна и децентрализации. Этот проект позволяет каждому пользователю безопасно продавать свои файлы непосредственно с компьютера или смартфона, без посредников.',
    maxTotalSupply: 'Максимальная/Общая эмиссия',
    assetPipeLine: 'и пайплайн активов для блокчейнов.',
    tax: 'Налог',
    sell: 'Продать',
    holderRewards: 'Награды держателей',
    marketing: 'Маркетинг',
    whitePaper: 'Белая бумага',
    trade: 'Торговля',
    official: 'Официальный',
    clickHere: 'Нажмите здесь',
    difiDetailsQuest: 'SOLE Детали и Ссылки',
    functionsOfDifiMarket: 'Функции SOLE Market?',
    onDifiMarketYouCan: 'На рынке SOLE вы можете',
    difiMarketBullet1: 'Продавать свои файлы, программы и любые другие типы цифровых файлов.',
    difiMarketBullet2: 'Покупать файлы других пользователей.',
    difiMarketBullet3: 'Оставлять отзывы после покупки.',
    difiMarketBullet4: 'Выводить свои SOLE с продаж.',
    whatWalletSupportedQues: 'Какие кошельки поддерживаются?',
    whatWalletSupportedAnsw:
        'Digital Files использует WalletConnect и поддерживает десятки различных кошельков для взаимодействия с магазином.',
    whatAreCommissionQuest: 'Какие комиссии?',
    fees: 'Комиссии',
    onEachSale: 'на каждую продажу',
    whatAreCommissionAnsw:
        'Каждая продажа файла облагается налогом в размере 1%, система использует блокчейн и IPFS, и 1% налога с каждой продажи позволяет нам поддерживать систему в идеальном состоянии.',
    uploadLimit: 'Ограничения на загрузку?',
    uploadLimitAnsw1: 'Максимальное ограничение на загрузку для каждого файла составляет около 100 МБ.',
    uploadLimitAnsw2: 'Нет ограничений на количество файлов, которые пользователь может загружать и продавать.',
    whatDoIBuyWith: 'Чем я покупаю файлы?',
    whatDoIBuyWithAnsw:
        'Файлы можно приобрести с использованием токена SOLE, для проведения транзакций также потребуется немного NEON, так как это местная валюта.',
    canIChangePrice: 'Могу ли я изменить цену файла?',
    canIChangePriceAnsw:
        'В настоящее время нельзя изменить цену файла, правильно рассчитывайте цену на основе цены SOLE при продаже файла.',
    moreQuestions: 'Еще вопросы?',
    ifYouCantFindAnsw: 'Если вы не можете найти ответы здесь',
    getInTouch: 'свяжитесь с нами',
    weWillHelp: 'Мы будем рады помочь',
    education: 'Учебные материалы',
    eBooks: 'Электронные книги',
    gDesign: 'Графический дизайн',
    os: 'Операционные системы',
    programming: 'Программирование',
    antiVirus: 'Антивирусы и безопасность',
    db: 'Базы данных',
    mutimedia: 'Мультимедиа',
    gaming: 'Игры',
    openSource: 'Открытый исходный код',
    adult: 'Для взрослых 18+',

    // Latest Translations keys --------------
    // Latest Translations keys --------------
    faqs: 'Вопросы и ответы',
    contactUs: 'Свяжитесь с нами',
    aboutUs: 'О нас',
    activity: 'Активности',
    aboutUsHeading: 'О нас',
    wordsAboutUsHeading: 'Несколько слов о',
    appLauncingTime: 'SOLE запущен 28 апреля 2024 года',
    aboutWord1:
        'Первый рынок цифровых файлов, созданный на блокчейне в web3, под управлением NEON EVM.',
    aboutWord2:
        'Покупайте, продавайте, продвигайте, верифицируйте свой магазин и многое другое на рынке SOLE, где пользователи на первом плане, а безопасность находится на самом высоком уровне благодаря Binance Smart Chain и токену SOLE.',
    creator: 'Создатель',
    topFeatureHeading: 'Лучшие функции',
    toPFeatureSubheading: 'Топ-3 функции на рынке SOLE, которые не имеют аналогов!',
    decentralized: 'Децентрализованный',
    decentralizedDescription:
        'Рынок SOLE полностью децентрализован, всё сохраняется в блокчейне NEON EVM для максимальной безопасности. Пользователи взаимодействуют, подключая свои кошельки, без внешнего управления.',
    hackerFreeHeading: 'Безопасность от хакеров',
    hackerFreeDescription:
        'Система ничего не сохраняет на веб-сервере, нет риска потери данных. Пользователи взаимодействуют со своим кошельком при подключении, ничто не может быть изменено, и администраторы не имеют доступа к средствам пользователей.',
    lowFeesHeading: 'Низкие комиссии',
    lowFeesDescription:
        'Возможно, самые низкие в мире, всего 1% комиссии с продаж, без скрытых платежей. Пользователи могут выводить SOLE с своего счета в любое время без ожидания или подтверждений. Пользователи оплачивают SOLE только в момент заказа.',
    aboutUsQuote: '"С SOLE Market мы уже находимся в будущем, прежде всего."',
    token: 'Токен',
    founder: 'Основатель',
    digitalFilesMarket: 'Рынок цифровых файлов',
    subscribeThanks: 'Спасибо за подписку',
    toastError: 'Упс! Что-то пошло не так!',
    resources: 'Ресурсы',
    newsletterSignup: 'Подписка на рассылку',
    newsletterText: 'Подпишитесь на рассылку и будьте в курсе последних новостей',
    newsletterErrMsg: 'Пожалуйста, введите вашу электронную почту',
    newsletterPlaceholder: 'Введите вашу электронную почту',
    sitemap: 'Карта сайта',
    bestSellers: 'Хиты продаж',
    topRated: 'Самые популярные',
    home: 'Главная',
    files: 'Файлы',
    resetFilters: 'Сбросить фильтры',
    searchForFiles: 'Поиск продуктов',
    noSearchResults: 'Нет продуктов, соответствующих вашему запросу',
    noFilesAtTheMoment: 'В данный момент нет файлов.',
    emptyHere: 'Здесь пусто',
    poromotionEndsAt: 'Акция заканчивается в',
    sortByRate: 'Сортировка по рейтингу',
    shopBrandDescription: 'Децентрализованный магазин цифровых файлов Web3',
    uploadFileNav: 'Загрузить файл',
    account: 'Аккаунт',
    wishlist: 'Список желаний',
    upload: 'Загрузить',
    menulegal: 'Юридическое',
    menuprivacy: 'Политика конфиденциальности',
    menutos: 'Условия использования',
    menulegaopinion: 'Правовое мнение',
    share: 'Делиться',
};
