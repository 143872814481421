/* ------------------------------------------------------------------------------------------ 
      FRENCH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const frenchTranslation = {
    recentlyAdded: 'Récemment ajouté',
    topTrending: 'Les plus populaires',
    featuredItems: 'Nos fichiers vedettes',
    noCategoryMatch: 'Aucun fichier ne correspond à cette catégorie',
    noReviewsYet: 'Aucun commentaire pour le moment',
    accessNow: 'Accéder maintenant',
    downloadNow: 'Télécharger maintenant',
    accessFileFor: 'Accéder à ce fichier pour seulement',
    youHaveAccess: 'Vous avez déjà accès à ce fichier',
    viewScreenshots: "Voir les captures d'écran",
    promoted: 'Promu',
    addToList: 'Ajouter à ma liste',
    inList: 'Dans votre liste',
    fileReviews: 'Commentaires sur le fichier',
    fileGallery: 'Galerie de fichiers',
    pending: 'En attente',
    pendingMsg: "Ce fichier est en attente d'approbation de l'administrateur",
    from: 'De',
    ratings: 'Évaluations',
    promoteThisFile: 'Promouvoir ce fichier',
    promoteFor: 'Promouvoir pour',
    youHaveReview: 'Vous avez déjà commenté ce fichier',
    rateThisFile: 'Noter ce fichier',
    fileHasNoReviews: "Ce fichier n'a pas encore de commentaires",
    discoverAll: 'Découvrir tous les fichiers',
    filterByCategory: 'Filtrer par catégorie',
    sortByDate: 'Trier par date',
    sortByPrice: 'Trier par prix',
    loadMore: 'Charger plus',
    select: 'Sélectionner',
    all: 'Tout',
    noMatches: 'Aucun fichier ne correspond à ce filtre',
    newest: 'Les plus récents en premier',
    oldest: 'Les plus anciens en premier',
    highPrice: 'Prix : du plus élevé au plus bas',
    lowPrice: 'Prix : du plus bas au plus élevé',
    headerLinks: 'Liens',
    headerDiscover: 'Découvrir',
    headerActivity: 'Activité',
    headerFaqs: 'FAQ',
    headerContactUs: 'Contactez-nous',
    trackTransaction: 'Suivre les transactions',
    headerMyAccount: 'Mon compte',
    headerMyWishList: 'Ma liste de souhaits',
    headerAdminPanel: "Panneau d'administration",
    headerAdminOnly: 'Admin seulement',
    weAreSocial: 'Nous sommes sur les réseaux sociaux',
    createdBy: 'Créé par',
    copyRights: 'Tous droits réservés',
    activityHeading: 'Activités',
    tableActivityHeading: 'Dernières activités',
    tableNoRecords: "Il n'y a pas encore d'activités à afficher",
    tableUser: 'Utilisateur',
    tableTime: 'Heure',
    tableAction: 'Action',
    tableMintFile: 'Fichier téléchargé',
    tableApproveMint: 'Fichier approuvé',
    tableBuyFile: 'Fichier acheté',
    tableWishList: 'Fichier ajouté à la liste de souhaits',
    tableNewSubscriber: 'Nouvel abonné',
    tablePromote: 'Le fichier a été ajouté à la promotion',
    contactPageHeading: 'Contactez-nous pour toute question',
    contactPageSubHeading:
        'Remplissez le formulaire avec ce dont vous avez besoin et nous vous contacterons par e-mail dans les 24 heures.',
    followUs: 'Suivez-nous pour rester informé.',
    yourNameLabel: 'Votre nom',
    yourNamePlaceholder: 'Entrez votre nom',
    yourNameErrorMsg: 'Veuillez entrer votre nom',
    yourEmail: 'Votre e-mail',
    yourEmailPlaceholder: 'Entrez votre adresse e-mail',
    yourEmailErrorMsg: 'Veuillez entrer une adresse e-mail valide',
    yourEmailInvalidPattern: 'Adresse e-mail invalide',
    yourMessage: 'Votre message',
    yourMessagePlaceholder: 'Votre message concernant une demande quelconque',
    yourMessageErrorMsg: 'Veuillez entrer votre message',
    submitRequest: 'Envoyer votre demande',
    submitting: 'Envoi en cours...',
    successSubmitHeading: 'Merci',
    successSubmitMsg: 'Nous vous répondrons dès que possible',
    returnHome: "Retour à la page d'accueil",
    utility: 'Utilité',
    title: 'Titre',
    titleErrorMsg: 'Veuillez entrer le titre de votre vidéo',
    titlePlaceHolder: 'Entrez le titre de votre produit',
    description: 'Description',
    descriptionErrorMsg: 'Veuillez entrer la description de votre fichier',
    descriptionPlaceholder: 'Ajoutez une brève description de votre fichier',
    category: 'Catégorie',
    categoryPlaceholder: 'Sélectionnez une catégorie',
    compatibleWith: 'Compatible avec',
    compatibleWithPlaceholder: 'Sélectionnez une compatibilité OS',
    compatibleWithErrorMsg: "Veuillez choisir avec quel système d'exploitation ce logiciel est compatible",
    price: 'Prix',
    pricePlaceholder: 'Entrez le prix souhaité pour votre fichier en',
    priceErrorMsg: 'Veuillez entrer le prix de votre fichier',
    uploadFile: 'Téléchargez votre fichier',
    uploadFileErrorMsg: 'Veuillez télécharger votre fichier',
    uploadFileThumb: 'Téléchargez la miniature de votre fichier',
    uploadFileThumbErrorMsg: 'Veuillez télécharger la miniature de votre fichier',
    uploadFileScreenshots: "Téléchargez les captures d'écran du fichier",
    uploadFileScreenshotsErrorMsg: "Veuillez télécharger les captures d'écran de votre fichier",
    wishlistPageHeading: 'Ma liste de souhaits',
    myAccountHeading: 'Mon compte',
    sellNewProduct: 'Vendre un nouveau produit',
    salesProfits: 'Profits des ventes',
    noProfitsYet: "Vous n'avez pas encore de profits",
    claimProfits: 'Réclamer les profits',
    accTableFile: 'Fichier',
    accTableCreator: 'Créateur',
    accTableCreatedAt: 'Date de création',
    filesIHaveUploaded: "Fichiers que j'ai téléchargés",
    accNoUploadedFiles: "Il n'y a aucun fichier téléchargé à afficher",
    accPendingFiles: 'Mes fichiers en attente',
    accNoPendingFiles: "Il n'y a aucun fichier en attente à afficher",
    accPurchasedFiles: 'Mes fichiers achetés',
    accNoPurchasedFiles: "Il n'y a aucun fichier acheté à afficher",
    adminPanel: "Panneau d'administration",
    appProfits: "Profits de l'application",
    approveSelected: 'Approuver les sélectionnés',
    blockSelected: 'Bloquer les sélectionnés',
    noFilesToDisplay: "Il n'y a aucun fichier à afficher",
    allPendingFiles: 'Tous les fichiers en attente',
    status: 'Statut',
    active: 'Actif',
    setPromotionPrice: 'Définir le prix promotionnel',
    currentPriceIs: 'Le prix actuel est',
    promotionPrice: 'Prix promotionnel',
    promotionPricePlaceholder: 'Entrez le prix promotionnel',
    promotionPriceErrorMsg: 'Veuillez ajouter le prix promotionnel',
    setPrice: 'Définir le prix',
    transferContractOwnership: 'Transférer la propriété du contrat',
    newOwner: 'Nouveau propriétaire',
    newOwnerPlaceholder: "Entrez l'adresse du nouveau propriétaire",
    newOwnerErrorMsg: "Adresse invalide ! Veuillez vérifier l'adresse que vous avez saisie",
    transfer: 'Transférer',
    newOwnerSuccessHeading: "Vous n'êtes plus le propriétaire de ce contrat",
    newOwnerSuccessText:
        "La propriété est maintenant transférée à un autre compte, vous n'avez plus accès à cette page. Bonne chance",
    updatePrice: 'Mettre à jour le prix',
    newPrice: 'Nouveau prix',
    updateProductPrice: 'Mettre à jour le prix du produit',
    equals: 'Égal à',
    txLoadingHeading: 'Cela prend généralement un certain temps',
    txLoadingMsg: 'Nous traitons votre transaction, veuillez ne pas recharger votre navigateur',
    txLoadingSmText: 'Traitement de la transaction',
    rating: 'Évaluation',
    reason: 'Raison',
    enterRatingReason: "Veuillez entrer la raison de l'évaluation",
    reasonPleaceholder: "'ex: Qualité du code'",
    message: 'Message',
    messagePlaceholder: "Saisissez votre message d'évaluation",
    ratingMsgError: "Veuillez entrer le message d'évaluation",
    submitRating: 'Soumettre votre évaluation',
    close: 'Fermer',
    uploadingYourAsset: 'Téléchargement de vos actifs',
    ipfsMsg: "Cela peut prendre un certain temps jusqu'à ce que nous sauvegardions vos actifs sur IPFS...",
    cover: 'Couverture',
    avatar: 'Avatar',
    screenshot: "Capture d'écran",
    appRunOn: 'Cette application est conçue pour fonctionner sur',
    connectToNetwork: 'Veuillez vous connecter à ce réseau',
    switchNetwork: 'Changer de réseau',
    oneOfYourItem: 'Un de vos articles',
    err: "Oops ! Une erreur s'est produite",
    youHaveAccessNow: 'Super ! Vous avez maintenant accès',
    fillTheFields: 'Veuillez remplir les champs pour pouvoir soumettre',
    addedToWatchlist: 'Super ! Vous avez ajouté cette vidéo à votre liste de souhaits',
    claimedProfits: 'Super ! Vous avez réclamé vos profits',
    filePriceUpdated: 'Super ! Le prix de votre fichier a été mis à jour',
    approvedSelectedFiles: 'Super ! Vous avez approuvé les fichiers sélectionnés',
    blockedSelectedFiles: 'Super ! Vous avez bloqué les fichiers sélectionnés',
    promotionPriceAdded: 'Super ! Le prix de promotion a été défini',
    ownerShipChanged: 'Super ! La propriété a été transférée vers un autre compte',
    fileIsPromoted: 'Super ! Votre fichier a été promu',
    fileUploaded: 'Super ! Vous avez téléchargé avec succès un fichier',
    filesTooLarge: 'Les fichiers sont trop volumineux',
    buy: 'Acheter',
    charts: 'Graphiques',
    audit: 'Audit',
    lottery: 'Loterie',
    frequAskedQuestions: 'Questions fréquemment posées',
    whoAreWe: 'Qui sommes-nous ?',
    whoWeAreAnsw:
        "Nous sommes une équipe de développeurs Web3 qui mettent en œuvre ce qui sera l'avenir d'Internet, de la blockchain et de la décentralisation. Ce projet permet à chaque utilisateur de vendre en toute sécurité ses fichiers, directement depuis son PC ou son smartphone, et sans intermédiaires.",
    maxTotalSupply: 'Offre maximale / totale',
    assetPipeLine: "et pipeline d'actifs pour les blockchains.",
    tax: 'Taxe',
    sell: 'Vendre',
    holderRewards: 'Récompenses pour les détenteurs',
    marketing: 'Marketing',
    whitePaper: 'Livre blanc',
    trade: 'Échanger',
    official: 'Officiel',
    clickHere: 'Cliquez ici',
    difiDetailsQuest: 'Détails et liens de SOLE',
    functionsOfDifiMarket: 'Fonctions du marché SOLE?',
    onDifiMarketYouCan: 'Sur le marché SOLE, vous pouvez',
    difiMarketBullet1: 'Vendre vos fichiers, programmes et tout type de fichier numérique.',
    difiMarketBullet2: "Acheter des fichiers d'autres utilisateurs.",
    difiMarketBullet3: 'Laisser un commentaire après un achat.',
    difiMarketBullet4: 'Retirer vos SOLE des ventes.',
    whatWalletSupportedQues: 'Quels portefeuilles sont pris en charge?',
    whatWalletSupportedAnsw:
        'Digital Files utilise WalletConnect et prend en charge des dizaines de portefeuilles différents pour interagir avec la boutique.',
    whatAreCommissionQuest: 'Quelles sont les commissions?',
    fees: 'Frais',
    onEachSale: 'sur chaque vente',
    whatAreCommissionAnsw:
        'Une taxe de 1% est appliquée sur chaque fichier vendu, le système utilise la blockchain et IPFS, et la taxe de 1% sur chaque vente nous permet de maintenir le système en parfait état de fonctionnement.',
    uploadLimit: 'Limites de téléchargement?',
    uploadLimitAnsw1: "La limite de téléchargement maximale pour chaque fichier est d'environ 100 Mo",
    uploadLimitAnsw2: "Il n'y a pas de limite au nombre de fichiers qu'un utilisateur peut télécharger et vendre.",
    whatDoIBuyWith: 'Avec quoi achète-t-on les fichiers?',
    whatDoIBuyWithAnsw:
        "Les fichiers peuvent être achetés en utilisant le jeton SOLE, même quelques NEON sont nécessaires pour gérer les transactions car il s'agit de la devise native.",
    canIChangePrice: "Puis-je changer le prix d'un fichier ?",
    canIChangePriceAnsw:
        "Pour le moment, il n'est pas possible de modifier le prix d'un fichier. Faites les calculs correctement en fonction du prix de SOLE lorsque vous vendez un fichier.",
    moreQuestions: 'Plus de questions?',
    ifYouCantFindAnsw: 'Si vous ne trouvez pas les réponses ici',
    getInTouch: 'prenez contact',
    weWillHelp: 'Nous serons heureux de vous aider',
    education: 'Matériel éducatif',
    eBooks: 'Livres électroniques',
    gDesign: 'Conception graphique',
    os: "Systèmes d'exploitation",
    programming: 'Programmation',
    antiVirus: 'Antivirus et sécurité',
    db: 'Base de données',
    mutimedia: 'Multimédia',
    gaming: 'Jeux',
    openSource: 'Open Source',
    adult: 'Pour adultes (18+)',

    // Latest Translations keys --------------
    faqs: 'FAQ',
    contactUs: 'Contactez-nous',
    aboutUs: 'À propos de nous',
    activity: 'Activités',
    aboutUsHeading: 'À propos de nous',
    wordsAboutUsHeading: 'Quelques mots à propos',
    appLauncingTime: 'SOLE lancé le 1er avril 2024',
    aboutWord1:
        'Le premier marché de fichiers numériques créé sur la Blockchain en web3, alimenté par NEON EVM.',
    aboutWord2:
        "Achetez, vendez et promouvez vos créations numériques de la manière la plus facile et sécurisée sur le marché. Aucune inscription, aucune tierce partie impliquée. C'est la décentralisation SOLE.",
    creator: 'Créateur',
    topFeatureHeading: 'Fonctionnalités principales',
    toPFeatureSubheading: 'Les 3 principales fonctionnalités du marché SOLE inégalées !',
    decentralized: 'Décentralisé',
    decentralizedDescription:
        'Il mercato SOLE è al 100% decentralizzato, tutto è salvato sulla blockchain di NEON EVM per una sicurezza massima. Gli utenti interagiscono collegando il loro portafoglio senza necessità di gestione esterna.',
    hackerFreeHeading: 'Sans piratage',
    hackerFreeDescription:
        "Le système ne sauvegarde rien sur le serveur web, il n'y a pas de données à risque, les utilisateurs interagissent avec leur portefeuille lorsqu'ils sont connectés, rien ne peut être manipulé et les administrateurs n'ont pas accès aux fonds des utilisateurs.",
    lowFeesHeading: 'Frais réduits',
    lowFeesDescription:
        "Probablement les plus bas à l'échelle mondiale, seulement 1% de commission sur les ventes, pas d'autres frais cachés, les utilisateurs peuvent retirer des SOLE de leur compte quand ils le souhaitent, sans attente ni confirmation, les utilisateurs paient en SOLE uniquement au moment de la commande.",
    aboutUsQuote: '"Avec SOLE Market, nous sommes déjà dans le futur, avant tout."',
    token: 'Jetons',
    founder: 'Fondateur',
    digitalFilesMarket: 'Marché des fichiers numériques',
    subscribeThanks: 'Merci de vous être abonné',
    toastError: "Oops ! Quelque chose s'est mal passé !",
    resources: 'Ressources',
    newsletterSignup: 'Inscription à la newsletter',
    newsletterText: 'Abonnez-vous à la newsletter et restez informé',
    newsletterErrMsg: 'Veuillez entrer votre adresse e-mail',
    newsletterPlaceholder: 'Ajoutez votre adresse e-mail',
    sitemap: 'Plan du site',
    bestSellers: 'Meilleures ventes',
    topRated: 'Les mieux notés',
    home: 'Accueil',
    files: 'Fichiers',
    resetFilters: 'Réinitialiser les filtres',
    searchForFiles: 'Rechercher des produits',
    noSearchResults: 'Aucun produit ne correspond à votre recherche',
    noFilesAtTheMoment: "Il n'y a aucun fichier pour le moment.",
    emptyHere: "C'est vide ici",
    poromotionEndsAt: 'La promotion se termine le',
    sortByRate: 'Trier par note',
    shopBrandDescription: 'Boutique décentralisée de fichiers numériques Web3',
    uploadFileNav: 'Télécharger un fichier',
    account: 'Compte',
    wishlist: 'Liste de souhaits',
    upload: 'Télécharger',
    menulegal: 'Légal',
    menuprivacy: 'Politique de confidentialité',
    menutos: "Conditions d'utilisation",
    menulegaopinion: 'Avis juridique',
    share: 'Partager',
};
