import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import { RiHome3Line } from 'react-icons/ri';
import { RiBuildingLine } from 'react-icons/ri';
import { BiLinkAlt } from 'react-icons/bi';
import { VscFileSubmodule } from 'react-icons/vsc';
import { HiChevronDown, HiOutlinePaperAirplane } from 'react-icons/hi';
import { appSettings } from '../../helpers/settings';
import { shuffle } from '../../helpers/utils';
import { RiShoppingBasket2Line } from 'react-icons/ri';
import useFiles from '../../hooks/useFiles';
import cg from './cg.png';

function BottomBanner() {
    const { t } = useTranslation();
    const { usdToken } = useFiles();
    return (
        <>
            <nav
                className='navbar nav-colored w-100 navbar-expand-lg px-0 justify-content-between rounded-0 shadow-0'
                style={{ background: '#ffcc00', zIndex: '999' }}
            >
                <div className='container py-2'>
                    <div className='d-inline-block d-lg-none'>
                        <a
                            href='https://coinmarketcap.com/currencies/solerium'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-xs d-flex align-items-center justify-content-center'
                            style={{ color: '#313c46' }}
                        >
                            <div className='animated-bullet me-2'>
                                <div className='animated-bullet-inner'></div>
                            </div>
                            <img src={cg} alt='CoinMarketCap' width='20' className='flex-shrink-0 mx-1' /> Live <img src='/token.png' alt='SOLE' width='20' className='flex-shrink-0 mx-1' /> price is{' '}
                            <strong className='fw-bold ms-2'>{(1 / usdToken).toFixed(3)} USD</strong>
                        </a>
                    </div>
                    <button
                        className='navbar-toggler shadow-0 p-0 border-0'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarBottomContent'
                        aria-controls='navbarBottomContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='navbar-toggler-icon-el'>
                            <span className='btn-mobile--menu-icon'></span>
                        </span>
                    </button>

                    <div className='collapse navbar-collapse justify-content-lg-between' id='navbarBottomContent'>
                        <ul className='navbar-nav mx-auto navbar-nav-custom'>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/'>
                                    <RiHome3Line className='me-2 mb-1' size='1.4rem' />
                                    {t('home')}
                                </NavLink>
                            </li>
                            <li className='nav-item dropdown'>
                                <Link
                                    className='nav-link dropdown-toggle no-caret d-flex align-items-center text-reset'
                                    to='/'
                                    role='button'
                                    data-bs-toggle='dropdown'
                                    data-bs-target='#linksDropdown'
                                    aria-expanded='false'
                                >
                                    <BiLinkAlt className='me-2 mb-1' size='1.4rem' />
                                    {t('headerLinks')}
                                    <HiChevronDown className='ms-1 mt-1' size='1rem' />
                                </Link>

                                <ul className='dropdown-menu' id='linksDropdown'>
                                    <li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://jup.ag/swap/SOL-H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6'
                                            className='dropdown-item rounded'
                                        >
                                            {t('buy')} SOLE
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://neonpass.live/'
                                            className='dropdown-item rounded'
                                        >
                                            SOLE Bridge
                                        </a>
                                    </li>									
                                    <li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://solscan.io/token/H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6'
                                            className='dropdown-item rounded'
                                        >
                                            SOLE @ Solscan
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://neonscan.org/token/0xc901d4d435a5f9b5a4c8b4074624b867d5295034'
                                            className='dropdown-item rounded'
                                        >
                                            SOLE @ NEONscan
                                        </a>
                                    </li>
									<li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://coinmarketcap.com/currencies/solerium'
                                            className='dropdown-item rounded'
                                        >
                                            SOLE @ CoinmarketCap
                                        </a>
                                    </li>
									<li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://t.me/sole_t2e_bot'
                                            className='dropdown-item rounded'
                                        >
                                            SOLE Tap2Earn
                                        </a>
                                    </li>
									<li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://t.me/sole_ai_bot'
                                            className='dropdown-item rounded'
                                        >
                                            SOLE AI
                                        </a>
                                    </li>
									<li>
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://solerium.io/sole-wallet.apk'
                                            className='dropdown-item rounded'
                                        >
                                            SOLE Wallet Android
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className='nav-item dropdown-mega position-static'>
                                <a
                                    href='#!'
                                    className='nav-link dropdown-toggle no-caret text-reset'
                                    data-bs-toggle='dropdown'
                                    data-bs-target='#megaMenu'
                                    aria-expanded='false'
                                >
                                    <VscFileSubmodule className='me-2 mb-1' size='1.4rem' />
                                    {t('files')}
                                    <HiChevronDown className='ms-1 mt-1' size='1rem' />
                                </a>
                                <div className='dropdown-menu shadow-none w-100 p-0' id='megaMenu'>
                                    <div
                                        className='mega-content text-dark p-lg-4 p-2'
                                        style={{ background: '#ffcc00' }}
                                    >
                                        <div className='row g-0'>
                                            {[
                                                {
                                                    label: 'All',
                                                    value: 'All',
                                                    icon: <RiShoppingBasket2Line size='2rem' className='text-reset' />,
                                                },
                                                ...shuffle(appSettings?.categoryOptions)?.slice(0, 11),
                                            ]?.map((categ, index) => {
                                                return (
                                                    <div className='col-xl-2 col-lg-4' key={index}>
                                                        <Link
                                                            to={`${
                                                                categ?.value === 'All'
                                                                    ? '/discover'
                                                                    : `/files/category/${categ?.value}`
                                                            }`}
                                                            className='nav-link d-flex align-items-center mega-nav-link'
                                                        >
                                                            {categ?.icon}
                                                            <div className='ms-2'>{t(`${categ?.translationKey}`)}</div>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/about'>
                                    <RiBuildingLine className='me-2 mb-1' size='1.4rem' />
                                    {t('aboutUs')}
                                </NavLink>
                            </li>

                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/features-requests'>
                                    <HiOutlinePaperAirplane className='me-2 mb-1' size='1.4rem' />
                                    {t('headerContactUs')}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default BottomBanner;
