import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HiPresentationChartLine } from 'react-icons/hi';
import FileBoxEx from '../../components/general/FileBoxEx';
import FileBoxMock from '../../mock-components/FileBoxMock';
import { useTranslation } from 'react-i18next';
import { Navigation, Autoplay } from 'swiper';

function BestSellers({ fileBuyers, files }) {
    const fallbackFiles = [1, 2, 3, 4, 5, 6, 7, 8];

    const { t } = useTranslation();

    return (
        <>
            {files?.filter((vid) => vid.approved)?.length > 0 &&
                fileBuyers.filter((vid) => vid.approved).filter((file) => file?.buyers?.length >= 1)?.length > 0 && (
                    <section className='py-5'>
                        <div className='container py-5'>
                            <h2 className='text-lg lh-1 mb-4 d-flex align-items-center'>
                                <HiPresentationChartLine size='3.5rem' className='text-primary' />
                                <div className='ms-2'>{t('bestSellers')}</div>
                            </h2>
                            <div className='swiper-wrapper-padding-nav'>
                                <Swiper
                                    breakpoints={{
                                        560: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        1200: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                        },
                                        1400: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                        },
                                    }}
                                    modules={[Navigation, Autoplay]}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    speed={1000}
                                    navigation
                                >
                                    {files?.filter((vid) => vid.approved)?.length > 0
                                        ? fileBuyers
                                              .filter((vid) => vid.approved)
                                              .filter((file) => file?.buyers?.length >= 1)
                                              .sort((a, b) => b?.buyers?.length - a?.buyers?.length)
                                              .slice(0, 8)
                                              .map((vid) => {
                                                  return (
                                                      <SwiperSlide key={vid.id}>
                                                          <FileBoxEx {...vid} />
                                                      </SwiperSlide>
                                                  );
                                              })
                                        : fallbackFiles.map((vid, i) => {
                                              return (
                                                  <SwiperSlide key={i}>
                                                      <div className='px-3 px-sm-0'>
                                                          <FileBoxMock />
                                                      </div>
                                                  </SwiperSlide>
                                              );
                                          })}
                                </Swiper>
                            </div>
                        </div>
                    </section>
                )}
        </>
    );
}

export default BestSellers;
