import React, { useState, useMemo, useEffect } from 'react';
import PageBanner from '../../components/general/PageBanner';
import FileBoxEx from '../../components/general/FileBoxEx';
import { BiRightArrowAlt, BiLeftArrowAlt, BiCategoryAlt, BiSearchAlt, BiReset } from 'react-icons/bi';
import { BsFillCalendar2DateFill } from 'react-icons/bs';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { FaStarHalfAlt } from 'react-icons/fa';
import { appSettings } from '../../helpers/settings';
import useFiles from '../../hooks/useFiles';
import ReactPaginate from 'react-paginate';
import RangeSlider from 'react-range-slider-input';
import { useTranslation } from 'react-i18next';
import { calcReviews } from '../../helpers/utils';

function FilesPage() {
    const { files: allFiles, filesWithBuyers } = useFiles();
    const { t } = useTranslation();

    const files = useMemo(() => {
        if (filesWithBuyers?.length > 0) {
            return filesWithBuyers;
        } else {
            return allFiles;
        }
    }, [allFiles, filesWithBuyers]);

    // SEARCH VARIABLED -------------------------------------
    const [searchQuery, setSearchQuery] = useState('');
    const [searchSubmitted, setSearchSubmitted] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    // FILTERS VARIABLES ------------------------------------
    const [categoryFilter, setCategoryFilter] = useState({ label: 'All', value: 'All' });
    const [dateFilter, setDateFilter] = useState({ label: 'All', value: 'All' });
    const [ratingFilter, setRatingFilter] = useState({ label: 'All', value: 'All' });
    const [priceRange, setPriceRange] = useState([0, 0]);
    const [priceRangeMin, setPriceRangeMin] = useState(1);
    const [priceRangeMax, setPriceRangeMax] = useState(1);

    const approvedFiles = useMemo(() => {
        return files?.filter((file) => file?.approved).sort((a, b) => b.createdAt - a.createdAt);
    }, [files]);

    // FILTERED PRODUCTS ------------------------------------
    const filteredResult = useMemo(() => {
        if (searchResults?.length === 0) {
            if (categoryFilter?.value !== 'All') {
                return approvedFiles
                    .filter((file) => file?.metadata?.category === categoryFilter?.value)
                    .filter(
                        (file) => Number(file?.usdPrice) >= priceRange[0] && Number(file?.usdPrice) <= priceRange[1]
                    );
            } else {
                return approvedFiles.filter(
                    (file) => Number(file?.usdPrice) >= priceRange[0] && Number(file?.usdPrice) <= priceRange[1]
                );
            }
        } else {
            if (categoryFilter?.value !== 'All') {
                return searchResults
                    .filter((file) => file?.metadata?.category === categoryFilter?.value)
                    .filter(
                        (file) => Number(file?.usdPrice) >= priceRange[0] && Number(file?.usdPrice) <= priceRange[1]
                    );
            } else {
                return searchResults.filter(
                    (file) => Number(file?.usdPrice) >= priceRange[0] && Number(file?.usdPrice) <= priceRange[1]
                );
            }
        }
    }, [categoryFilter, approvedFiles, priceRange, searchResults]);

    // PAGINATION VARIABLES ---------------------------------
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 12;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(approvedFiles?.length / itemsPerPage);

    // CHANGE MINIMUM & MAXIMUM PRICE RANGES ---------------
    useEffect(() => {
        if (approvedFiles?.length > 0) {
            setPriceRange([
                Math.min(...approvedFiles?.map((product) => Number(product?.usdPrice))),
                Math.max(...approvedFiles?.map((product) => Number(product?.usdPrice))),
            ]);
            setPriceRangeMin(Math.min(...approvedFiles?.map((product) => Number(product?.usdPrice))));
            setPriceRangeMax(Math.max(...approvedFiles?.map((product) => Number(product?.usdPrice))));
        }
    }, [approvedFiles]);

    /* --------------------------------------------- 
          MOVE BETWEEN PAGES
    --------------------------------------------- */
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % approvedFiles?.length;
        setItemOffset(newOffset);
    };

    /* --------------------------------------------- 
          FILTER PRODUCTS BY DATE
    --------------------------------------------- */
    function handleDateFilter(val) {
        setDateFilter(val);
        setRatingFilter({ label: 'All', value: 'All' });
        setSearchResults([]);
    }

    /* --------------------------------------------- 
        FILTER PRODUCTS BY RATINGS
    --------------------------------------------- */
    function handleRatingFilter(val) {
        setRatingFilter(val);
        setDateFilter({ label: 'All', value: 'All' });
        setSearchResults([]);
    }

    /* --------------------------------------------- 
          SEARCH PRODUCTS HANDLER
    --------------------------------------------- */
    function searchProductsHandler(e) {
        e.preventDefault();
        setSearchSubmitted(true);
        setSearchResults(
            approvedFiles?.filter((file) => {
                return file?.metadata?.title.toLowerCase().includes(searchQuery?.toLowerCase()?.trim());
            })
        );
        setRatingFilter({ label: 'All', value: 'All' });
        setDateFilter({ label: 'All', value: 'All' });
    }

    /* --------------------------------------------- 
          RESET SEARCH
    --------------------------------------------- */
    function handleResetSearch() {
        setSearchQuery('');
        setSearchResults([]);
        setSearchSubmitted(false);
    }

    /* --------------------------------------------- 
          RESET ALL FILTERS
    --------------------------------------------- */
    function handleResetFilters() {
        setDateFilter({ label: 'All', value: 'All' });
        setRatingFilter({ label: 'All', value: 'All' });
        setCategoryFilter({ label: 'All', value: 'All' });
        if (approvedFiles?.length > 0) {
            setPriceRange([
                Math.min(...approvedFiles?.map((file) => Number(file?.usdPrice))),
                Math.max(...approvedFiles?.map((file) => Number(file?.usdPrice))),
            ]);
        } else {
            setPriceRange([0, 0]);
        }
        setPriceRangeMin(Math.min(...approvedFiles?.map((file) => Number(file?.usdPrice))));
        setPriceRangeMax(Math.max(...approvedFiles?.map((file) => Number(file?.usdPrice))));
    }

    return (
        <>
            <PageBanner heading={`${t('discoverAll')}`} />
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row gy-5'>
                        <div className='col-xxl-2 col-xl-3'>
                            {/* CATEGORIES FILTER */}
                            <div className='mb-4 pb-2 border-bottom'>
                                <h6 className='h5 mb-3'>
                                    <BiCategoryAlt className='mb-1 me-2 text-primary' size={20} />
                                    {t('categories')}
                                </h6>
                                <ul className='list-unstyled p-0'>
                                    {[{ label: 'All', value: 'All' }, ...appSettings.categoryOptions]?.map(
                                        (category, index) => {
                                            return (
                                                <li key={index}>
                                                    <button
                                                        className={`btn-filter mb-2 ${
                                                            categoryFilter?.value === category?.label ? 'active' : ''
                                                        }`}
                                                        onClick={() => {
                                                            setCategoryFilter(category);
                                                            setSearchResults([]);
                                                        }}
                                                    >
                                                        {category?.label}
                                                        <span className='small count text-muted ms-2'>
                                                            (
                                                            {category?.label !== 'All'
                                                                ? approvedFiles?.filter(
                                                                      (product) =>
                                                                          product?.metadata?.category ===
                                                                          category?.label
                                                                  )?.length
                                                                : approvedFiles?.length}
                                                            )
                                                        </span>
                                                    </button>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>

                            {/* DATE FILTER */}
                            <div className='mb-4 pb-2 border-bottom'>
                                <h6 className='h5 mb-3'>
                                    <BsFillCalendar2DateFill className='mb-1 me-2 text-primary' size={20} />
                                    {t('sortByDate')}
                                </h6>
                                <ul className='list-unstyled p-0 mb-0'>
                                    {[
                                        { label: 'Newest First', value: 'newest' },
                                        { label: 'Oldest First', value: 'oldest' },
                                    ].map((date, index) => {
                                        return (
                                            <li className='mb-2' key={index} onClick={() => handleDateFilter(date)}>
                                                <button
                                                    className={`btn-filter mb-2 ${
                                                        dateFilter?.value === date?.value ? 'active' : ''
                                                    }`}
                                                >
                                                    {date?.label}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            {/* RATING FILTER */}
                            <div className='mb-4 pb-2 border-bottom'>
                                <h6 className='h5 mb-3'>
                                    <FaStarHalfAlt className='mb-1 me-2 text-primary' size={20} />
                                    {t('sortByRate')}
                                </h6>
                                <ul className='list-unstyled p-0 mb-0'>
                                    {[
                                        { label: 'High Rates', value: 'high' },
                                        { label: 'Low Rates', value: 'low' },
                                    ].map((rating, index) => {
                                        return (
                                            <li className='mb-2' key={index} onClick={() => handleRatingFilter(rating)}>
                                                <button
                                                    className={`btn-filter mb-2 ${
                                                        ratingFilter?.value === rating?.value ? 'active' : ''
                                                    }`}
                                                >
                                                    {rating?.label}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            {/* PRICE FILTER */}
                            <div className='mb-4'>
                                <h6 className='h5 mb-3'>
                                    <RiMoneyDollarCircleFill className='mb-1 me-2 text-primary' size={20} />
                                    {t('sortByPrice')}
                                </h6>
                                <RangeSlider
                                    value={priceRange}
                                    onInput={setPriceRange}
                                    step={0.01}
                                    min={priceRangeMin}
                                    max={priceRangeMax}
                                />
                                <div className='d-flex align-items-center justify-content-between text-sm mt-3'>
                                    <p>
                                        <span className='text-muted'>{t('from')}:</span>{' '}
                                        <strong className='fw-bold'>{priceRange[0]}</strong>{' '}
                                        <span className='text-muted'>USD</span>
                                    </p>
                                    <p>
                                        <span className='text-muted'>{t('to')}:</span>{' '}
                                        <strong className='fw-bold'>{priceRange[1]}</strong>{' '}
                                        <span className='text-muted'>USD</span>
                                    </p>
                                </div>
                            </div>

                            {/* RESET ALL FILTERS */}
                            <button className='btn btn-primary' onClick={handleResetFilters}>
                                <BiReset className='me-1' size='1.3rem' style={{ transfrom: 'translateY(-3px)' }} />
                                {t('resetFilters')}
                            </button>
                        </div>

                        <div className='col-xxl-10 col-xl-9'>
                            {/* SEARCH PRODUCTS FORM */}
                            <form className='mb-4' onSubmit={searchProductsHandler} data-aos='fade-up'>
                                <div className='form-floating position-relative'>
                                    <input
                                        id='searchInput'
                                        type='text'
                                        className='form-control'
                                        placeholder={t('searchForFiles')}
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        autoComplete='off'
                                    />
                                    <label htmlFor='searchInput'>{t('searchForFiles')}</label>

                                    <div className='position-absolute top-0 end-0 bottom-0 p-2 d-flex align-items-stretch'>
                                        <button
                                            className='btn btn-secondary w-100 me-2'
                                            type='button'
                                            style={{ maxWidth: '4rem' }}
                                            onClick={handleResetSearch}
                                        >
                                            <BiReset
                                                className='me-1'
                                                size='1.3rem'
                                                style={{ transfrom: 'translateY(-3px)' }}
                                            />
                                        </button>
                                        <button
                                            className='btn btn-primary w-100'
                                            type='submit'
                                            style={{ maxWidth: '8rem' }}
                                            disabled={searchQuery.length < 3}
                                        >
                                            <BiSearchAlt
                                                className='me-1'
                                                size='1.3rem'
                                                style={{ transfrom: 'translateY(-3px)' }}
                                            />{' '}
                                            {t('search')}
                                        </button>
                                    </div>
                                </div>
                                {searchSubmitted && searchResults.length === 0 && (
                                    <div className='invalid-feedback d-block'>{t('noSearchResults')}</div>
                                )}
                            </form>

                            {/* PRODUCTS */}
                            <div className='row g-2'>
                                {filteredResult?.length > 0 ? (
                                    filteredResult
                                        .sort((a, b) => {
                                            if (dateFilter.value === 'newest') {
                                                return b.id - a.id;
                                            } else if (dateFilter.value === 'oldest') {
                                                return a.id - b.id;
                                            }

                                            return 0;
                                        })
                                        .sort((a, b) => {
                                            if (ratingFilter.value === 'high') {
                                                return calcReviews(b.reviews) - calcReviews(a.reviews);
                                            } else if (ratingFilter.value === 'low') {
                                                return calcReviews(a.reviews) - calcReviews(b.reviews);
                                            }

                                            return 0;
                                        })
                                        ?.slice(itemOffset, endOffset)
                                        ?.map((file, index) => {
                                            return (
                                                <div
                                                    className='col-xxl-3 col-lg-4 col-md-6 col-sm-6'
                                                    data-aos='fade-up'
                                                    data-aos-delay={100 * index}
                                                    key={index}
                                                >
                                                    <FileBoxEx {...file} />
                                                </div>
                                            );
                                        })
                                ) : (
                                    <div className='text-center'>
                                        <h2>{t('emptyHere')}</h2>
                                        <p className='text-muted'>{t('noFilesAtTheMoment')}</p>
                                    </div>
                                )}
                            </div>

                            {/* PAGINATION */}
                            {filteredResult?.length > 0 && (
                                <div className='react-pagination mt-4 justify-content-center'>
                                    <ReactPaginate
                                        breakLabel='...'
                                        nextLabel={<BiRightArrowAlt />}
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel={<BiLeftArrowAlt />}
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FilesPage;
