import React from 'react';
import { RiFileZipFill } from 'react-icons/ri';
import DataTable from 'react-data-table-component';
import { formatDate, truncate, truncateStart } from '../../helpers/utils';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { configEtherScanUrl } from '../../helpers/utils';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PendingFilesTable({ setIds }) {
    const { files } = useFiles();
    const { account, username, networkId } = useWeb3();
    const { t } = useTranslation();

    /*** =============================================== */
    //      PERIODS TABLE COLUMNS
    /*** =============================================== */
    const columns = [
        {
            name: t('accTableFile'),
            selector: (row) => row?.metadata?.title,
            sortable: true,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/files/${row.id}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${row?.metadata?.thumbnail})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>{row?.metadata?.title}</h6>
                                <p className='text-muted small mb-0'>
                                    {truncateStart(row?.metadata?.description, 30, '...')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('accTableCreator'),
            minWidth: '200px',
            selector: (row) => row?.creator,
            cell: (row) => (
                <div row={row}>
                    <a
                        href={configEtherScanUrl(networkId, row?.creator)}
                        rel='noopener noreferrer'
                        className='text-reset'
                        target='_blank'
                    >
                        <div className='d-flex align-items-center'>
                            <div className='avatar avatar-md2'>
                                <div style={{ width: '30px', height: '30px' }}>
                                    <Jazzicon address={row?.creator || ''} />
                                </div>
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.creator !== account ? row?.userGenName : username}
                                </h6>
                                <p className='text-muted small mb-0'>{truncate(row?.creator, 15, '.....')}</p>
                            </div>
                        </div>
                    </a>
                </div>
            ),
        },
        {
            name: t('accTableCreatedAt'),
            minWidth: '200px',
            selector: (row) => row?.createdAt,
            cell: (row) => (
                <div row={row}>
                    <small>{formatDate(row.createdAt)}</small>
                </div>
            ),
        },
        {
            name: t('price'),
            selector: (row) => row?.price,
            cell: (row) => (
                <div row={row}>
                    <small>{row?.price} USD</small>
                </div>
            ),
        },
        {
            name: t('compatibleWith'),
            minWidth: '200px',
            selector: (row) => row?.id,
            cell: (row) => (
                <div row={row}>
                    <small>{row?.metadata?.compatibility}</small>
                </div>
            ),
        },
        {
            name: t('tableAction'),
            minWidth: '140px',
            selector: (row) => row?.id,
            cell: (row) => (
                <div row={row}>
                    <div className='form-check'>
                        <input
                            type='checkbox'
                            id={`pending_video_${row?.id}`}
                            className='form-check-input'
                            onChange={(e) =>
                                e.target.checked
                                    ? setIds((prev) => [...prev, Number(row.id)])
                                    : setIds((prev) => prev.filter((id) => id !== Number(row.id)))
                            }
                        />
                        <label htmlFor={`pending_video_${row?.id}`} className='form-check-label'>
                            {t('select')}
                        </label>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex a;ign-items-center mb-5'>
                    <div className='stats-icon solid-orange'>
                        <RiFileZipFill size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('allPendingFiles')}</h2>
                        <p className='text-muted fw-normal mb-0'></p>
                    </div>
                </div>

                {files?.filter((file) => !file.approved)?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={files
                            ?.filter((file) => !file.approved)
                            .sort((a, b) => {
                                return new Date(b.createdAt) - new Date(a.createdAt);
                            })}
                        pagination={files.length >= 1 && true}
                        responsive
                        theme='solarized'
                    />
                ) : (
                    <p>{t('noFilesToDisplay')}</p>
                )}
            </div>
        </div>
    );
}

export default PendingFilesTable;
