import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import { useTranslation } from 'react-i18next';

function FAQsPage() {
    const { t } = useTranslation();

    const FAQS = [
        {
            question: t('whoAreWe'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>{t('whoWeAreAnsw')}</p>
                </div>
            ),
        },
        {
            question: t('difiDetailsQuest'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>
                        <strong className='text-white'>Solana Contract</strong> –{' '}
                        <a href='https://solscan.io/token/H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6'>
                            H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6
                        </a>
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>NEON EVM Contract</strong> –{' '}
                        <a href='https://neonscan.org/token/0xc901d4d435a5f9b5a4c8b4074624b867d5295034'>
                            0xc901d4d435a5F9B5a4c8B4074624B867d5295034
                        </a>
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('maxTotalSupply')}</strong> – 1,000,000 SOLE,{' '}
                        {t('assetPipeLine')}
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('tax')}</strong> – {t('buy')}: 0%, {t('sell')}: 0%
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('whitePaper')}</strong> –{' '}
                        <a href='https://solerium-1.gitbook.io/solerium'>Click Here</a>
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('trade')} Jupiter</strong> –{' '}
                        <a href='https://jup.ag/swap/SOL-H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6'>
                            SOLE/SOL
                        </a>
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('official')} x</strong> –{' '}
                        <a href='https://x.com/Solerium_io'>{t('clickHere')}</a>
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('official')} Telegram</strong> –{' '}
                        <a href='https://t.me/solerium_io'>{t('clickHere')}</a>
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('official')} TikTok</strong> –{' '}
                        <a href='https://www.tiktok.com/@solerium_io'>{t('clickHere')}</a>
                    </p>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('official')} YouTube</strong> –{' '}
                        <a href='https://www.youtube.com/@solerium_io'>{t('clickHere')}</a>
                    </p>                </div>
            ),
        },
        {
            question: t('functionsOfDifiMarket'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>{t('onDifiMarketYouCan')}:</p>
                    <ul className='mb-0 text-white'>
                        <li>{t('difiMarketBullet1')}</li>
                        <li>{t('difiMarketBullet2')}</li>
                        <li>{t('difiMarketBullet3')}</li>
                        <li>{t('difiMarketBullet4')}</li>
                        <li>.zip / .rar</li>
                    </ul>
                </div>
            ),
        },
        {
            question: t('whatWalletSupportedQues'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>{t('whatWalletSupportedAnsw')}</p>
                </div>
            ),
        },
        {
            question: t('whatAreCommissionQuest'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>
                        <strong className='text-white'>{t('fees')}</strong> – 1% {t('onEachSale')}.
                    </p>
                    <p className='mb-2'>{t('whatAreCommissionAnsw')}</p>
                </div>
            ),
        },
        {
            question: t('uploadLimit'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>{t('uploadLimitAnsw1')}</p>
                    <p className='mb-2 fw-bold'>{t('uploadLimitAnsw1')}</p>
                </div>
            ),
        },
        {
            question: t('whatDoIBuyWith'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>{t('whatDoIBuyWithAnsw')}</p>
                </div>
            ),
        },
        {
            question: t('canIChangePrice'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>{t('canIChangePriceAnsw')}</p>
                </div>
            ),
        },
        {
            question: t('moreQuestions'),
            answer: (
                <div className='text-muted'>
                    <p className='mb-2'>
                        {t('ifYouCantFindAnsw')},{' '}
                        <a
                            href='https://solerium.io/features-requests/'
                            className='text-primary'
                            rel='noopener noreferrer'
                        >
                            {t('getInTouch')}
                        </a>
                        . {t('weWillHelp')}.
                    </p>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageBanner heading={t('frequAskedQuestions')} text=''></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='accordion accordio-minimal' id='faqs'>
                                {FAQS.map((item, index) => {
                                    return (
                                        <div
                                            className='accordion-item'
                                            key={index}
                                            data-aos='fade-up'
                                            data-aos-delay={`${index * 100}`}
                                        >
                                            <h2 className='accordion-header' id={`heading${index}`}>
                                                <button
                                                    className='accordion-button'
                                                    type='button'
                                                    data-bs-toggle='collapse'
                                                    data-bs-target={`#collapse${index}`}
                                                    aria-expanded={index === 0 ? 'true' : 'false'}
                                                    aria-controls={`collapse${index}`}
                                                >
                                                    {item.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent='#faqs'
                                            >
                                                <div className='accordion-body'>{item.answer}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FAQsPage;
