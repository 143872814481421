import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GoFileSubmodule } from 'react-icons/go';
import { ImCloudUpload } from 'react-icons/im';
import { GoHeart } from 'react-icons/go';
import { HiUserCircle } from 'react-icons/hi';
import { RiHomeSmileFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Popup from './Popup';
import useWeb3 from '../../hooks/useWeb3';
import { switchNetwork } from '@wagmi/core';
import { appSettings } from '../../helpers/settings';
import ConnectWalletHander from './ConnectWalletHandler';

function MobileNav() {
    const { t } = useTranslation();
    const [connectWalletPopup, setConnectWalletPopup] = useState(false);
    const { account } = useWeb3();

    /* --------------------------------------------- 
          CHAHNGE NETWORK HANDLER
    --------------------------------------------- */
    async function changChain() {
        // eslint-disable-next-line no-unused-vars
        const network = await switchNetwork({
            chainId: appSettings.networkId,
        });
    }

    return (
        <>
            <div className='mobile-nav'>
                <div className='mobile-nav-holder'>
                    <div className='row row-cols-5 g-0'>
                        <div className='col'>
                            <NavLink className='mobile-nav-link' to='/'>
                                <RiHomeSmileFill size='1.4rem' className='mb-1' />
                                <p className='mb-0'>{t('home')}</p>
                            </NavLink>
                        </div>
                        <div className='col'>
                            <NavLink className='mobile-nav-link' to='/discover'>
                                <GoFileSubmodule size='1.4rem' className='mb-1' />
                                <p className='mb-0'>{t('files')}</p>
                            </NavLink>
                        </div>
                        <div className='col d-flex align-items-center justify-content-center'>
                            <NavLink className='mobile-nav-link primary' to='/mint'>
                                <div className='position-relative d-inline-block'>
                                    <ImCloudUpload size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('upload')}</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className='col'>
                            {account ? (
                                <NavLink className='mobile-nav-link' to='/account'>
                                    <HiUserCircle size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('account')}</p>
                                </NavLink>
                            ) : (
                                <button className='mobile-nav-link' onClick={() => setConnectWalletPopup(true)}>
                                    <HiUserCircle size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('account')}</p>
                                </button>
                            )}
                        </div>
                        <div className='col'>
                            {account ? (
                                <NavLink className='mobile-nav-link' to='/watchlist'>
                                    <GoHeart size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('wishlist')}</p>
                                </NavLink>
                            ) : (
                                <button className='mobile-nav-link' onClick={() => setConnectWalletPopup(true)}>
                                    <GoHeart size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('wishlist')}</p>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {connectWalletPopup && !account && (
                <Popup closeModal={() => setConnectWalletPopup(false)}>
                    <div className={`card-body text-center`}>
                        <h2 className='h6 mb-0 ms-2'>
                            {t('appRunOn')}{' '}
                            <span className='text-orange orange text-backline'>{appSettings.activeNetworkName}</span>
                        </h2>
                        <p className='text-muted text-sm'>{t('switchNetwork')}</p>
                        {account ? (
                            <button className='btn btn-primary switch-btn' onClick={changChain}>
                                <img src='/wcbrand.svg' alt='wc' className='me-2' width='20' />
                                {t('switchNetwork')}
                            </button>
                        ) : (
                            <ConnectWalletHander />
                        )}
                    </div>
                </Popup>
            )}
        </>
    );
}

export default MobileNav;
