import React, { useEffect } from 'react';
import { appSettings } from '../../helpers/settings';

const AdsComponent = () => {
    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {}
    }, []);

    return (
        <>
            <ins
                className='adsbygoogle'
                style={{ display: 'block', width: '100%', height: '10rem' }}
                data-ad-client={appSettings.adsenseAdClient}
                data-adtest='on'
                data-ad-slot={appSettings.adsenseAdSlot}
                data-ad-format='auto'
                data-full-width-responsive='true'
            ></ins>
        </>
    );
};

export default AdsComponent;
