import React, { useState } from 'react';
import { RiFileZipFill } from 'react-icons/ri';
import DataTable from 'react-data-table-component';
import { formatDate, truncateStart } from '../../helpers/utils';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Popup from '../../components/general/Popup';
import { appSettings } from '../../helpers/settings';
import { useContractWrite } from 'wagmi';
import { toast } from 'react-toastify';
import Web3 from 'web3';

function UserApprovedFilesTable() {
    const { contract, filesContractAbi, files, usdToken, setTransactionLoading, loadFiles } = useFiles();
    const { account } = useWeb3();
    const { t } = useTranslation();
    const [fileInfo, setFileInfo] = useState(null);
    const [newPrice, setNewPrice] = useState(fileInfo?.price || '');
    const [updatePriceModal, setUpdatePriceModal] = useState(false);

    /* --------------------------------------------- 
              UPDATE FILE'S PRICE
     --------------------------------------------- */
    const { write: web3UpdatePrice } = useContractWrite({
        address: contract?.address,
        abi: filesContractAbi,
        functionName: 'updateFile',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadFiles(contract, filesContractAbi);
                handleResetUpdate();
                setNewPrice('');
                toast.success(t('filePriceUpdated'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    function handleRequestUpdate(
        id,
        price,
        usdPrice,
        title,
        description,
        category,
        type,
        file,
        gallery,
        compatibility,
        thumbnail
    ) {
        setUpdatePriceModal(true);
        setFileInfo({
            id,
            price,
            usdPrice,
            title,
            description,
            category,
            type,
            file,
            gallery,
            compatibility,
            thumbnail,
        });
    }

    function handleResetUpdate() {
        setUpdatePriceModal(false);
        setFileInfo(null);
    }

    function triggerUpdatePrice(e) {
        e.preventDefault();

        web3UpdatePrice({
            recklesslySetUnpreparedArgs: [
                fileInfo?.id,
                [
                    fileInfo?.title,
                    fileInfo?.description,
                    fileInfo?.category,
                    fileInfo?.type,
                    fileInfo?.file,
                    fileInfo?.gallery,
                    fileInfo?.compatibility.split(','),
                    fileInfo?.thumbnail,
                ],
                Web3.utils.toWei(newPrice.toString(), 'ether'),
            ],
        });
    }

    /*** =============================================== */
    //      PERIODS TABLE COLUMNS
    /*** =============================================== */
    const columns = [
        {
            name: t('accTableFile'),
            selector: (row) => row?.metadata?.title,
            sortable: true,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/files/${row.id}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${row?.metadata?.thumbnail})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>{row?.metadata?.title}</h6>
                                <p className='text-muted small mb-0'>
                                    {truncateStart(row?.metadata?.description, 30, '...')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('accTableCreatedAt'),
            minWidth: '200px',
            selector: (row) => row?.createdAt,
            cell: (row) => (
                <div row={row}>
                    <small>{formatDate(row.createdAt)}</small>
                </div>
            ),
        },
        {
            name: t('price'),
            selector: (row) => row?.price,
            cell: (row) => (
                <div row={row}>
                    <small>{row?.usdPrice} USD</small>
                </div>
            ),
        },
        {
            name: t('compatibleWith'),
            minWidth: '200px',
            selector: (row) => row?.id,
            cell: (row) => (
                <div row={row}>
                    <small>{row?.metadata?.compatibility}</small>
                </div>
            ),
        },
        {
            name: t('tableAction'),
            minWidth: '200px',
            selector: (row) => row?.id,
            cell: (row) => (
                <div row={row}>
                    <button
                        className='btn btn-primary btn-sm'
                        type='button'
                        onClick={() =>
                            handleRequestUpdate(
                                row?.id,
                                row?.price,
                                row?.usdPrice,
                                row?.metadata?.title,
                                row?.metadata?.description,
                                row?.metadata?.category,
                                row?.metadata?.type,
                                row?.metadata?.file,
                                row?.metadata?.screenshots,
                                row?.metadata?.compatibility,
                                row?.metadata?.thumbnail
                            )
                        }
                    >
                        {t('updatePrice')}
                    </button>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
                <div className='card-body p-lg-5'>
                    <div className='d-flex a;ign-items-center mb-5'>
                        <div className='stats-icon solid-turquoise'>
                            <RiFileZipFill size='1.4rem' />
                        </div>
                        <div className='ms-3'>
                            <h2 className='mb-0 h4'>{t('filesIHaveUploaded')}</h2>
                            <p className='text-muted fw-normal mb-0'></p>
                        </div>
                    </div>

                    {files?.filter((file) => file.creator === account)?.filter((file) => file.approved).length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={files
                                ?.filter((file) => file.creator === account)
                                ?.filter((file) => file.approved)
                                .sort((a, b) => {
                                    return new Date(b.createdAt) - new Date(a.createdAt);
                                })}
                            pagination={files.length >= 1 && true}
                            responsive
                            theme='solarized'
                        />
                    ) : (
                        <p className='mb-0'>{t('accNoUploadedFiles')}</p>
                    )}
                </div>
            </div>

            {updatePriceModal && (
                <Popup closeModal={handleResetUpdate}>
                    <div className='text-center'>
                        <h2 className='h2'>{t('updateProductPrice')}</h2>
                        <p className='text-muted mb-4'>
                            Current Price is <strong className='text-primary ms-2'>{fileInfo?.usdPrice} USD</strong>
                        </p>

                        <form onSubmit={triggerUpdatePrice}>
                            <div className='mb-2 text-start'>
                                <label className='form-label'>
                                    {t('newPrice')}{' '}
                                    <span className='fw-normal text-white ms-2 text-xxs'>
                                        - {t('equals')}{' '}
                                        <i className='text-primary'>
                                            {newPrice > 0 ? Number(newPrice * usdToken) : 0} {appSettings.currency}
                                        </i>
                                    </span>
                                </label>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={newPrice}
                                    placeholder={`Add the new price in USD`}
                                    onChange={(e) => setNewPrice(Number(e.target.value))}
                                />
                            </div>
                            <button className='btn btn-primary w-100' type='submit'>
                                {t('updatePrice')}
                            </button>
                        </form>
                    </div>
                </Popup>
            )}
        </>
    );
}

export default UserApprovedFilesTable;
