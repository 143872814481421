/* ------------------------------------------------------------------------------------------ 
      Chinese TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const chineseTranslation = {
    recentlyAdded: '最近添加',
    topTrending: '热门排行',
    featuredItems: '我们推荐的文件',
    noCategoryMatch: '没有与此类别匹配的文件',
    noReviewsYet: '还没有评论',
    accessNow: '立即访问',
    downloadNow: '立即下载',
    accessFileFor: '仅需',
    youHaveAccess: '您已经可以访问此文件',
    viewScreenshots: '查看截图',
    promoted: '推广',
    addToList: '添加到我的列表',
    inList: '在您的列表中',
    fileReviews: '文件评论',
    fileGallery: '文件画廊',
    pending: '待处理',
    pendingMsg: '此文件正在等待管理员批准',
    from: '来自',
    ratings: '评分',
    promoteThisFile: '推广此文件',
    promoteFor: '推广费用',
    youHaveReview: '您已经对此文件进行了评论',
    rateThisFile: '评价此文件',
    fileHasNoReviews: '此文件还没有评论',
    discoverAll: '发现所有文件',
    filterByCategory: '按类别筛选',
    sortByDate: '按日期排序',
    sortByPrice: '按价格排序',
    loadMore: '加载更多',
    select: '选择',
    all: '全部',
    noMatches: '没有符合此筛选条件的文件',
    newest: '最新',
    oldest: '最旧',
    highPrice: '价格从高到低',
    lowPrice: '价格从低到高',
    headerLinks: '链接',
    headerDiscover: '发现',
    headerActivity: '活动',
    headerFaqs: '常见问题',
    headerContactUs: '联系我们',
    trackTransaction: '跟踪交易',
    headerMyAccount: '我的账户',
    headerMyWishList: '我的愿望清单',
    headerAdminPanel: '管理员面板',
    headerAdminOnly: '仅管理员',
    weAreSocial: '关注我们',
    createdBy: '创建者',
    copyRights: '版权所有',
    activityHeading: '活动',
    tableActivityHeading: '最新活动',
    tableNoRecords: '暂无活动记录',
    tableUser: '用户',
    tableTime: '时间',
    tableAction: '操作',
    tableMintFile: '上传的文件',
    tableApproveMint: '已批准的文件',
    tableBuyFile: '已购买的文件',
    tableWishList: '添加到愿望清单的文件',
    tableNewSubscriber: '新订阅者',
    tablePromote: '文件已添加到推广',
    contactPageHeading: '联系我们',
    contactPageSubHeading: '填写您需要的表单，我们将在24小时内通过电子邮件与您联系',
    followUs: '关注我们以获取最新动态。',
    yourNameLabel: '您的姓名',
    yourNamePlaceholder: '输入您的姓名',
    yourNameErrorMsg: '请输入您的姓名',
    yourEmail: '您的电子邮件',
    yourEmailPlaceholder: '输入您的电子邮件地址',
    yourEmailErrorMsg: '请输入有效的电子邮件地址',
    yourEmailInvalidPattern: '无效的电子邮件地址',
    yourMessage: '您的留言',
    yourMessagePlaceholder: '请输入您的留言',
    yourMessageErrorMsg: '请输入您的留言',
    submitRequest: '提交请求',
    submitting: '正在提交...',
    successSubmitHeading: '谢谢',
    successSubmitMsg: '我们将尽快回复您',
    returnHome: '返回首页',
    utility: '实用工具',
    title: '标题',
    titleErrorMsg: '请输入视频标题',
    titlePlaceHolder: '输入产品标题',
    description: '描述',
    descriptionErrorMsg: '请输入文件描述',
    descriptionPlaceholder: '添加有关文件的简短描述',
    category: '类别',
    categoryPlaceholder: '选择一个类别',
    compatibleWith: '兼容性',
    compatibleWithPlaceholder: '选择操作系统兼容性',
    compatibleWithErrorMsg: '请选择此软件兼容的操作系统',
    price: '价格',
    pricePlaceholder: '输入文件的期望价格',
    priceErrorMsg: '请输入文件价格',
    uploadFile: '上传您的文件',
    uploadFileErrorMsg: '请上传您的文件',
    uploadFileThumb: '上传文件缩略图',
    uploadFileThumbErrorMsg: '请上传文件缩略图',
    uploadFileScreenshots: '上传文件截图',
    uploadFileScreenshotsErrorMsg: '请上传文件截图',
    wishlistPageHeading: '我的愿望清单',
    myAccountHeading: '我的帐户',
    sellNewProduct: '销售新产品',
    salesProfits: '销售利润',
    noProfitsYet: '您还没有任何利润',
    claimProfits: '领取利润',
    accTableFile: '文件',
    accTableCreator: '创建者',
    accTableCreatedAt: '创建时间',
    filesIHaveUploaded: '我上传的文件',
    accNoUploadedFiles: '没有要显示的上传文件',
    accPendingFiles: '我的待处理文件',
    accNoPendingFiles: '没有要显示的待处理文件',
    accPurchasedFiles: '我购买的文件',
    accNoPurchasedFiles: '没有要显示的已购买文件',
    adminPanel: '管理员面板',
    appProfits: '应用程序利润',
    approveSelected: '批准选定',
    blockSelected: '阻止选定',
    noFilesToDisplay: '没有要显示的文件',
    allPendingFiles: '所有待处理文件',
    status: '状态',
    active: '活跃',
    setPromotionPrice: '设置促销价格',
    currentPriceIs: '当前价格为',
    promotionPrice: '促销价格',
    promotionPricePlaceholder: '输入促销价格',
    promotionPriceErrorMsg: '请添加促销价格',
    setPrice: '设置价格',
    transferContractOwnership: '转移合同所有权',
    newOwner: '新所有者',
    newOwnerPlaceholder: '输入新的所有者地址',
    newOwnerErrorMsg: '无效地址！请检查您输入的地址',
    transfer: '转移',
    newOwnerSuccessHeading: '您不再是该合同的所有者',
    newOwnerSuccessText: '所有权已转移到另一个帐户，您无法再访问此页面。祝您好运',
    updatePrice: '更新价格',
    newPrice: '新价格',
    updateProductPrice: '更新产品价格',
    equals: '等于',
    txLoadingHeading: '这通常需要一些时间',
    txLoadingMsg: '我们正在处理您的交易，请不要刷新浏览器',
    txLoadingSmText: '处理交易中',
    rating: '评级',
    reason: '原因',
    enterRatingReason: '请输入评级原因',
    reasonPleaceholder: '“例如：代码质量”',
    message: '留言',
    messagePlaceholder: '输入您的留言',
    ratingMsgError: '请输入评级留言',
    submitRating: '提交您的评级',
    close: '关闭',
    uploadingYourAsset: '正在上传您的资产',
    ipfsMsg: '这可能需要一些时间，直到我们将您的资产保存到IFPS...',
    cover: '封面',
    avatar: '头像',
    screenshot: '屏幕截图',
    appRunOn: '此应用程序设置为在以下运行',
    connectToNetwork: '请连接到此网络',
    switchNetwork: '切换网络',
    oneOfYourItem: '您的其中一个项目',
    err: '哎呀！出错了',
    youHaveAccessNow: '太棒了！您现在有权限访问',
    fillTheFields: '请填写字段以便提交',
    addedToWatchlist: '太棒了！您已将此文件添加到您的观看列表中',
    claimedProfits: '太棒了！您已领取利润',
    filePriceUpdated: '太棒了！您的文件价格已更新',
    approvedSelectedFiles: '太棒了！您已批准所选文件',
    blockedSelectedFiles: '太棒了！您已阻止所选文件',
    promotionPriceAdded: '太棒了！促销价格已设置',
    ownerShipChanged: '太棒了！所有权已转移给另一个帐户',
    fileIsPromoted: '太棒了！您的文件已经得到了推广',
    fileUploaded: '太棒了！您已成功上传文件',
    filesTooLarge: '文件太大',
    buy: '购买',
    charts: '图表',
    audit: '审核',
    lottery: '抽奖',
    frequAskedQuestions: '常见问题',
    whoAreWe: '我们是谁？',
    whoWeAreAnsw:
        '我们是一支由Web3开发人员组成的团队，致力于实现互联网、区块链和去中心化的未来。该项目使每个用户可以安全地从他们的PC或智能手机直接销售文件，而无需第三方。',
    maxTotalSupply: '最大/总供应量',
    assetPipeLine: '和区块链的资产流水线。',
    tax: '税',
    sell: '出售',
    holderRewards: '持有者奖励',
    marketing: '市场营销',
    whitePaper: '白皮书',
    trade: '交易',
    official: '官方',
    clickHere: '点击这里',
    difiDetailsQuest: 'SOLE市场的详情和链接',
    functionsOfDifiMarket: 'SOLE市场的功能是什么？',
    onDifiMarketYouCan: '在SOLE市场上，您可以',
    difiMarketBullet1: '出售您的文件、程序和任何类型的数字文件。',
    difiMarketBullet2: '购买其他用户的文件。',
    difiMarketBullet3: '在购买后留下反馈。',
    difiMarketBullet4: '提取您的销售收入。',
    whatWalletSupportedQues: '支持哪些钱包？',
    whatWalletSupportedAnsw: 'Digital Files使用WalletConnect，并支持几十种不同的钱包与商店进行交互。',
    whatAreCommissionQuest: '佣金是多少？',
    fees: '费用',
    onEachSale: '每笔销售',
    whatAreCommissionAnsw:
        '每个文件销售会收取1%的税费，系统使用区块链和IPFS，每笔销售的1%税费使我们能够使系统正常运行。',
    uploadLimit: '上传限制？',
    uploadLimitAnsw1: '每个文件的最大上传限制约为100MB。',
    uploadLimitAnsw2: '用户可以上传和出售的文件数量没有限制。',
    whatDoIBuyWith: '我用什么购买文件？',
    whatDoIBuyWithAnsw: '可以使用SOLE代币购买文件，甚至需要一些BNB来处理交易，因为NEON是原生货币。',
    canIChangePrice: '我可以更改文件的价格吗？',
    canIChangePriceAnsw: '目前无法更改文件的价格，请根据出售文件时的SOLE价格进行正确计算。',
    moreQuestions: '还有更多问题吗？',
    ifYouCantFindAnsw: '如果您找不到答案',
    getInTouch: '请联系我们',
    weWillHelp: '我们将很乐意帮助您',
    education: '教育材料',
    eBooks: '电子书',
    gDesign: '图形设计',
    os: '操作系统',
    programming: '编程',
    antiVirus: '防病毒和安全',
    db: '数据库',
    mutimedia: '多媒体',
    gaming: '游戏',
    openSource: '开源',
    adult: '成人 18+',

    // Latest Translations keys --------------
    faqs: '常见问题',
    contactUs: '联系我们',
    aboutUs: '关于我们',
    activity: '活动',
    aboutUsHeading: '关于我们',
    wordsAboutUsHeading: '关于我们的一些话',
    appLauncingTime: 'SOLE于2024年4月28日发布',
    aboutWord1: '第一个在web3上基于区块链创建的数字文件市场，由NEON EVM提供支持。',
    aboutWord2: '以最简便、最安全的方式购买、出售和推广您的数字创作。无需注册，无第三方参与。这就是去中心化的SOLE。',
    creator: '创造者',
    topFeatureHeading: '顶级功能',
    toPFeatureSubheading: 'SOLE市场的三大独一无二的顶级功能！',
    decentralized: '去中心化',
    decentralizedDescription:
        'SOLE市场是100%去中心化的，所有数据都保存在以太坊区块链上，以实现最大安全性。用户通过连接他们的钱包进行交互，无需外部管理。',
    hackerFreeHeading: '免受黑客攻击',
    hackerFreeDescription:
        '系统不会在网络服务器上保存任何数据，不存在数据风险，用户在连接时与其钱包进行交互，无法被操纵，管理员无法访问用户资金。',
    lowFeesHeading: '低费用',
    lowFeesDescription:
        '全球可能是最低的，销售仅收取1%的佣金，没有其他隐藏费用，用户可以随时从他们的账户提取SOLE，无需等待或确认，用户仅在下单时以SOLE支付。',
    aboutUsQuote: '"通过SOLE市场，我们已经进入了未来，首先是这样。"',
    token: '代币',
    founder: '创始人',
    digitalFilesMarket: '数字文件市场',
    subscribeThanks: '感谢您的订阅',
    toastError: '哎呀！出错了！',
    resources: '资源',
    newsletterSignup: '订阅通讯',
    newsletterText: '订阅通讯并保持最新',
    newsletterErrMsg: '请输入您的电子邮件',
    newsletterPlaceholder: '添加您的电子邮件',
    sitemap: '网站地图',
    bestSellers: '畅销商品',
    topRated: '最高评级',
    home: '首页',
    files: '文件',
    resetFilters: '重置筛选',
    searchForFiles: '搜索产品',
    noSearchResults: '没有符合搜索条件的产品',
    noFilesAtTheMoment: '当前没有文件。',
    emptyHere: '这里是空的',
    poromotionEndsAt: '促销截止时间',
    sortByRate: '按评级排序',
    shopBrandDescription: 'Web3分散式数字文件商店',
    uploadFileNav: '上传文件',
    account: '账户',
    wishlist: '愿望清单',
    upload: '上传',
    menulegal: '法律',
    menuprivacy: '隐私政策',
    menutos: '服务条款',
    menulegaopinion: '法律意见',
    share: '分享',
};
