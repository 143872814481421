import React, { useState, useMemo } from 'react';
import useFiles from '../../hooks/useFiles';
import { Link } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';

function HeaderSearch() {
    const { files } = useFiles();
    const [searchQuery, setSearchQuery] = useState('');

    const searchInData = useMemo(() => {
        return [
            ...files
                ?.filter((file) => file?.approved)
                ?.map((file) => ({ title: file?.metadata?.title, type: 'File', url: `/files/${file?.id}` })),
        ];
    }, [files]);

    const searchResults = useMemo(() => {
        if (searchQuery !== '') {
            return searchInData?.filter((item) => {
                return item?.title.toLowerCase().includes(searchQuery?.toLowerCase()?.replace(/\s/g, ''));
            });
        } else {
            return [];
        }
    }, [searchQuery, searchInData]);

    return (
        <>
            <div className='dropdown w-100 mx-2' style={{ maxWidth: '40rem' }}>
                <div className='header-search-bar'>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Search'
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                    />
                    <div className='header-search-btn'>
                        <BiSearch size={20} />
                    </div>
                </div>
                {searchResults?.length > 0 && (
                    <div
                        className='dropdown-menu search-dropdown show w-100'
                        data-bs-popper='static'
                        style={{ zIndex: '999' }}
                    >
                        {searchResults?.map((result, index) => {
                            return (
                                <li key={index} onClick={() => setSearchQuery('')}>
                                    <Link
                                        to={`${result?.url}`}
                                        className='dropdown-item d-flex align-items-center justify-content-between'
                                    >
                                        <span>{result?.title}</span>
                                        <span className='text-sm'>{result?.type}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </div>
                )}

                {searchResults?.length === 0 && searchQuery !== '' && (
                    <div className='dropdown-menu show w-100' data-bs-popper='static' style={{ zIndex: '999' }}>
                        <li>
                            <span className='dropdown-item d-flex align-items-center justify-content-center text-muted'>
                                <span className='text-sm'>No Data Found</span>
                            </span>
                        </li>
                    </div>
                )}
            </div>
        </>
    );
}

export default HeaderSearch;
