import React from 'react';

const FilesContext = React.createContext({
    files: [],
    filesWithBuyers: [],
    contract: null,
    filesContractAbi: null,
    owner: '',
    appProfits: '0',
    previewModal: false,
    mintUploadProgress: 0,
    preview: '',
    ratingModalSrc: {},
    ratingModalState: false,
    buyers: [],
    transactionLoading: false,
    uploadingProgress: false,
    promotionPrice: 0,
    tokenInUSD: 0,
    usdToken: 0,
    activities: [],
    lang: { code: 'en', key: 'English' },
    loadContract: () => {},
    loadFiles: () => {},
    loadFileBuyers: () => {},
    loadAppOwner: () => {},
    setTransactionLoading: () => {},
    setPreviewSrc: () => {},
    setPreviewModal: () => {},
    loadMintUploadProgress: () => {},
    loadAppProfits: () => {},
    setUploadingProgress: () => {},
    loadActivities: () => {},
    setRatingModalState: () => {},
    setRatingModalSrc: () => {},
    getContractAbi: () => {},
    loadPromotionPrice: () => {},
    switchLanguage: () => {},
    loadPaymentTokenPriceInUSD: () => {},
    loadFileBuyersWithBuyers: () => {},
});

export default FilesContext;
