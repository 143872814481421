import React from 'react';
import useApp from '../../hooks/useApp';
import Langs from './Langs';
import { Link } from 'react-router-dom';
import { FiYoutube } from 'react-icons/fi';
import { FaTelegram, FaTiktok, FaTwitter  } from 'react-icons/fa';
import ToggleThemeHandler from './ToggleThemeHandler';
import { useTranslation } from 'react-i18next';
import useFiles from '../../hooks/useFiles';
import cg from './cg.png';


function TopBanner() {
    const { t } = useTranslation();
    const { themeMode } = useApp();
    const { usdToken } = useFiles();

    return (
        <>
            <div
                className={`${themeMode === 'light' ? 'bg-dark text-light' : 'bg-dark'} py-1 position-relative`}
                style={{ zIndex: 1001 }}
            >
                <div className='container pb-2 pb-lg-0'>
                    <div className='row gx-0 gy-2 align-items-center'>
                        <div className='col-lg-4'>
                            <ul className='list-inline p-0 mb-0 d-flex justify-content-center justify-content-lg-start align-items-center'>
                                <li className='list-inline-item me-3'>
                                    <Langs />
                                </li>
                                <li className='list-inline-item'>
                                    <a className='text-reset' rel='noreferrer' href='https://x.com/Solerium_io'>
                                        <FaTwitter size='1rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a className='text-reset' rel='noreferrer' href='https://t.me/solerium_io'>
                                        <FaTelegram size='1rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li>
								<li className='list-inline-item'>
                                    <a className='text-reset' target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/@solerium_io'>
                                        <FiYoutube size='1rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li> 
								<li className='list-inline-item'>
                                    <a className='text-reset' target='_blank' rel='noopener noreferrer' href='https://www.tiktok.com/@solerium_io'>
                                        <FaTiktok size='1rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li> 								
                                <li className='list-inline-item'>
                                    <ToggleThemeHandler />
                                </li>
                            </ul>
                        </div>

                        <div className='col-lg-4 text-center d-none d-lg-block'>
                            <div className='d-inline-block'>
                                <a
                                    href='https://coinmarketcap.com/currencies/solerium'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='text-xs d-flex align-items-center justify-content-center text-reset'
                                >
                                    <div className='animated-bullet me-2'>
                                        <div className='animated-bullet-inner'></div>
                                    </div>
                                    <img src={cg} alt='CoinMarketCap' width='20' className='flex-shrink-0 mx-1' /> Live <img src='/token.png' alt='SOLE' width='20' className='flex-shrink-0 mx-1' />{' '}
                                    price is{' '}
                                    <strong className='fw-bold text-primary ms-2'>
                                        {(1 / usdToken).toFixed(3)} USD
                                    </strong>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-4 text-center text-lg-end'>
                            <ul className='list-inline mb-0 p-0 text-sm'>
                                <li className='list-inline-item'>
                                    <Link className='text-reset' to='/about'>
                                        {t('aboutUs')}
                                    </Link>
                                </li>
                                <li
                                    className={`list-inline-item ${
                                        themeMode === 'light' ? 'text-gray-400' : 'text-gray-700'
                                    }`}
                                >
                                    |
                                </li>
                                <li className='list-inline-item'>
                                    <Link className='text-reset' to='/faqs'>
                                        {t('faqs')}
                                    </Link>
                                </li>
                                <li
                                    className={`list-inline-item ${
                                        themeMode === 'light' ? 'text-gray-400' : 'text-gray-700'
                                    }`}
                                >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopBanner;
