import React, { useRef, useState } from 'react';
import PageBanner from '../../components/general/PageBanner';
import { sendFormData } from '../../helpers/utils';
import { useForm } from 'react-hook-form';
import { appSettings } from '../../helpers/settings';
import { FaRegSmileBeam } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function FeaturesRequestsPage() {
    const [formspreeState, setFormsPreeState] = useState(false);
    const [formspreeSendingState, setFormsPreeSendingState] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const formRef = useRef();
    const handleSucceeding = () => setFormsPreeState(true);
    const handleFailing = () => setFormsPreeState(false);
    const handleStartLoading = () => setFormsPreeSendingState(true);
    const handleStopLoading = () => setFormsPreeSendingState(true);
    const { t } = useTranslation();

    function handleFormSubmit() {
        sendFormData(
            formRef.current,
            appSettings.requestFeatureFormId,
            handleSucceeding,
            handleFailing,
            handleStartLoading,
            handleStopLoading
        );
    }

    return (
        <>
            <PageBanner heading={t('headerContactUs')} text=''></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto' data-aos='fade-up'>
                            <div className='card'>
                                <div className='card-body p-lg-5'>
                                    <div className='row'>
                                        <div className='col-lg-10 mx-auto text-center'>
                                            <h2 className='h3 mb-2'>{t('contactPageHeading')}</h2>
                                            <p className='text-muted mb-5'>{t('contactPageSubHeading')}</p>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <h2 className='h3 mb-1'>{t('weAreSocial')}</h2>
                                            <p className='small text-muted mb-3'>{t('followUs')}</p>
                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item'>
                                                    <a
                                                        className='social-link bg-hover-primary'
                                                        rel='noopener noreferrer'
                                                        href='https://x.com/Solerium_io'
                                                        target='_blank'
                                                    >
                                                        <i className='lab la-twitter'></i>
                                                    </a>
                                                </li>
                                                <li className='list-inline-item'>
                                                    <a
                                                        className='social-link bg-hover-primary'
                                                        rel='noopener noreferrer'
                                                        href='https://t.me/solerium_io'
                                                        target='_blank'
                                                    >
                                                        <i className='lab la-telegram'></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {formspreeState ? (
                                        <div className='text-center'>
                                            <FaRegSmileBeam size='4rem' className='text-primary mb-4' />
                                            <h1>{t('successSubmitHeading')}</h1>
                                            <p className='text-muted'>{t('successSubmitMsg')}</p>
                                            <Link className='btn btn-primary px-4' to='/'>
                                                {t('returnHome')}
                                            </Link>
                                        </div>
                                    ) : (
                                        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate ref={formRef}>
                                            <div className='row gy-3'>
                                                <div className='col-12'>
                                                    <label className='form-label'>{t('yourNameLabel')}</label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        placeholder={t('yourNamePlaceholder')}
                                                        name='name'
                                                        {...register('name', { required: true })}
                                                    />
                                                    {errors.name && (
                                                        <span className='invalid-feedback'>
                                                            {t('yourNameErrorMsg')}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className='col-12'>
                                                    <label className='form-label'>{t('yourEmail')}</label>
                                                    <input
                                                        type='email'
                                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                        placeholder={t('yourEmailPlaceholder')}
                                                        name='email'
                                                        {...register('email', {
                                                            required: {
                                                                value: true,
                                                                message: t('yourEmailErrorMsg'),
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: t('yourEmailInvalidPattern'),
                                                            },
                                                        })}
                                                    />
                                                    {errors.email && (
                                                        <span className='invalid-feedback'>
                                                            {errors?.email?.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className='col-12'>
                                                    <label className='form-label'>{t('yourMessage')}</label>
                                                    <textarea
                                                        rows='4'
                                                        className={`form-control ${
                                                            errors.description ? 'is-invalid' : ''
                                                        }`}
                                                        placeholder={t('yourMessagePlaceholder')}
                                                        {...register('description', { required: true })}
                                                    />
                                                    {errors.description && (
                                                        <span className='invalid-feedback'>
                                                            {t('yourMessageErrorMsg')}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className='col-12'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary w-100'
                                                        disabled={formspreeSendingState}
                                                    >
                                                        {formspreeSendingState ? t('submitting') : t('submitRequest')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FeaturesRequestsPage;
