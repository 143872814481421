import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import ActivitiesTable from './ActivitiesTable';
import { useTranslation } from 'react-i18next';
import AdBanner from '../../components/general/AdBanner.js';

function ActivitesPage() {
    const { t } = useTranslation();
    return (
        <>
            <PageBanner heading={t('activityHeading')} text=''></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='mb-5'>
                        <ActivitiesTable />
						<AdBanner />
                    </div>
                </div>
            </section>
        </>
    );
}

export default ActivitesPage;
