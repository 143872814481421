import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HiPresentationChartLine } from 'react-icons/hi';
import FileBoxEx from '../../components/general/FileBoxEx';
import FileBoxMock from '../../mock-components/FileBoxMock';
import { useTranslation } from 'react-i18next';
import useApp from '../../hooks/useApp';
import { Navigation, Autoplay } from 'swiper';

function TopRating({ fileBuyers }) {
    const fallbackFiles = [1, 2, 3, 4, 5, 6, 7, 8];
    const { t } = useTranslation();
    const { themeMode } = useApp();

    function calcReviews(reviews) {
        if (reviews?.length > 0) {
            return reviews
                ?.map((rev) => rev?.stars)
                ?.reduce((total, star) => {
                    return total + star;
                }, 0);
        } else {
            return 0;
        }
    }

    return (
        <>
            {fileBuyers?.filter((vid) => vid.approved)?.length > 0 &&
                fileBuyers.filter((vid) => vid.approved).filter((vid) => calcReviews(vid?.reviews) >= 4)?.length >
                    0 && (
                    <section className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-gray-950'}`}>
                        <div className='container py-5'>
                            <h2 className='text-lg lh-1 mb-4 d-flex align-items-center'>
                                <HiPresentationChartLine size='3.5rem' className='text-primary' />
                                <div className='ms-2'>{t('topRated')}</div>
                            </h2>
                            <div className='swiper-wrapper-padding-nav'>
                                <Swiper
                                    breakpoints={{
                                        560: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        1200: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                        },
                                        1400: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                        },
                                    }}
                                    modules={[Navigation, Autoplay]}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    speed={1000}
                                    navigation
                                >
                                    {fileBuyers?.filter((vid) => vid.approved)?.length > 0
                                        ? fileBuyers
                                              .filter((vid) => vid.approved)
                                              .filter((vid) => calcReviews(vid?.reviews) >= 4)
                                              .sort((a, b) => calcReviews(b?.reviews) - calcReviews(a?.reviews))
                                              .slice(0, 8)
                                              .map((vid) => {
                                                  return (
                                                      <SwiperSlide key={vid.id}>
                                                          <div className='px-3 px-sm-0'>
                                                              <FileBoxEx {...vid} />
                                                          </div>
                                                      </SwiperSlide>
                                                  );
                                              })
                                        : fallbackFiles.map((vid, i) => {
                                              return (
                                                  <SwiperSlide key={i}>
                                                      <FileBoxMock />
                                                  </SwiperSlide>
                                              );
                                          })}
                                </Swiper>
                            </div>
                        </div>
                    </section>
                )}
        </>
    );
}

export default TopRating;
