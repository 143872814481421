import React from 'react';
import { useTranslation } from 'react-i18next';

function NetworkAlert({ file, screenshot, forChannel, avatar, cover }) {
    const { t } = useTranslation();

    return (
        <div className='fullscreen-loader' data-aos='zoom-in-up' data-aos-duration='100'>
            <div className='fullscreen-loader-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-7 mx-auto text-center'>
                            <div className='card shadow'>
                                <div className='card-body p-4 p-lg-5'>
                                    <span className='loader-spinner mb-3'></span>
                                    <h2 className='h4 fw-normal mb-1'>{t('uploadingYourAsset')}</h2>
                                    <p className='text-muted fw-normal mb-4'>{t('ipfsMsg')}</p>

                                    {forChannel ? (
                                        <div className='row gy-3'>
                                            <div className='col-12'>
                                                <div className='d-flex align-items-center rounded bg-gray-850 p-3'>
                                                    <h6 className='mb-0'>{t('cover')}</h6>
                                                    <div
                                                        className='progress mx-3 flex-fill'
                                                        role='progressbar'
                                                        aria-label='Animated striped example'
                                                        aria-valuenow={cover}
                                                        aria-valuemin='0'
                                                        aria-valuemax='100'
                                                        style={{ height: '4px' }}
                                                    >
                                                        <div
                                                            className='progress-bar progress-bar-striped progress-bar-animated'
                                                            style={{ width: `${cover}%` }}
                                                        ></div>
                                                    </div>
                                                    <h6 className='mb-0'>{cover}%</h6>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex align-items-center rounded bg-gray-850 p-3'>
                                                    <h6 className='mb-0'>{t('avatar')}</h6>
                                                    <div
                                                        className='progress mx-3 flex-fill'
                                                        role='progressbar'
                                                        aria-label='Animated striped example'
                                                        aria-valuenow={avatar}
                                                        aria-valuemin='0'
                                                        aria-valuemax='100'
                                                        style={{ height: '4px', width: '4rem' }}
                                                    >
                                                        <div
                                                            className='progress-bar progress-bar-striped progress-bar-animated'
                                                            style={{ width: `${avatar}%` }}
                                                        ></div>
                                                    </div>
                                                    <h6 className='mb-0'>{avatar}%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row gy-3'>
                                            <div className='col-12'>
                                                <div className='d-flex align-items-center rounded bg-gray-850 p-3'>
                                                    <h6 className='mb-0'>{t('file')}</h6>
                                                    <div
                                                        className='progress mx-3 flex-fill'
                                                        role='progressbar'
                                                        aria-label='Animated striped example'
                                                        aria-valuenow={file}
                                                        aria-valuemin='0'
                                                        aria-valuemax='100'
                                                        style={{ height: '4px' }}
                                                    >
                                                        <div
                                                            className='progress-bar progress-bar-striped progress-bar-animated'
                                                            style={{ width: `${file}%` }}
                                                        ></div>
                                                    </div>
                                                    <h6 className='mb-0'>{file}%</h6>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex align-items-center rounded bg-gray-850 p-3'>
                                                    <h6 className='mb-0'>{t('screenshot')}</h6>
                                                    <div
                                                        className='progress mx-3 flex-fill'
                                                        role='progressbar'
                                                        aria-label='Animated striped example'
                                                        aria-valuenow={screenshot}
                                                        aria-valuemin='0'
                                                        aria-valuemax='100'
                                                        style={{ height: '4px', width: '4rem' }}
                                                    >
                                                        <div
                                                            className='progress-bar progress-bar-striped progress-bar-animated'
                                                            style={{ width: `${screenshot}%` }}
                                                        ></div>
                                                    </div>
                                                    <h6 className='mb-0'>{screenshot}%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NetworkAlert;
