import React, { useState } from 'react';
import PendingFilesTable from './PendingFilesTable';
import AllFilesTable from './AllFilesTable';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import useStream from '../../hooks/useStream';
import PageBanner from '../../components/general/PageBanner';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import TransferOwnership from './TransferOwnership';
import SetPromotionPrice from './SetPromotionPrice';
import { useTranslation } from 'react-i18next';

function AdminPage() {
    const [pendingFilesIds, setPendingFilessIds] = useState([]);
    const [filesIds, setFilesIds] = useState([]);
    const {
        contract,
        filesContractAbi,
        setTransactionLoading,
        loadFiles,
        loadFileBuyersWithBuyers,
        appProfits,
        loadAppProfits,
    } = useFiles();
    const { streamContract, streamAbi } = useStream();
    const { account } = useWeb3();
    const { t } = useTranslation();

    /* --------------------------------------------- 
              APPROVE FILES HANDLER
     --------------------------------------------- */
    const { write: web3ApproveFiles } = useContractWrite({
        address: contract?.address,
        abi: filesContractAbi,
        functionName: 'approveMint',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadFiles(contract, filesContractAbi);
                loadFileBuyersWithBuyers(contract, filesContractAbi);
                setPendingFilessIds([]);
                toast.success(t('approvedSelectedFiles'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    function approveFilesHandler() {
        web3ApproveFiles({
            recklesslySetUnpreparedArgs: [pendingFilesIds],
        });
    }

    /* --------------------------------------------- 
              BLOCK FILES FUNCTION
     --------------------------------------------- */
    const { write: web3BlockFiles } = useContractWrite({
        address: contract?.address,
        abi: filesContractAbi,
        functionName: 'blockFiles',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadFiles(contract, filesContractAbi);
                loadFileBuyersWithBuyers(contract, filesContractAbi);
                setPendingFilessIds([]);
                toast.success(t('blockedSelectedFiles'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    function blockFilesHandler() {
        web3BlockFiles({
            recklesslySetUnpreparedArgs: [filesIds],
        });
    }

    /* --------------------------------------------- 
              CLAIM PROFITS FUNCTION
     --------------------------------------------- */
    const { write: web3ClaimProfits } = useContractWrite({
        address: contract?.address,
        abi: filesContractAbi,
        functionName: 'claimFunds',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAppProfits(streamContract, account, streamAbi);
                toast.success(t('claimedProfits'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    function claimProfitsHandler() {
        web3ClaimProfits();
    }

    return (
        <>
            <PageBanner heading={t('adminPanel')} text=''></PageBanner>
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='card shadow-lg mb-5' data-aos='fade-up' data-aos-delay='100'>
                        <div className='card-body p-lg-5'>
                            <div className='d-flex a;ign-items-center mb-5'>
                                <div className='stats-icon solid-turquoise'>
                                    <RiMoneyDollarCircleFill size='1.4rem' />
                                </div>
                                <div className='ms-3'>
                                    <h2 className='mb-0 h4'>{t('appProfits')}</h2>
                                    <p className='text-muted fw-normal mb-0'></p>
                                </div>
                            </div>

                            {appProfits === '0' ? (
                                <p className='lead'>{t('noProfitsYet')}!</p>
                            ) : (
                                <p className='text-xl mb-0'>
                                    {appProfits} {appSettings.currency}
                                </p>
                            )}

                            {Number(appProfits) > 0 && (
                                <div className='mt-3'>
                                    <button className='btn btn-primary' onClick={claimProfitsHandler}>
                                        {t('claimProfits')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <PendingFilesTable setIds={setPendingFilessIds} />
                        {pendingFilesIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={approveFilesHandler}>
                                {t('approveSelected')}
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <SetPromotionPrice />
                    </div>
                    <div className='mb-5'>
                        <TransferOwnership />
                    </div>
                    <div className='mb-5'>
                        <AllFilesTable setIds={setFilesIds} />
                        {filesIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={blockFilesHandler}>
                                {t('blockSelected')}
                            </button>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default AdminPage;
