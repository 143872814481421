import React, { useState } from 'react';
import useFiles from '../../hooks/useFiles';
import { toast } from 'react-toastify';
import { FaStar } from 'react-icons/fa';
import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';

function Modal() {
    const { contract, filesContractAbi, ratingModalSrc, setRatingModalState, loadFiles, setTransactionLoading } =
        useFiles();

    const [rating, setRating] = useState(0);
    const [ratingReason, setRatingReason] = useState('');
    const [ratingMsg, setRatingMsg] = useState('');
    const [hover, setHover] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    /* --------------------------------------------- 
              RATE FILE FUNCTION
     --------------------------------------------- */
    const { write: web3RateFile } = useContractWrite({
        address: contract?.address,
        abi: filesContractAbi,
        functionName: 'addRate',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadFiles(contract, filesContractAbi);
                toast.success(t('youHaveAccessNow'));
                setRatingModalState(false);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    function handleRateFile(event) {
        event.preventDefault();
        setSubmitted(true);
        if (rating !== 0 && ratingReason !== '' && ratingMsg !== '') {
            web3RateFile({
                recklesslySetUnpreparedArgs: [Number(ratingModalSrc.id), ratingMsg, `${rating}`, ratingReason],
            });
        } else {
            toast.error(t('fillTheFields'));
        }
    }

    const handleRatingChange = (value) => {
        setRating(value);
    };

    return (
        <div className='fullscreen-loader' data-aos='zoom-in-up' data-aos-duration='100'>
            <div className='fullscreen-loader-inner p-4'>
                <div className='container'>
                    <div className='row mt-4'>
                        <div className='col-xl-6 col-lg-7 mx-auto text-center'>
                            <div className='card shadow position-relative'>
                                <div className='position-absolute m-3 top-0 end-0'>
                                    <button
                                        className='btn btn-dark btn-sm z-index-20'
                                        type='button'
                                        onClick={() => setRatingModalState(false)}
                                    >
                                        {t('close')}
                                    </button>
                                </div>
                                <div className='card-body p-4 p-lg-5'>
                                    <header className='text-center mb-4'>
                                        <span className='text-muted'>{t('rateThisFile')}</span>
                                        <h2 className='h3'>{ratingModalSrc?.title}</h2>
                                    </header>

                                    <form onSubmit={handleRateFile} className='text-start'>
                                        <label className='form-label d-block'>{t('rating')}</label>
                                        {[...Array(5)].map((star, index) => {
                                            const value = index + 1;
                                            return (
                                                <label key={value} style={{ cursor: 'pointer' }}>
                                                    <input
                                                        type='radio'
                                                        name='rating'
                                                        className='d-none'
                                                        value={value}
                                                        checked={rating === value}
                                                        onChange={() => handleRatingChange(value)}
                                                        required
                                                    />
                                                    <FaStar
                                                        className='star me-1'
                                                        color={value <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                                                        size={17}
                                                        onMouseEnter={() => setHover(value)}
                                                        onMouseLeave={() => setHover(0)}
                                                    />
                                                </label>
                                            );
                                        })}

                                        <div className='mt-3'>
                                            <label htmlFor='ratingReason' className='form-label'>
                                                {t('reason')}
                                            </label>
                                            <input
                                                type='text'
                                                placeholder={t('reasonPleaceholder')}
                                                className={`form-control ${
                                                    submitted && ratingReason === '' ? 'is-invalid' : ''
                                                }`}
                                                id='ratingReason'
                                                value={ratingReason}
                                                onChange={(e) => setRatingReason(e.target.value)}
                                            />
                                            {submitted && ratingReason === '' && (
                                                <div className='invalid-feedback d-block'>{t('enterRatingReason')}</div>
                                            )}
                                        </div>

                                        <div className='mt-3'>
                                            <label htmlFor='ratingMessage' className='form-label'>
                                                {t('message')}
                                            </label>
                                            <textarea
                                                rows='4'
                                                placeholder={t('messagePlaceholder')}
                                                className={`form-control ${
                                                    submitted && ratingMsg === '' ? 'is-invalid' : ''
                                                }`}
                                                id='ratingMessage'
                                                value={ratingMsg}
                                                onChange={(e) => setRatingMsg(e.target.value)}
                                            ></textarea>
                                            {submitted && ratingMsg === '' && (
                                                <div className='invalid-feedback d-block'>{t('ratingMsgError')}</div>
                                            )}
                                        </div>

                                        <div className='mt-3'>
                                            <button
                                                className='btn btn-primary btn-sm px-4'
                                                type='submit'
                                                disabled={rating === 0}
                                            >
                                                {t('submitRating')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
