/* ------------------------------------------------------------------------------------------ 
      ENGLISH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const englishTranslation = {
    // HOMEPAGE TEXTS
    recentlyAdded: 'Recently Added',
    topTrending: 'Top Trending',
    featuredItems: 'Our Featured Files',
    noCategoryMatch: "There're no Files match this category",
    noReviewsYet: 'No reviews yet',
    accessNow: 'Access Now',
    downloadNow: 'Download Now',
    accessFileFor: 'Access this file for only',
    youHaveAccess: 'You already have access to this file',
    viewScreenshots: 'View Screenshots',
    promoted: 'Promoted',
    addToList: 'Add to my list',
    inList: 'In your List',
    fileReviews: 'File Reviews',
    fileGallery: 'File Gallery',
    pending: 'Pending',
    pendingMsg: 'This file is waiting for admin approval',
    from: 'From',
    ratings: 'Ratings',
    promoteThisFile: 'Promote This File',
    promoteFor: 'Promote this for',
    youHaveReview: "You've already reviewed this file",
    rateThisFile: 'Rate this File',
    fileHasNoReviews: 'This file has no reviews yet',

    // DISCOVER PAGE TEXTS
    discoverAll: 'Discover All Files',
    filterByCategory: 'Filter by Category',
    sortByDate: 'Sort by Date',
    sortByPrice: 'Sort by Price',
    loadMore: 'Load More',
    select: 'Select',
    all: 'All',
    noMatches: 'No Files match this filter',
    newest: 'Newest First',
    oldest: 'Oldest First',
    highPrice: 'Price: High to Low',
    lowPrice: 'Price: Low to High',

    // Header TEXTS
    headerLinks: 'Links',
    headerDiscover: 'Discover',
    headerActivity: 'Activity',
    headerFaqs: 'FAQs',
    headerContactUs: 'Contact us',
    trackTransaction: 'Track Transactions',
    headerMyAccount: 'My Account',
    headerMyWishList: 'My Wishlist',
    headerAdminPanel: 'Admin Panel',
    headerAdminOnly: 'Admin Only',

    // FOOTER TEXTS
    weAreSocial: 'We Are Social',
    createdBy: 'Created By',
    copyRights: 'All rights reserved',

    // ACTIVITY PAGE TEXTS
    activityHeading: 'Activities',
    tableActivityHeading: 'Latest Activities',
    tableNoRecords: "There're no activities yet to display",
    tableUser: 'User',
    tableTime: 'Time',
    tableAction: 'Action',
    tableMintFile: 'Uploaded File',
    tableApproveMint: 'Approved File',
    tableBuyFile: 'Bought File',
    tableWishList: 'Added file to Watchlist',
    tableNewSubscriber: 'New Subscriber',
    tablePromote: 'The file has been added to the promotion',

    // CONTACT US TEXTS
    contactPageHeading: 'Contact us about any issue',
    contactPageSubHeading: "Fill the form with what you need and we'll reach you via email in under 24 hous",
    followUs: 'Follow us to keep updated.',
    yourNameLabel: 'Your Name',
    yourNamePlaceholder: 'Enter your Name',
    yourNameErrorMsg: 'Please enter your name',
    yourEmail: 'Your Email',
    yourEmailPlaceholder: 'Enter your email address',
    yourEmailErrorMsg: 'Please enter valid email address',
    yourEmailInvalidPattern: 'Invalid email address',
    yourMessage: 'Your Message',
    yourMessagePlaceholder: 'Your Message about any request',
    yourMessageErrorMsg: 'Please enter your message',
    submitRequest: 'Submit your request',
    submitting: 'Submitting...',
    successSubmitHeading: 'Thanks',
    successSubmitMsg: "We'll be back to you as soon as possible",
    returnHome: 'Return Home',

    // UPLOAD FILE PAGE TEXTS
    utility: 'Utility',
    title: 'Title',
    titleErrorMsg: 'Please enter your video title',
    titlePlaceHolder: 'Enter your product title',
    description: 'Description',
    descriptionErrorMsg: 'Please enter your file description',
    descriptionPlaceholder: 'Add a short description about your file',
    category: 'Category',
    categoryPlaceholder: 'Select a category',
    compatibleWith: 'Compatible With',
    compatibleWithPlaceholder: 'Select a OS compatibility',
    compatibleWithErrorMsg: 'Please choose which OS does this software compatible with',
    price: 'Price',
    pricePlaceholder: 'Enter your file desired price in',
    priceErrorMsg: 'Please enter your file price',
    uploadFile: 'Upload your file',
    uploadFileErrorMsg: 'Please upload your file',
    uploadFileThumb: 'Upload your file thumbnail',
    uploadFileThumbErrorMsg: 'Please upload your file thumbnail',
    uploadFileScreenshots: 'Upload file Screenshots',
    uploadFileScreenshotsErrorMsg: 'Please upload your file screenshot',

    // MY WISHLIST PAGE TEXT
    wishlistPageHeading: 'My Wishlist',

    // MY ACCOUNT TEXTS
    myAccountHeading: 'My Account',
    sellNewProduct: 'Sell New Product',
    salesProfits: 'Sales Profits',
    noProfitsYet: "You don't have any profits yet",
    claimProfits: 'Claim Profits',
    accTableFile: 'File',
    accTableCreator: 'Creator',
    accTableCreatedAt: 'Created At',
    filesIHaveUploaded: "Files I've Uploaded",
    accNoUploadedFiles: "There're no uploaded files to display",
    accPendingFiles: 'My Pending Files',
    accNoPendingFiles: "There're no pending files to display",
    accPurchasedFiles: 'My Purchased Files',
    accNoPurchasedFiles: "There're no purchased files to display",

    // ADMIN PANEL TEXTS
    adminPanel: 'Admin Panel',
    appProfits: 'App Profits',
    approveSelected: 'Approve Selected',
    blockSelected: 'Block Selected',
    noFilesToDisplay: 'There are no files to display',
    allPendingFiles: 'All Pending Files',
    status: 'Status',
    active: 'Active',
    setPromotionPrice: 'Set Promotion Price',
    currentPriceIs: 'Current Price is',
    promotionPrice: 'Promotion Price',
    promotionPricePlaceholder: 'Enter the promotion price',
    promotionPriceErrorMsg: 'Please add promotion price',
    setPrice: 'Set Price',
    transferContractOwnership: 'Transfer Contracts Ownership',
    newOwner: 'New Owner',
    newOwnerPlaceholder: 'Enter the new owner address form',
    newOwnerErrorMsg: "Invalid Address!, please check the address you've entered",
    transfer: 'Transfer',
    newOwnerSuccessHeading: "You're no longer the owner of this contract",
    newOwnerSuccessText:
        "The Ownership goes to another account now, you're no longer have access to this page. Good luck",

    updatePrice: 'Update Price',
    newPrice: 'New Price',
    updateProductPrice: 'Update Product Price',
    equals: 'Equals',

    // GENERAL COMPONENTS TEXTS
    txLoadingHeading: 'This normally takes some time',
    txLoadingMsg: "We're processing your transaction, please don't reload your browser",
    txLoadingSmText: 'Processing Transaction',
    rating: 'Rating',
    reason: 'Reason',
    enterRatingReason: 'Please enter the rating reason',
    reasonPleaceholder: "'ex: Code Quality'",
    message: 'Message',
    messagePlaceholder: 'Enter you review message',
    ratingMsgError: 'Please enter the rating message',
    submitRating: 'Submit your rating',
    close: 'Close',
    uploadingYourAsset: 'Uploading your assets',
    ipfsMsg: 'This could take some time until we save your assets to IFPS...',
    cover: 'Cover',
    avatar: 'Avatar',
    screenshot: 'Screenshot',
    appRunOn: 'This app is set to run on',
    connectToNetwork: 'Please connect to this network',
    switchNetwork: 'Switch Network',
    oneOfYourItem: 'One of your Items',

    // TOASTS TEXTS
    err: 'Oops! Something went error',
    youHaveAccessNow: 'Great! You have access now',
    fillTheFields: 'Please fill the fields to be able to submit',
    addedToWatchlist: 'Great! You have added this file to your wachlist',
    claimedProfits: 'Great! You have claimed your profits',
    filePriceUpdated: 'Great! your file price has been updated',
    approvedSelectedFiles: 'Great! You have approved selected Files',
    blockedSelectedFiles: 'Great! You have blocked selected Files',
    promotionPriceAdded: 'Great! Promotion price has been set',
    ownerShipChanged: 'Great! Ownership has been transfered to another account',
    fileIsPromoted: 'Great! your file has been promoted',
    fileUploaded: 'Great! You have successfully uploaded a file',
    filesTooLarge: 'Files are too large',

    // HEADER LINKS
    buy: 'Buy',
    charts: 'Charts',
    audit: 'Audit',
    lottery: 'Lottery',

    // FAQS
    frequAskedQuestions: 'Frequently Asked Questions',
    whoAreWe: 'Who are we?',
    whoWeAreAnsw:
        'We are a team of Web3 developers, who are implementing what will be the future of the internet,blockchain and decentralization. This project allows each user to securely sell their files, directly from their PC or smartphone, and without third parties.',
    maxTotalSupply: 'Max/Total Supply',
    assetPipeLine: 'and asset pipeline for blockchains.',
    tax: 'Tax',
    sell: 'Sell',
    holderRewards: 'Holders Rewards',
    marketing: 'Marketing',
    whitePaper: 'Whitepaper',
    trade: 'Trade',
    official: 'Official',
    clickHere: 'Click Here',
    difiDetailsQuest: 'SOLE Details and Links',
    functionsOfDifiMarket: 'Functions of the SOLE Market?',
    onDifiMarketYouCan: 'On the SOLE market you can',
    difiMarketBullet1: 'Sell your files, programs and any type of digital file.',
    difiMarketBullet2: 'Purchasing other users Files.',
    difiMarketBullet3: 'Leave Feedback after a purchase.',
    difiMarketBullet4: 'Withdraw your SOLE Of sales.',
    whatWalletSupportedQues: 'What wallets are supported?',
    whatWalletSupportedAnsw:
        'Digital Files uses WalletConnect and supports dozens of different wallets to interact with the Shop.',
    whatAreCommissionQuest: 'What are the commissions?',
    fees: 'Fees',
    onEachSale: 'on each Sale',
    whatAreCommissionAnsw:
        'A 1% tax is applied to each file sold, the system uses the blockchain and IPFS, and the 1% tax on each sale allows us to keep the system up and running perfectly.',
    uploadLimit: 'Upload Limits?',
    uploadLimitAnsw1: 'The maximum upload limit for each file is about 100MB',
    uploadLimitAnsw2: 'There are no limits to the number of files a user can upload and sell.',
    whatDoIBuyWith: 'What do I buy the Files with?',
    whatDoIBuyWithAnsw:
        'The files can be purchased using the SOLE Token, even a few NEON are needed to manage the transactions as it is the native currency.',
    canIChangePrice: 'Can I change the price of a File?',
    canIChangePriceAnsw:
        'At the moment it is not possible to change the price of a file, do the calculations correctly based on the price of the SOLE when you sell a file.',
    moreQuestions: 'More questions?',
    ifYouCantFindAnsw: "If you can't find the answers here",
    getInTouch: 'get in touch',
    weWillHelp: 'We will be happy to help',

    // CATEGORY TEXTS
    education: 'Education Materials',
    eBooks: 'eBooks',
    gDesign: 'Graphic design',
    os: 'Operating systems',
    programming: 'Programming',
    antiVirus: 'Antivirus and security',
    db: 'Database',
    mutimedia: 'Multimedia',
    gaming: 'Gaming',
    openSource: 'Open Source',
    adult: 'Adults 18+',

    // Latest Translations keys --------------
    faqs: 'FAQs',
    contactUs: 'Contact us',
    aboutUs: 'About Us',
    activity: 'Activities',
    aboutUsHeading: 'About Us',
    wordsAboutUsHeading: 'Some words about',
    appLauncingTime: 'SOLE launched 28 April 2024',
    aboutWord1: 'The first Digital Files market created on the Blockchain in web3, Powered by NEON EVM.',
    aboutWord2:
        'Buy, Sell, and Promote your Digital Creations in the easiest and safest way on the market. No registration, no third parties involved. This is Decentralization SOLE.',
    creator: 'Creator',
    topFeatureHeading: 'Top Features',
    toPFeatureSubheading: 'Top 3 Features in the SOLE Market that are unrivaled!',
    decentralized: 'Decentralized',
    decentralizedDescription:
        'The SOLE Market is 100% Decentralized, everything is saved on the NEON EVM Blockchain for maximum security, Users interact by connectingtheir Wallet without external management.',
    hackerFreeHeading: 'Hacker FREE',
    hackerFreeDescription:
        'The system saves nothing on the web server, there is no data at risk, users interact with their wallet when connected, nothing can be manipulated, and the admins have no access to user funds.',
    lowFeesHeading: 'Low Fees',
    lowFeesDescription:
        'Globally probably the lowest, only 1% commission on sales, no other hidden fees, users can withdraw SOLE from their account whenever they want, without waiting or confirmations, users pay in SOLE only at the time of order.',
    aboutUsQuote: '"With SOLE Market we are already in the future, first of all."',
    token: 'Token',
    founder: 'Founder',
    digitalFilesMarket: 'Digital Files Market',
    subscribeThanks: 'Thank you for subscribing',
    toastError: 'Oops! something went wrong!',
    resources: 'Resources',
    newsletterSignup: 'Newsletter Signup',
    newsletterText: 'Subscribe to the newsletter and stay updated',
    newsletterErrMsg: 'Please enter your email',
    newsletterPlaceholder: 'Add your email',
    sitemap: 'Sitemap',
    bestSellers: 'Best Sellers',
    topRated: 'Top Rated',
    home: 'Home',
    files: 'Files',
    resetFilters: 'Reset Filters',
    searchForFiles: 'Search for products',
    noSearchResults: "There's no products match your search",
    noFilesAtTheMoment: "There're no files at the moment.",
    emptyHere: "It's empty here",
    poromotionEndsAt: 'Promotion ends at',
    sortByRate: 'Sort By Rate',
    shopBrandDescription: 'Decentralized Web3 Digital Files Store',
    uploadFileNav: 'Upload File',
    account: 'Account',
    wishlist: 'Wishlist',
    upload: 'Upload',
    menulegal: 'Legal',
    menuprivacy: 'Privacy Policy',
    menutos: 'Terms of Service',
    menulegaopinion: 'Legal Opinion',
    share: 'Share',
};
