import React from 'react';
import useStream from '../../hooks/useStream';
import PageBanner from '../../components/general/PageBanner';
import FileBoxEx from '../../components/general/FileBoxEx';
import { useTranslation } from 'react-i18next';
import useFiles from '../../hooks/useFiles';
import { useMemo } from 'react';

function MyListPage() {
    const { watchList } = useStream();
    const { files } = useFiles();

    const extractedWatchList = useMemo(() => {
        const filesIds = files?.map((file) => file?.id);
        const watchListIds = watchList?.map((file) => file?.id);
        const intersection = watchListIds?.filter((file) => filesIds.indexOf(file) !== -1);

        return files?.filter((file) => intersection?.includes(file?.id));
    }, [watchList, files]);

    const { t } = useTranslation();

    return (
        <>
            <PageBanner heading={t('wishlistPageHeading')} text=''></PageBanner>
            <section className='py-5'>
                <div className='container py-5'>
                    {extractedWatchList && (
                        <div className='row gy-4 justify-content-center'>
                            {extractedWatchList.map((file, i) => {
                                return (
                                    <div className='col-lg-3 col-md-4 col-sm-6' key={i}>
                                        <FileBoxEx {...file} />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default MyListPage;
