/* -----------------------------------------------------------------------------------------

    THSI FILE CONTAINS THE IMPORTANT WEB3/UI CONFIGURATIONS FOR THE APP

----------------------------------------------------------------------------------------- */
import React from 'react';
import {
    FaSchool,
    FaBook,
    FaDesktop,
    FaTools,
    FaCode,
    FaShieldVirus,
    FaDatabase,
    FaVideo,
    FaGamepad,
    FaImages,
    FaFileVideo,
} from 'react-icons/fa';
import { DiWindows, DiApple, DiLinux, DiAndroid, DiOpensource } from 'react-icons/di';

export const appSettings = {
    /* APP MAIN LOGO - REPLACE IT FROM THE PUBLIC FOLDER */
    logo: '/logo192.png',
    logoLight: '/logo192.png',

    /* APP MAIN LOGO WIDTH - CHANGE IT ACCORDING TO YOUR OWN LOGO */
    logoWidth: '64',

    /* APP BRAND NAME - USED IN ALL PAGES & META TAGE - CHANGE TO YOUR OWN BRAND NAME*/
    brandName: 'SOLE - Digital Marketplace',

    /* APP BRAND DESCRIPTION - USED IN HOME PAGE & META TAGS - PUT A SENTENCE THAT DESCRIBE YOUR APP*/
    brandDescription: 'Decentralized Files Marketplace powered by NEON and Solerium',

    /* APP AUTHOR - CHANGE IT IF YOU HAVE A LICENSE KEY OR PURCHASE CODE */
    marketplaceAuthor: 'SOLE Dev',

    /* COPYRIGHTS BRAND NAME */
    copyrightsBrand: 'Solerium.io',

    /* TEXT IDENTIFIER FOR THE NETWORK WHERE THE APP IS UP AND RUNNING, YOU CAN WRITE WHATEVER YOU WANT */
    activeNetworkName: 'NEON EVM',

    /* COPYRIGHTS LINK - REPLACE IT IF YOU HAVE A LICENSE KEY OR A PURCHASE CODE */
    copyrightsLink: 'https://solerium.io',

    /* THE NETWORK RPC URL WHERE YOUR CONTRACTS ARE DEPOLYED ON, CHECK THE EXACT RPC URL FROM HERE https://chainlist.org */
     rpcUrl: 'https://neon-proxy-mainnet.solana.p2p.org',
    //rpcUrl: 'https://alfajores-forno.celo-testnet.org',

    /* THE CHAIN ID of NETWORK WHERE YOUR CONTRACTS ARE DEPOLYED ON, GET IT FROM HERE https://chainlist.org */
     networkId: 245022934,
    //networkId: 44787,

    /* THE BLOCK SCAN EXPLORER WHRE YOU CAN TRACK THE TRANSACTIONS */
    blockExplorerUrls: 'https://neonscan.org/',

    /* THE  CURRENCY THAT YOUR APP WILL USE FOR TRANSACTIONS */
    currency: 'SOLE',

    /* THE NATIVE CURRENCY OF THE CHAIN THAT THE APP IS RUNNING ON */
    nativeCurrency: 'NEON',

    /* IPFS PROJECT ID */
    IPFSProjectID: '2MdiAyEWVLi23yercgf7PPnYWRM',

    /* IPFS PROJECT SECRET */
    IPFSProjectSecret: '0cb9aa4f8c64700f3257e2fd9f047162',

    /* IPFS PROJECT ID */
    IPFSGateway: 'difi',

    /* ENDPOINT FOR ASK FOR A FEATURE FORM */
    requestFeatureFormId: 'moqzjqva',

    /* ENDPOINT FOR NEWSLETTER FORM */
    newsletterFormId: 'moqzjqva',

    /* WALLET CONNECT PROJECT ID */
    wcProjectId: 'ff48c6e479fc5a2c0e81af527dd90fa4',

    /* ADSEND AD CLIENT */
    adsenseAdClient: 'ca-pub-2678760727259403',

    /* ADSEND AD SLOT */
    adsenseAdSlot: '3037896495',

    /* COINGECKO TOKEN ID */
    tokenId: 'sole',

    /* CATEGORY LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    categoryOptions: [
        {
            label: 'Utility',
            translationKey: 'utility',
            value: 'Utility',
            icon: <FaTools size='1.5rem' />,
        },
        {
            label: 'Education Materials',
            translationKey: 'education',
            value: 'Education',
            icon: <FaSchool size='1.5rem' />,
        },
        {
            label: 'eBooks',
            translationKey: 'ebooks',
            value: 'eBooks',
            icon: <FaBook size='1.5rem' />,
        },
        {
            label: 'Graphic design',
            translationKey: 'gDesign',
            value: 'Graphic design',
            icon: <FaImages size='1.5rem' />,
        },
        {
            label: 'Operating systems',
            translationKey: 'os',
            value: 'Operating systems',
            icon: <FaDesktop size='1.5rem' />,
        },
        {
            label: 'Programming',
            translationKey: 'programming',
            value: 'Programming',
            icon: <FaCode size='1.5rem' />,
        },
        {
            label: 'Antivirus and security',
            translationKey: 'antiVirus',
            value: 'Antivirus and security',
            icon: <FaShieldVirus size='1.5rem' />,
        },
        {
            label: 'Database',
            translationKey: 'db',
            value: 'Database',
            icon: <FaDatabase size='1.5rem' />,
        },
        {
            label: 'Multimedia',
            translationKey: 'mutimedia',
            value: 'Multimedia',
            icon: <FaVideo size='1.5rem' />,
        },
        {
            label: 'Gaming',
            translationKey: 'gaming',
            value: 'Gaming',
            icon: <FaGamepad size='1.5rem' />,
        },
        {
            label: 'Open Source',
            translationKey: 'openSource',
            value: 'Open Source',
            icon: <DiOpensource size='1.5rem' />,
        },
        {
            label: 'Adults 18+',
            translationKey: 'adult',
            value: 'Adults 18+',
            icon: <FaFileVideo size='1.5rem' />,
        },
    ],

    /* COMPATIBILITY LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    compatibilityOptions: [
        { label: 'Window', value: 'Windows', icon: <DiWindows size='1.5rem' /> },
        { label: 'macOS', value: 'macOS', icon: <DiApple size='1.5rem' /> },
        { label: 'Linux', value: 'Linux', icon: <DiLinux size='1.5rem' /> },
        { label: 'Android', value: 'Android', icon: <DiAndroid size='1.5rem' /> },
        { label: 'IOS', value: 'IOS', icon: <DiApple size='1.5rem' /> },
    ],
};
