import React from 'react';
// import ReadOnlyRating from './ReadOnlyRating';
import { Link } from 'react-router-dom';
import { HiShoppingBag } from 'react-icons/hi';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useApp from '../../hooks/useApp';
import { AvgRating } from './RatingDisplay';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import useWeb3 from '../../hooks/useWeb3';
import useFiles from '../../hooks/useFiles';
import ConnectWalletHander from './ConnectWalletHandler';

function FilePreview() {
    const { t } = useTranslation();
    const { owner } = useFiles();
    const { themeMode, fileViewSrc, setFileView } = useApp();
    const { account } = useWeb3();

    return (
        <>
            <div className='w-100'>
                <div className='row align-items-center g-5'>
                    <div className='col-xl-6'>
                        <ul className='list-inline' data-aos='fade-right'>
                            <li className='list-inline-item'>
                                <Link
                                    className='badge bg-primary fw-normal rounded-0 text-gray-100'
                                    to={`/files/category/${fileViewSrc?.category}`}
                                >
                                    {fileViewSrc?.category}
                                </Link>
                            </li>
                        </ul>
                        <h2 className='h1 text-shadow' data-aos='fade-right' data-aos-delay='100'>
                            {fileViewSrc?.name}
                        </h2>

                        <div className='mb-4'>
                            <AvgRating reviews={fileViewSrc?.reviews} />
                        </div>

                        <p className={`${themeMode === 'dark' ? 'text-gray-500' : 'text-dark'} fw-light mb-4`}>
                            {fileViewSrc?.description}
                        </p>
                        <ul className='list-inline mb-4'>
                            <li className='list-inline-item me-4'>
                                <p className='mb-0 text-muted h6 text-sm text-uppercase'>{t('productPrice')}</p>
                                <p className='h1 mb-0'>
                                    {fileViewSrc?.price} <span className='text-sm fw-normal text-primary'>USD</span>
                                </p>
                                <div className='text-xxs badge fw-normal bg-secondary'>
                                    <img src='/token.png' alt='SOLE' width='20' /> {fileViewSrc?.tokenPrice} SOLE
                                </div>
                            </li>
                        </ul>
                        {account ? (
                            <>
                                <div className='mb-2'>
                                    <div className='d-inline-block' onClick={() => setFileView(false)}>
                                        <Link className='btn btn-primary px-4' to={`/files/${fileViewSrc?.id}`}>
                                            {fileViewSrc?.buyers?.includes(account) ||
                                            fileViewSrc?.buyers?.includes(owner) ? (
                                                <>
                                                    <FaCloudDownloadAlt className='mb-1 me-2' size={20} />
                                                    {t('downloadNow')}
                                                </>
                                            ) : (
                                                <>
                                                    <HiShoppingBag className='mb-1 me-2' size={20} />
                                                    {t('purchase')}
                                                </>
                                            )}
                                        </Link>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <ConnectWalletHander />
                        )}
                    </div>
                    <div className='col-lg-6'>
                        <div className='hero-gallery' data-aos='fade-left'>
                            <Swiper spaceBetween={0} slidesPerView={1} modules={[Navigation, Thumbs]} navigation>
                                {fileViewSrc?.gallery?.map((img, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div
                                                className='product-gallery-slide cursor-pointer'
                                                key={index}
                                                style={{ background: `url(${img})` }}
                                            ></div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilePreview;
