import React, { useReducer } from 'react';

import AppContext from './app-context';

const defaultAppState = {
    themeMode: localStorage.getItem('digital_files_theme') || 'light',
    fileView: false,
    fileViewSrc: {},
};

const appReducer = (state, action) => {
    if (action.type === 'SWITCHMODE') {
        return {
            ...state,
            themeMode: action.themeMode,
        };
    }
    if (action.type === 'SET_FILE_VIEW') {
        return {
            ...state,
            fileView: action.status,
        };
    }
    if (action.type === 'SET_FILE_VIEW_src') {
        return {
            ...state,
            fileViewSrc: action.src,
        };
    }
    return defaultAppState;
};

const AppProvider = (props) => {
    const [appState, dispatchAppAction] = useReducer(appReducer, defaultAppState);

    const switchModeHandler = (themeMode) => {
        dispatchAppAction({ type: 'SWITCHMODE', themeMode: themeMode });
        localStorage.setItem('digital_files_theme', themeMode);
        return themeMode;
    };

    const setFileViewHandler = (status) => {
        dispatchAppAction({ type: 'SET_FILE_VIEW', status: status });
    };

    const setFileViewSrcHandler = (src) => {
        dispatchAppAction({ type: 'SET_FILE_VIEW_src', src: src });
    };

    const appContext = {
        themeMode: appState.themeMode,
        fileView: appState.fileView,
        fileViewSrc: appState.fileViewSrc,
        switchMode: switchModeHandler,
        setFileView: setFileViewHandler,
        setFileViewSrc: setFileViewSrcHandler,
    };

    return <AppContext.Provider value={appContext}>{props.children}</AppContext.Provider>;
};

export default AppProvider;
