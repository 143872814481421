import React from 'react';
import { appSettings } from '../../helpers/settings';
import useApp from '../../hooks/useApp';
import ConnectWalletHander from './ConnectWalletHandler';
import { switchNetwork } from '@wagmi/core';
import useWeb3 from '../../hooks/useWeb3';
import { useTranslation } from 'react-i18next';

function ViewOnlyAlert() {
    const { t } = useTranslation();
    const { themeMode } = useApp();
    const { account } = useWeb3();

    /* --------------------------------------------- 
          CHAHNGE NETWORK HANDLER
    --------------------------------------------- */
    async function changChain() {
        // eslint-disable-next-line no-unused-vars
        const network = await switchNetwork({
            chainId: appSettings.networkId,
        });
    }

    return (
        <div className='d-none d-sm-block'>
            <div className='viewonly-mode'>
                <div className='px-4 text-end'>
                    <div className='d-inline-block'>
                        <div
                            className={`card ${themeMode === 'light' ? 'bg-white shadow-lg' : 'bg-gray-850'} mb-0`}
                            style={{
                                position: 'relative',
                                zIndex: '999',
                            }}
                        >
                            <div className={`card-body text-center`}>
                                <h2 className='h6 mb-0 ms-2'>
                                    {t('appRunOn')}{' '}
                                    <span className='text-orange orange text-backline'>
                                        {appSettings.activeNetworkName}
                                    </span>
                                </h2>
                                <p className='text-muted text-sm'>{t('switchNetwork')}</p>
                                {account ? (
                                    <button className='btn btn-primary switch-btn' onClick={changChain}>
                                        <img src='/wcbrand.svg' alt='wc' className='me-2' width='20' />
                                        {t('switchNetwork')}
                                    </button>
                                ) : (
                                    <ConnectWalletHander />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewOnlyAlert;
