// src/pages/Layout.js

import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';
import useFiles from '../hooks/useFiles';
import useWeb3 from '../hooks/useWeb3';
import useStream from '../hooks/useStream';
import ScrollToTop from '../components/general/ScrollToTop';
import { appSettings } from '../helpers/settings';
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-range-slider-input/dist/style.css';
import { useTranslation } from 'react-i18next';

import FilesAbi from '../contracts/Collectible.json';
import StreamAbi from '../contracts/StreamList.json';
import TokenAbi from '../integration/TokenAbi.json';

import AOS from 'aos';

import MetaMaskLoader from '../components/general/MetaMaskLoader';
import PreviewModal from '../components/general/PreviewModal';
import ViewOnlyAlert from '../components/general/ViewOnlyAlert';
import Modal from '../components/general/Modal';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig, useNetwork } from 'wagmi';
// Rimuovi l'importazione di 'Chain' poich� non necessaria in JavaScript
// import { Chain } from 'wagmi/chains';
import { getAccount, watchAccount, getContract } from '@wagmi/core';
import useApp from '../hooks/useApp';
import Popup from '../components/general/Popup';
import FilePreview from '../components/general/FilePreview';
import MobileNav from '../components/general/MobileNav';

// Definizione della NEON EVM chain senza TypeScript
export const neon = {
  id: 245022934,
  name: 'NEON EVM',
  network: 'NEON',
  nativeCurrency: {
    decimals: 18,
    name: 'NEON',
    symbol: 'NEON',
  },
  rpcUrls: {
    public: { http: ['https://neon-proxy-mainnet.solana.p2p.org'] },
    default: { http: ['https://neon-proxy-mainnet.solana.p2p.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'NeonScan', url: 'https://neonscan.org' },
    default: { name: 'NeonScan', url: 'https://neonscan.org' },
  },
  contracts: {},
};

const chains = [neon];
const projectId = appSettings.wcProjectId;

const { provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider,
  webSocketProvider,
});

const ethereumClient = new EthereumClient(wagmiClient, chains);

function Layout() {
  const { account, loadAccount, setUsername } = useWeb3();
  const {
    transactionLoading,
    previewModal,
    ratingModalState,
    lang,
    loadContract,
    loadFiles,
    loadAppOwner,
    loadActivities,
    loadAppProfits,
    getContractAbi,
    loadPromotionPrice,
    loadPaymentTokenPriceInUSD,
    loadFileBuyersWithBuyers,
  } = useFiles();
  const { fileView, setFileView } = useApp();
  const {
    streamContract,
    loadStreamContract,
    getStreamContractAbi,
    loadUserWatchlist,
    loadUserFiles,
    loadPaymentTokenAddress,
    loadPaymentTokenContract,
    paymentToken,
  } = useStream();
  const { chain } = useNetwork();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem('moviex_username')) {
      setUsername(
        uniqueNamesGenerator({
          dictionaries: [starWars],
          seed: account,
        }).replace('_', ' ')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (streamContract && account) {
      loadAppProfits(streamContract, account, StreamAbi.abi);
      loadUserWatchlist(streamContract, account);
      loadUserFiles(streamContract, account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamContract, account]);

  /*** -------------------------------------------- */
  //      GET PAYMENT TOKEN ADDRESS
  /*** -------------------------------------------- */
  useEffect(() => {
    if (paymentToken) {
      loadPaymentTokenContract(paymentToken, TokenAbi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentToken]);

  /*** -------------------------------------------- */
  //      GET PAYMENT TOKEN CONTRACT
  /*** -------------------------------------------- */
  useEffect(() => {
    if (streamContract) {
      loadPaymentTokenAddress(streamContract);
      loadPromotionPrice(streamContract, StreamAbi.abi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamContract]);

  /* -------------------------------------------------- */
  //      AOS ANIMATION
  /* -------------------------------------------------- */
  useEffect(() => {
    AOS.init({
      duration: 700,
      disable: 'mobile',
      once: true,
    });
  }, []);

  /* --------------------------------------------- 
        GET TOKEN PRICE IN USD
  --------------------------------------------- */
  useEffect(() => {
    loadPaymentTokenPriceInUSD(appSettings?.tokenId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSettings?.tokenId]);

  useEffect(() => {
    /*** -------------------------------------------------- */
    //      GET BLOCKCHAIN DATA
    /*** -------------------------------------------------- */
    const calclateInitialSettings = async () => {
      getContractAbi(FilesAbi.abi);
      getStreamContractAbi(StreamAbi.abi);

      const acc = getAccount();
      loadAccount(acc?.address);

      // Load Contracts
      const fileDeployedNetwork = FilesAbi.networks[appSettings.networkId];
      const streamDeployedNetwork = StreamAbi.networks[appSettings.networkId];

      const filesContract =
        fileDeployedNetwork &&
        getContract({
          address: fileDeployedNetwork.address,
          abi: FilesAbi.abi,
        });

      const streamContract =
        streamDeployedNetwork &&
        getContract({
          address: streamDeployedNetwork.address,
          abi: StreamAbi.abi,
        });

      loadContract(filesContract);
      loadStreamContract(streamContract);

      loadFiles(filesContract, FilesAbi?.abi);
      loadFileBuyersWithBuyers(filesContract, FilesAbi?.abi);
      loadAppOwner(filesContract, FilesAbi?.abi);
      loadActivities(filesContract, FilesAbi?.abi);

      // eslint-disable-next-line no-unused-vars
      const unwatch = watchAccount((account) => {
        loadAccount(account?.address);
        setUsername(
          uniqueNamesGenerator({
            dictionaries: [starWars],
          }).replace('_', ' ')
        );
      });
    };

    calclateInitialSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* --------------------------------------------- 
        CHANGE APP LANGUAGE
  --------------------------------------------- */
  useEffect(() => {
    i18n.changeLanguage(lang?.code);
  }, [lang, i18n]);

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <div className='app'>
          <Header />
          <ScrollToTop />
          {<Outlet />}
          <Footer />
          <MobileNav />
          {chain?.id !== appSettings.networkId && <ViewOnlyAlert />}
        </div>
        {ratingModalState && <Modal />}
        <ToastContainer position='top-center' autoClose={1500} />
        {previewModal && <PreviewModal />}
        {transactionLoading && <MetaMaskLoader />}
        {fileView && (
          <Popup closeModal={() => setFileView(false)}>
            <FilePreview />
          </Popup>
        )}
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          '--w3m-z-index': '9999',
          '--w3m-container-background-color': 'rgba(0,0,0,.7)',
        }}
      />
    </>
  );
}

export default Layout;
