import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useStream from '../../hooks/useStream';
import useWeb3 from '../../hooks/useWeb3';
import useFiles from '../../hooks/useFiles';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';

function WatchListAction({ id, creator }) {
    const { contract, filesContractAbi, loadFiles, setTransactionLoading } = useFiles();
    const { streamContract, streamAbi, watchList, loadUserWatchlist } = useStream();
    const { account } = useWeb3();
    const location = useLocation();
    const { t } = useTranslation();

    /* --------------------------------------------- 
              ADD TO WISHLIST FUNCTION
     --------------------------------------------- */
    const { write: web3AddToWithList } = useContractWrite({
        address: streamContract?.address,
        abi: streamAbi,
        functionName: 'addToWishList',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadFiles(contract, filesContractAbi);
                loadUserWatchlist(streamContract, account, streamAbi);
                toast.success(t('addedToWatchlist'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    /* -------------------------------------------------- */
    //      ADD VIDEO TO WATCHLIST HANDLER
    /* -------------------------------------------------- */
    function addToWatchList() {
        web3AddToWithList({
            recklesslySetUnpreparedArgs: [Number(id)],
        });
    }

    return creator !== account ? (
        <>
            {watchList.filter((video) => video.id === Number(id)).length === 0 ? (
                <button type='button' className='btn btn-outline-light flex-fill' onClick={addToWatchList}>
                    <AiOutlineAppstoreAdd className='mb-1 me-2' size='1.35rem' />
                    {t('addToList')}
                </button>
            ) : (
                <Link
                    className={`btn ${
                        location?.pathname.includes('watchlist')
                            ? 'btn-opac-light cursor-default text-white'
                            : 'btn-light text-dark'
                    }   flex-fill`}
                    to={'/watchlist'}
                >
                    <AiOutlineAppstoreAdd className='mb-1 me-2' size='1.35rem' />
                    {t('inList')}
                </Link>
            )}
        </>
    ) : (
        <button type='button' className='btn btn-opac-light text-white flex-fill cursor-default'>
            <AiOutlineAppstoreAdd className='mb-1 me-2' size='1.35rem' />
            {t('oneOfYourItem')}
        </button>
    );
}

export default WatchListAction;
