import React from 'react';

const StreamContext = React.createContext({
    streamContract: null,
    streamAbi: null,
    watchList: null,
    paymentTokenContract: null,
    paymentToken: null,
    tokenInUSD: 0,
    userFiles: [],
    loadStreamContract: () => {},
    getStreamContractAbi: () => {},
    loadUserWatchlist: () => {},
    loadUserFiles: () => {},
    loadPaymentTokenContract: () => {},
    loadPaymentTokenAddress: () => {},
    loadPaymentTokenPriceInUSD: () => {},
});

export default StreamContext;
