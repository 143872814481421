import React from 'react';
import { Link } from 'react-router-dom';
import UserApprovedFilesTable from './UserApprovedFilesTable';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import UserPendingFilesTable from './UserPendingFilesTable';
import UserPurchasedFiles from './UserPurchasedFiles';
import useFiles from '../../hooks/useFiles';
import PageBanner from '../../components/general/PageBanner';
import useWeb3 from '../../hooks/useWeb3';
import useStream from '../../hooks/useStream';
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';
import { appSettings } from '../../helpers/settings.js';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';

function UserPage() {
    const { account } = useWeb3();
    const { streamContract, streamAbi } = useStream();
    const { setTransactionLoading, appProfits, loadAppProfits } = useFiles();
    const { t } = useTranslation();

    /* --------------------------------------------- 
              CLAIM PROFITS FUNCTION
     --------------------------------------------- */
    const { write: web3ClaimProfits } = useContractWrite({
        address: streamContract?.address,
        abi: streamAbi,
        functionName: 'claimFunds',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAppProfits(streamContract, account, streamAbi);
                toast.success(t('claimedProfits'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    /*** ------------------------------------------------ */
    //      CLAIM PROFITS TO WALLET
    /*** ------------------------------------------------ */
    function claimProfitsHandler() {
        web3ClaimProfits();
    }

    return (
        <>
            <PageBanner heading={t('myAccountHeading')}>
                {account ? (
                    <Link className='btn btn-primary' to='/mint'>
                        {t('sellNewProduct')}
                    </Link>
                ) : (
                    <ConnectWalletHander customClass='my-3' />
                )}
            </PageBanner>
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='card shadow-lg mb-5' data-aos='fade-up' data-aos-delay='100'>
                        <div className='card-body p-lg-5'>
                            <div className='d-flex a;ign-items-center mb-5'>
                                <div className='stats-icon solid-turquoise'>
                                    <RiMoneyDollarCircleFill size='1.4rem' />
                                </div>
                                <div className='ms-3'>
                                    <h2 className='mb-0 h4'>{t('salesProfits')}</h2>
                                    <p className='text-muted fw-normal mb-0'></p>
                                </div>
                            </div>

                            {appProfits === '0' ? (
                                <p className='lead'>{t('noProfitsYet')}!</p>
                            ) : (
                                <p className='text-xl mb-0'>
                                    {appProfits} {appSettings.currency}
                                </p>
                            )}

                            {Number(appProfits) > 0 && (
                                <div className='mt-3'>
                                    <button className='btn btn-primary' onClick={claimProfitsHandler}>
                                        {t('claimProfits')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <UserApprovedFilesTable />
                    </div>
                    <div className='mb-5'>
                        <UserPendingFilesTable />
                    </div>
                    <div className='mb-5'>
                        <UserPurchasedFiles />
                    </div>
                </div>
            </section>
        </>
    );
}

export default UserPage;
