import React from 'react';
import { appSettings } from '../../helpers/settings';
import useApp from '../../hooks/useApp';
import { HiShieldCheck } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { FiYoutube } from 'react-icons/fi';
import { FaTelegram, FaTiktok, FaTwitter  } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { themeMode } = useApp();
    const { t } = useTranslation();

    return (
        <footer className='main-footer pt-5'>
            <div className='container pt-5 pb-4'>
                <div className='row gy-4 gx-5 mb-4'>
                    <div className='col-xxl-3 col-lg-6'>
                        <img
                            src={`${themeMode === 'dark' ? appSettings?.logo : appSettings?.logoLight}`}
                            alt={`${appSettings?.brandName}`}
                            width={appSettings.logoWidth}
                            className='img-fluid mb-3'
                        />
                        <ul className='list-unstyled p-0 text-sm'>
                            <li className='d-flex mb-2'>
                                <HiShieldCheck className='text-primary me-2' size={20} />
                                <a href='https://solerium.io' className='text-reset'>
                                    https://solerium.io
                                </a>
                            </li>
							</ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('sitemap')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/'>
                                    {t('home')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/discover'>
                                    {t('Discover')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/about'>
                                    {t('aboutUs')}
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset text-sm' to='/features-requests'>
                                    {t('contactUs')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('resources')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://jup.ag/swap/SOL-H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('buy')} SOLE
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://dexscreener.com/solana/H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE {t('charts')}
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://neonpass.live/'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE Bridge
                                </a>
                            </li>							
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://solscan.io/token/H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE @ SolScan
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://neonscan.org/token/0xc901d4d435a5f9b5a4c8b4074624b867d5295034'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE @ NEONScan
                                </a>
                            </li>
							<li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://coinmarketcap.com/currencies/solerium'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE @ CoinmarketCap
                                </a>
                            </li>
							<li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://t.me/sole_t2e_bot'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE Tap2Earn Game
                                </a>
                            </li>	
							<li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://t.me/sole_ai_bot'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE AI
                                </a>
                            </li>	
							<li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://solerium.io/sole-wallet.apk'
                                    className='text-reset text-sm mb-2'
                                >
                                    SOLE Wallet Android
                                </a>
                            </li>	
                        </ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>{t('menulegal')}</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://solerium-1.gitbook.io/solerium'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('whitePaper')}
                                </a>
                            </li>
                            <li>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href='https://www.cyberscope.io/audits/sole'
                                    className='text-reset text-sm mb-2'
                                >
                                    {t('audit')}
                                </a>
                            </li>
							</ul>
                    </div>
                </div>
                <h2 className='h3 text-center mb-1'>{t('weAreSocial')}</h2>
                <ul className='list-inline my-3 text-center'>
                    <li className='list-inline-item'>
                        <a
                            className='social-link bg-hover-primary'
                            href='https://x.com/Solerium_io'
							target='_blank'
							rel='noopener noreferrer'
                        >
                            <FaTwitter size='1.25rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li>
                    <li className='list-inline-item'>
                        <a className='social-link bg-hover-primary' href='https://t.me/solerium_io' target='_blank' rel='noopener noreferrer'>
                            <FaTelegram size='1.25rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li>
					 <li className='list-inline-item'>
                        <a className='social-link bg-hover-primary' target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/@solerium_io'>
                            <FiYoutube size='2rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li> 
					<li className='list-inline-item'>
                        <a className='social-link bg-hover-primary' target='_blank' rel='noopener noreferrer' href='https://www.tiktok.com/@solerium_io'>
                            <FaTiktok size='2rem' style={{ transform: 'translateY(-2px)' }} />
                        </a>
                    </li> 					
                </ul>

                <p className='text-muted mb-0 text-center text-sm'>
                    {t('createdBy')}{' '}
                    <a href={appSettings.copyrightsLink} className='text-primary'>
                        {appSettings.copyrightsBrand}
                    </a>{' '}
                    {new Date().getFullYear()}. &copy; {t('copyRights')}
                </p>
            </div>
        </footer>
    );
}

export default Footer;
