import React from 'react';
import { truncate } from '../../helpers/utils';
import { Jazzicon } from '@ukstv/jazzicon-react';
import useWeb3 from '../../hooks/useWeb3';
import RatingDisplay from '../../components/general/RatingDisplay';
import { useTranslation } from 'react-i18next';

function FileReviews({ reviews }) {
    const { account, username } = useWeb3;
    const { t } = useTranslation();
    return (
        <>
            <section className='pb-5'>
                <div className='container pb-5'>
                    <h2 className='h1 text-xl mb-5'>{t('fileReviews')}</h2>
                    {reviews?.length > 0 ? (
                        <>
                            {reviews?.map((rev, index) => {
                                return (
                                    <div key={index}>
                                        <div className='mb-4'>
                                            <div className='d-flex align-items-center mb-2'>
                                                <div className='avatar avatar-md2'>
                                                    <div style={{ width: '40px', height: '40px' }}>
                                                        <Jazzicon address={rev?.account || ''} />
                                                    </div>
                                                </div>
                                                <div className='ms-3'>
                                                    <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                                        {rev?.account !== account ? rev?.accountGenName : username}
                                                    </h6>
                                                    <p className='text-muted small mb-0'>
                                                        {truncate(rev?.account, 15, '.....')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <RatingDisplay rating={rev?.stars} />
                                            </div>
                                            <h5 className='mb-2'>{rev?.reason}</h5>
                                            <p className='text-muted mb-0'>{rev?.message}</p>
                                        </div>
                                        {index !== reviews?.length - 1 && <hr className='mb-4' />}
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <p>{t('fileHasNoReviews')}</p>
                    )}
                </div>
            </section>
        </>
    );
}

export default FileReviews;
