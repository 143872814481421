import React, { useEffect } from 'react';
import HeroSlider from './HeroSlider';
import RecentlyAdded from './RecentlyAdded';
import FeaturedItems from './FeaturedItems';
import useFiles from '../../hooks/useFiles';
import { appSettings } from '../../helpers/settings';
import BestSellers from './BestSellers';
import TopRated from './TopRated';
import { useMemo } from 'react';
function HomePage() {
    const { files, filesWithBuyers } = useFiles();
    const sliderVids = useMemo(() => {
        return files?.filter((vid) => vid.approved)?.slice(0, 8);
    }, [files]);

    const promotedFiles = useMemo(() => {
        return sliderVids?.filter((vid) => vid?.promoted).slice(0, 8);
    }, [sliderVids]);

    const homeFiles = useMemo(() => {
        if (filesWithBuyers?.length > 0) {
            return filesWithBuyers;
        } else {
            return files;
        }
    }, [filesWithBuyers, files]);

    useEffect(() => {
        document.title = `${appSettings.brandName} | ${appSettings.brandDescription}`;
    }, []);

    return (
        <section>
            <HeroSlider data={promotedFiles?.length > 0 ? promotedFiles : sliderVids} />
            {promotedFiles?.length > 0 && <FeaturedItems files={files} fileBuyers={homeFiles} />}
            <RecentlyAdded files={files} fileBuyers={homeFiles} />
            <BestSellers files={files} fileBuyers={homeFiles} />
            <TopRated files={files} fileBuyers={homeFiles} />
        </section>
    );
}

export default HomePage;
