/* ------------------------------------------------------------------------------------------ 
      ENGLISH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const persianTranslation = {
    recentlyAdded: 'تازه افزوده شده ها',
    topTrending: 'محبوب ترین ها',
    featuredItems: 'فایل های برگزیده ما',
    noCategoryMatch: 'فایلی با این دسته بیانیه همخوانی ندارد',
    noReviewsYet: 'هنوز بررسی ای انجام نشده است',
    accessNow: 'دسترسی فوری',
    downloadNow: 'دانلود فوری',
    accessFileFor: 'دسترسی به این فایل فقط با',
    youHaveAccess: 'شما قبلا به این فایل دسترسی داشته اید',
    viewScreenshots: 'مشاهده عکس های صفحه نمایش',
    promoted: 'تبلیغ شده',
    addToList: 'اضافه کردن به لیست من',
    inList: 'در لیست شما',
    fileReviews: 'بررسی فایل',
    fileGallery: 'گالری فایل',
    pending: 'در انتظار',
    pendingMsg: 'این فایل منتظر تأیید مدیر است',
    from: 'از',
    ratings: 'امتیازات',
    promoteThisFile: 'تبلیغ این فایل',
    promoteFor: 'تبلیغ این فایل به مدت',
    youHaveReview: 'شما قبلا نظر خود را درباره این فایل ثبت کرده اید',
    rateThisFile: 'امتیازدهی به این فایل',
    fileHasNoReviews: 'این فایل هنوز بررسی نشده است',
    discoverAll: 'کشف همه فایل ها',
    filterByCategory: 'فیلتر بر اساس دسته بندی',
    sortByDate: 'مرتب سازی بر اساس تاریخ',
    sortByPrice: 'مرتب سازی بر اساس قیمت',
    loadMore: 'بارگیری بیشتر',
    select: 'انتخاب',
    all: 'همه',
    noMatches: 'هیچ فایلی با این فیلتر مطابقت ندارد',
    newest: 'جدیدترین ابتدا',
    oldest: 'قدیمی ترین ابتدا',
    highPrice: 'قیمت: بالا به پایین',
    lowPrice: 'قیمت: پایین به بالا',
    headerLinks: 'لینک ها',
    headerDiscover: 'کشف',
    headerActivity: 'فعالیت',
    headerFaqs: 'سوالات متداول',
    headerContactUs: 'تماس با ما',
    trackTransaction: 'پیگیری تراکنش ها',
    headerMyAccount: 'حساب کاربری من',
    headerMyWishList: 'لیست علاقه مندی های من',
    headerAdminPanel: 'پنل مدیریت',
    headerAdminOnly: 'تنها برای مدیر',
    weAreSocial: 'ما در شبکه های اجتماعی فعالیت می کنیم',
    createdBy: 'ساخته شده توسط',
    copyRights: 'تمامی حقوق محفوظ است',
    activityHeading: 'فعالیت ها',
    tableActivityHeading: 'آخرین فعالیت ها',
    tableNoRecords: 'هنوز هیچ فعالیتی برای نمایش وجود ندارد',
    tableUser: 'کاربر',
    tableTime: 'زمان',
    tableAction: 'عملیات',
    tableMintFile: 'فایل بارگذاری شده',
    tableApproveMint: 'تأیید فایل',
    tableBuyFile: 'فایل خریداری شده',
    tableWishList: 'اضافه کردن فایل به لیست علاقه مندی ها',
    tableNewSubscriber: 'اشتراک‌گذاری جدید',
    tablePromote: 'فایل به تبلیغات اضافه شده است',
    contactPageHeading: 'با هر مشکلی با ما تماس بگیرید',
    contactPageSubHeading: 'فرم را با نیازهای خود پر کنید و ما در کمتر از ۲۴ ساعت به شما ایمیل می‌کنیم.',
    followUs: 'ما را دنبال کنید تا بروز باشید.',
    yourNameLabel: 'نام شما',
    yourNamePlaceholder: 'نام خود را وارد کنید',
    yourNameErrorMsg: 'لطفاً نام خود را وارد کنید',
    yourEmail: 'ایمیل شما',
    yourEmailPlaceholder: 'آدرس ایمیل خود را وارد کنید',
    yourEmailErrorMsg: 'لطفاً آدرس ایمیل معتبر وارد کنید',
    yourEmailInvalidPattern: 'آدرس ایمیل نامعتبر است',
    yourMessage: 'پیام شما',
    yourMessagePlaceholder: 'پیام شما درباره هر درخواستی',
    yourMessageErrorMsg: 'لطفاً پیام خود را وارد کنید',
    submitRequest: 'درخواست خود را ارسال کنید',
    submitting: 'در حال ارسال...',
    successSubmitHeading: 'با تشکر',
    successSubmitMsg: 'همانطور که ممکن است به شما پاسخ خواهیم داد',
    returnHome: 'بازگشت به خانه',
    utility: 'ابزار',
    title: 'عنوان',
    titleErrorMsg: 'لطفاً عنوان ویدیو خود را وارد کنید',
    titlePlaceHolder: 'عنوان محصول را وارد کنید',
    description: 'توضیحات',
    descriptionErrorMsg: 'لطفاً توضیحات فایل خود را وارد کنید',
    descriptionPlaceholder: 'توضیحی کوتاه درباره فایل خود اضافه کنید',
    category: 'دسته بندی',
    categoryPlaceholder: 'یک دسته بندی انتخاب کنید',
    compatibleWith: 'سازگار با',
    compatibleWithPlaceholder: 'سیستم عامل مورد نظر را انتخاب کنید',
    compatibleWithErrorMsg: 'لطفاً سیستم عاملی را که این نرم افزار با آن سازگار است انتخاب کنید',
    price: 'قیمت',
    pricePlaceholder: 'قیمت مورد نظر خود را وارد کنید به',
    priceErrorMsg: 'لطفاً قیمت فایل خود را وارد کنید',
    uploadFile: 'فایل خود را بارگذاری کنید',
    uploadFileErrorMsg: 'لطفاً فایل خود را بارگذاری کنید',
    uploadFileThumb: 'تصویر بند انگشتی فایل خود را بارگذاری کنید',
    uploadFileThumbErrorMsg: 'لطفاً تصویر بند انگشتی فایل خود را بارگذاری کنید',
    uploadFileScreenshots: 'تصاویر فایل را بارگذاری کنید',
    uploadFileScreenshotsErrorMsg: 'لطفاً تصاویر از فایل خود را بارگذاری کنید',
    wishlistPageHeading: 'لیست علاقه مندی های من',
    myAccountHeading: 'حساب کاربری من',
    sellNewProduct: 'فروش محصول جدید',
    salesProfits: 'سودهای فروش',
    noProfitsYet: 'هنوز سودی نداشته اید',
    claimProfits: 'درخواست دریافت سود',
    accTableFile: 'فایل',
    accTableCreator: 'Creator',
    accTableCreatedAt: 'تاریخ ایجاد',
    filesIHaveUploaded: 'فایل‌هایی که بارگذاری کرده‌ام',
    accNoUploadedFiles: 'هیچ فایلی برای نمایش وجود ندارد',
    accPendingFiles: 'فایل‌های در انتظار من',
    accNoPendingFiles: 'هیچ فایل در انتظاری برای نمایش وجود ندارد',
    accPurchasedFiles: 'فایل‌های خریداری شده من',
    accNoPurchasedFiles: 'هیچ فایل خریداری شده‌ای برای نمایش وجود ندارد',
    adminPanel: 'پنل مدیریت',
    appProfits: 'سودهای برنامه',
    approveSelected: 'تأیید انتخاب شده‌ها',
    blockSelected: 'مسدود کردن انتخاب شده‌ها',
    noFilesToDisplay: 'هیچ فایلی برای نمایش وجود ندارد',
    allPendingFiles: 'همه فایل‌های در انتظار',
    status: 'وضعیت',
    active: 'فعال',
    setPromotionPrice: 'تنظیم قیمت تبلیغاتی',
    currentPriceIs: 'قیمت فعلی',
    promotionPrice: 'قیمت تبلیغاتی',
    promotionPricePlaceholder: 'قیمت تبلیغاتی را وارد کنید',
    promotionPriceErrorMsg: 'لطفاً قیمت تبلیغاتی را وارد کنید',
    setPrice: 'تنظیم قیمت',
    transferContractOwnership: 'انتقال مالکیت قراردادها',
    newOwner: 'مالک جدید',
    newOwnerPlaceholder: 'آدرس مالک جدید را وارد کنید',
    newOwnerErrorMsg: 'آدرس نامعتبر! لطفاً آدرس وارد شده را بررسی کنید',
    transfer: 'انتقال',
    newOwnerSuccessHeading: 'شما دیگر صاحب این قرارداد نیستید',
    newOwnerSuccessText: 'مالکیت اکنون به یک حساب دیگر منتقل شده است و شما دسترسی به این صفحه را ندارید. موفق باشید',
    updatePrice: 'به‌روزرسانی قیمت',
    newPrice: 'قیمت جدید',
    updateProductPrice: 'به‌روزرسانی قیمت محصول',
    equals: 'مساوی',
    txLoadingHeading: 'این معمولاً مدتی طول می‌کشد',
    txLoadingMsg: 'ما در حال پردازش تراکنش شما هستیم، لطفاً صفحه خود را تا پایان پردازش تراکنش بازنگری نکنید',
    txLoadingSmText: 'در حال پردازش تراکنش',
    rating: 'امتیازدهی',
    reason: 'دلیل',
    enterRatingReason: 'لطفاً دلیل امتیازدهی را وارد کنید',
    reasonPleaceholder: 'مثال: کیفیت کد',
    message: 'پیام',
    messagePlaceholder: 'پیام نظر خود را وارد کنید',
    ratingMsgError: 'لطفاً پیام امتیازدهی را وارد کنید',
    submitRating: 'ثبت امتیازدهی',
    close: 'بستن',
    uploadingYourAsset: 'در حال بارگذاری دارایی‌های شما',
    ipfsMsg: 'این ممکن است مدتی طول بکشد تا دارایی‌های شما در IFPS ذخیره شوند...',
    cover: 'کاور',
    avatar: 'آواتار',
    screenshot: 'عکس‌های صفحه',
    appRunOn: 'این برنامه بر روی اجرا می‌شود',
    connectToNetwork: 'لطفاً به این شبکه متصل شوید',
    switchNetwork: 'تغییر شبکه',
    oneOfYourItem: 'یکی از موارد شما',
    err: 'اوه! یک خطا رخ داد',
    youHaveAccessNow: 'عالی! اکنون دسترسی دارید',
    fillTheFields: 'لطفاً فیلدها را پر کنید تا بتوانید ارسال کنید',
    addedToWatchlist: 'عالی! شما این فایل را به لیست تماشای خود اضافه کرده‌اید',
    claimedProfits: 'عالی! شما سودهای خود را درخواست کرده‌اید',
    filePriceUpdated: 'عالی! قیمت فایل شما به‌روز شده است',
    approvedSelectedFiles: 'عالی! شما فایل‌های انتخاب شده را تأیید کرده‌اید',
    blockedSelectedFiles: 'عالی! شما فایل‌های انتخاب شده را مسدود کرده‌اید',
    promotionPriceAdded: 'عالی! قیمت تبلیغاتی تنظیم شده است',
    ownerShipChanged: 'عالی! مالکیت به یک حساب دیگر منتقل شده است',
    fileIsPromoted: 'عالی! فایل شما تبلیغ شده است',
    fileUploaded: 'عالی! شما با موفقیت یک فایل بارگذاری کردید',
    filesTooLarge: 'فایل‌ها بیش از حد بزرگ هستند',
    buy: 'خرید',
    charts: 'نمودارها',
    audit: 'حسابرسی',
    lottery: 'قرعه‌کشی',
    frequAskedQuestions: 'سوالات متداول',
    whoAreWe: 'ما کی هستیم؟',
    whoWeAreAnsw:
        'ما یک تیم توسعه‌دهندگان وب 3 هستیم که در حال پیاده‌سازی آنچه که آینده اینترنت، بلاکچین و غیرمتمرکزسازی خواهد بود. این پروژه به هر کاربر امکان می‌دهد تا به طور امن فایل‌های خود را مستقیماً از رایانه شخصی یا تلفن همراه خود بفروشد و بدون واسطه‌های سوم.',
    maxTotalSupply: 'حداکثر / تامین کلی',
    assetPipeLine: 'and asset pipeline for blockchains.',
    tax: 'مالیات',
    sell: 'فروش',
    holderRewards: 'پاداش دارندگان',
    marketing: 'بازاریابی',
    whitePaper: 'کاغذ سفید',
    trade: 'تجارت',
    official: 'رسمی',
    clickHere: 'اینجا را کلیک کنید',
    difiDetailsQuest: 'جزئیات و لینک‌های SOLE',
    functionsOfDifiMarket: 'عملکردهای بازار SOLE چیست؟',
    onDifiMarketYouCan: 'در بازار SOLE می‌توانید',
    difiMarketBullet1: 'فایل‌ها، برنامه‌ها و هر نوع فایل دیجیتالی خود را بفروشید.',
    difiMarketBullet2: 'خرید فایل‌های کاربران دیگر.',
    difiMarketBullet3: 'پس از خرید، بازخورد بگذارید.',
    difiMarketBullet4: 'درآمد حاصل از فروش SOLE خود را برداشت کنید.',
    whatWalletSupportedQues: 'کدام کیف‌های پشتیبانی می‌شوند؟',
    whatWalletSupportedAnsw:
        'Digital Files از WalletConnect استفاده می‌کند و دسترسی به ده‌ها کیف پول مختلف برای تعامل با فروشگاه را فراهم می‌کند.',
    whatAreCommissionQuest: 'کمیسیون‌ها چیستند؟',
    fees: 'هزینه‌ها',
    onEachSale: 'در هر فروش',
    whatAreCommissionAnsw:
        '1% مالیات برای هر فایل فروخته شده اعمال می‌شود، سیستم از بلاکچین و IPFS استفاده می‌کند و مالیات 1% در هر فروش به ما امکان می‌دهد سیستم را به خوبی اجرا کنیم.',
    uploadLimit: 'محدودیت آپلود؟',
    uploadLimitAnsw1: 'حداکثر محدودیت آپلود برای هر فایل حدود 100 مگابایت است.',
    uploadLimitAnsw2: 'هیچ محدودیتی برای تعداد فایل‌هایی که یک کاربر می‌تواند آپلود و فروش کند وجود ندارد.',
    whatDoIBuyWith: 'با چه چیزی فایل‌ها را خریداری می‌کنم؟',
    whatDoIBuyWithAnsw:
        'فایل‌ها با استفاده از توکن SOLE قابل خریداری هستند، حتی برای مدیریت تراکنش‌ها به چندین NEON نیاز است زیرا این ارز محلی است.',
    canIChangePrice: 'آیا می‌توانم قیمت یک فایل را تغییر دهم؟',
    canIChangePriceAnsw:
        'در حال حاضر امکان تغییر قیمت یک فایل وجود ندارد، در فروش یک فایل با توجه به قیمت SOLE محاسبات را به درستی انجام دهید.',
    moreQuestions: 'سوالات بیشتر؟',
    ifYouCantFindAnsw: 'اگر پاسخی را در اینجا پیدا نمی‌کنید',
    getInTouch: 'با ما تماس بگیرید',
    weWillHelp: 'ما خوشحال خواهیم شد کمک کنیم',
    education: 'مطالب آموزشی',
    eBooks: 'کتاب‌های الکترونیکی',
    gDesign: 'طراحی گرافیکی',
    os: 'سیستم‌عامل',
    programming: 'برنامه‌نویسی',
    antiVirus: 'آنتی ویروس و امنیت',
    db: 'پایگاه‌داده',
    mutimedia: 'چندرسانه‌ای',
    gaming: 'بازی‌ها',
    openSource: 'منبع‌باز',
    adult: 'بزرگسالان 18+',

    // Latest Translations keys --------------
    faqs: 'پرسش‌های متداول',
    contactUs: 'تماس با ما',
    aboutUs: 'درباره ما',
    activity: 'فعالیت‌ها',
    aboutUsHeading: 'درباره ما',
    wordsAboutUsHeading: 'چند کلمه درباره',
    appLauncingTime: 'SOLE در تاریخ 28 آوریل 2024 راه‌اندازی شد',
    aboutWord1:
        'اولین بازار فایل‌های دیجیتالی که بر روی زنجیره بلوک در web3 ایجاد شده، با پشتیبانی از اتریوم.',
    aboutWord2:
        'به راحتی و به‌صورت ایمنترین روش، خلق‌های دیجیتالی خود را خریداری، فروش و تبلیغ کنید. هیچ ثبت‌نامی و هیچ نهاد سومی درگیر نیست. این است فرایندزایی SOLE.',
    creator: 'سازنده',
    topFeatureHeading: 'ویژگی‌های برتر',
    toPFeatureSubheading: '3 ویژگی برتر در بازار SOLE که بی‌نظیر هستند!',
    decentralized: 'متمرکز',
    decentralizedDescription:
        'بازار SOLE 100٪ غیرمتمرکز است، همه چیز در زنجیره بلوک NEON EVM برای ایمنی حداکثری ذخیره می‌شود. کاربران با اتصال کیف پول خود بدون مدیریت خارجی تعامل می‌کنند.',
    hackerFreeHeading: 'بدون اختراق',
    hackerFreeDescription:
        'سیستم هیچ چیز را در سرور وب ذخیره نمی‌کند، هیچ داده‌ای در معرض خطر نیست، کاربران هنگام اتصال به کیف پول خود با آن تعامل می‌کنند و هیچ‌کس نمی‌تواند آن را تغییر دهد و مدیران دسترسی به دارایی‌های کاربران را ندارند.',
    lowFeesHeading: 'هزینه‌های کم',
    lowFeesDescription:
        'احتمالاً کمترین هزینه به صورت جهانی، فقط 1 درصد کمیسیون بر فروش، بدون هزینه‌های پنهان دیگر، کاربران می‌توانند هر زمان که می‌خواهند، بدون انتظار یا تأییدیه، SOLE را از حساب خود برداشت کنند، کاربران فقط در زمان سفارش به SOLE پرداخت می‌کنند.',
    aboutUsQuote: '"با SOLE Market، در واقع، قبلاً در آینده هستیم."',
    token: 'توکن',
    founder: 'موسس',
    digitalFilesMarket: 'بازار فایل‌های دیجیتال',
    subscribeThanks: 'از عضویت شما متشکریم',
    toastError: 'اوه! مشکلی پیش آمد!',
    resources: 'منابع',
    newsletterSignup: 'عضویت در خبرنامه',
    newsletterText: 'عضویت در خبرنامه و بروز باشید',
    newsletterErrMsg: 'لطفاً ایمیل خود را وارد کنید',
    newsletterPlaceholder: 'ایمیل خود را وارد کنید',
    sitemap: 'نقشه سایت',
    bestSellers: 'پرفروش‌ترین‌ها',
    topRated: 'بهترین امتیاز',
    home: 'خانه',
    files: 'فایل‌ها',
    resetFilters: 'تنظیمات پیش‌فرض',
    searchForFiles: 'جستجو برای محصولات',
    noSearchResults: 'هیچ محصولی با جستجوی شما مطابقت ندارد',
    noFilesAtTheMoment: 'در حال حاضر فایلی وجود ندارد.',
    emptyHere: 'اینجا خالی است',
    poromotionEndsAt: 'تبلیغات تا',
    sortByRate: 'مرتب‌سازی بر اساس امتیاز',
    shopBrandDescription: 'فروشگاه فایل‌های دیجیتالی وب۳ غیرمتمرکز',
    uploadFileNav: 'آپلود فایل',
    account: 'حساب',
    wishlist: 'لیست علاقه‌مندی‌ها',
    upload: 'آپلود',
    menulegal: 'قانونی',
    menuprivacy: 'سیاست حفظ حریم خصوصی',
    menutos: 'شرایط خدمات',
    menulegaopinion: 'نظر حقوقی',
    share: 'اشتراک گذاری',
};
