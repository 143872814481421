/* ------------------------------------------------------------------------------------------ 
      SPANISH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const spanishTranslation = {
    // TEXTOS DE LA PÁGINA DE INICIO
    recentlyAdded: 'Recientemente añadido',
    topTrending: 'Tendencias principales',
    featuredItems: 'Nuestros archivos destacados',
    noCategoryMatch: 'No hay archivos que coincidan con esta categoría',
    noReviewsYet: 'Aún no hay reseñas',
    accessNow: 'Acceder ahora',
    downloadNow: 'Descargar ahora',
    accessFileFor: 'Accede a este archivo por solo',
    youHaveAccess: 'Ya tienes acceso a este archivo',
    viewScreenshots: 'Ver capturas de pantalla',
    promoted: 'Promocionado',
    addToList: 'Agregar a mi lista',
    inList: 'En tu lista',
    fileReviews: 'Reseñas del archivo',
    fileGallery: 'Galería de archivos',
    pending: 'Pendiente',
    pendingMsg: 'Este archivo está esperando la aprobación del administrador',
    from: 'De',
    ratings: 'Valoraciones',
    promoteThisFile: 'Promocionar este archivo',
    promoteFor: 'Promocionar por',
    youHaveReview: 'Ya has revisado este archivo',
    rateThisFile: 'Califica este archivo',
    fileHasNoReviews: 'Este archivo aún no tiene reseñas',
    discoverAll: 'Descubrir todos los archivos',
    filterByCategory: 'Filtrar por categoría',
    sortByDate: 'Ordenar por fecha',
    sortByPrice: 'Ordenar por precio',
    loadMore: 'Cargar más',
    select: 'Seleccionar',
    all: 'Todos',
    noMatches: 'No hay archivos que coincidan con este filtro',
    newest: 'Más recientes primero',
    oldest: 'Más antiguos primero',
    highPrice: 'Precio: de mayor a menor',
    lowPrice: 'Precio: de menor a mayor',
    // TEXTOS DEL ENCABEZADO
    headerLinks: 'Enlaces',
    headerDiscover: 'Descubrir',
    headerActivity: 'Actividad',
    headerFaqs: 'Preguntas frecuentes',
    headerContactUs: 'Contáctanos',
    trackTransaction: 'Seguir transacciones',
    headerMyAccount: 'Mi cuenta',
    headerMyWishList: 'Mi lista de deseos',
    headerAdminPanel: 'Panel de administración',
    headerAdminOnly: 'Solo administradores',

    // TEXTOS DEL PIE DE PÁGINA
    weAreSocial: 'Somos sociales',
    createdBy: 'Creado por',
    copyRights: 'Todos los derechos reservados',
    // TEXTOS DE LA PÁGINA DE ACTIVIDAD
    activityHeading: 'Actividades',
    tableActivityHeading: 'Actividades recientes',
    tableNoRecords: 'Aún no hay actividades para mostrar',
    tableUser: 'Usuario',
    tableTime: 'Hora',
    tableAction: 'Acción',
    tableMintFile: 'Archivo subido',
    tableApproveMint: 'Archivo aprobado',
    tableBuyFile: 'Archivo comprado',
    tableWishList: 'Archivo añadido a la lista de deseos',
    tableNewSubscriber: 'Nuevo suscriptor',
    tablePromote: 'El archivo ha sido añadido a la promoción',

    // CONTACT US TEXTS
    contactPageHeading: 'Contáctanos sobre cualquier problema',
    contactPageSubHeading:
        'Completa el formulario con lo que necesitas y nos comunicaremos contigo por correo electrónico en menos de 24 horas',
    followUs: 'Síguenos para mantenerte actualizado.',
    yourNameLabel: 'Tu nombre',
    yourNamePlaceholder: 'Ingresa tu nombre',
    yourNameErrorMsg: 'Por favor ingresa tu nombre',
    yourEmail: 'Tu correo electrónico',
    yourEmailPlaceholder: 'Ingresa tu dirección de correo electrónico',
    yourEmailErrorMsg: 'Por favor ingresa una dirección de correo electrónico válida',
    yourEmailInvalidPattern: 'Dirección de correo electrónico inválida',
    yourMessage: 'Tu mensaje',
    yourMessagePlaceholder: 'Tu mensaje sobre cualquier solicitud',
    yourMessageErrorMsg: 'Por favor ingresa tu mensaje',
    submitRequest: 'Enviar tu solicitud',
    submitting: 'Enviando...',
    successSubmitHeading: 'Gracias',
    successSubmitMsg: 'Nos pondremos en contacto contigo lo antes posible',
    returnHome: 'Volver a la página principal',
    // UPLOAD FILE PAGE TEXTS
    utility: 'Utilidad',
    title: 'Título',
    titleErrorMsg: 'Por favor ingresa el título de tu video',
    titlePlaceHolder: 'Ingresa el título de tu producto',
    description: 'Descripción',
    descriptionErrorMsg: 'Por favor ingresa la descripción de tu archivo',
    descriptionPlaceholder: 'Agrega una breve descripción sobre tu archivo',
    category: 'Categoría',
    categoryPlaceholder: 'Selecciona una categoría',
    compatibleWith: 'Compatible con',
    compatibleWithPlaceholder: 'Selecciona la compatibilidad con el sistema operativo',
    compatibleWithErrorMsg: 'Por favor elige con qué sistemas operativos es compatible este software',
    price: 'Precio',
    pricePlaceholder: 'Ingresa el precio deseado para tu archivo en',
    priceErrorMsg: 'Por favor ingresa el precio de tu archivo',
    uploadFile: 'Sube tu archivo',
    uploadFileErrorMsg: 'Por favor sube tu archivo',
    uploadFileThumb: 'Sube la miniatura de tu archivo',
    uploadFileThumbErrorMsg: 'Por favor sube la miniatura de tu archivo',
    uploadFileScreenshots: 'Sube capturas de pantalla del archivo',
    uploadFileScreenshotsErrorMsg: 'Por favor sube capturas de pantalla de tu archivo',

    wishlistPageHeading: 'Mi lista de deseos',
    myAccountHeading: 'Mi cuenta',
    sellNewProduct: 'Vender nuevo producto',
    salesProfits: 'Ganancias de ventas',
    noProfitsYet: 'Todavía no tienes ninguna ganancia',
    claimProfits: 'Reclamar ganancias',
    accTableFile: 'Archivo',
    accTableCreator: 'Creador',
    accTableCreatedAt: 'Creado en',
    filesIHaveUploaded: 'Archivos que he subido',
    accNoUploadedFiles: 'No hay archivos subidos para mostrar',
    accPendingFiles: 'Mis archivos pendientes',
    accNoPendingFiles: 'No hay archivos pendientes para mostrar',
    accPurchasedFiles: 'Mis archivos comprados',
    accNoPurchasedFiles: 'No hay archivos comprados para mostrar',
    adminPanel: 'Panel de administración',
    appProfits: 'Ganancias de la aplicación',
    approveSelected: 'Aprobar seleccionados',
    blockSelected: 'Bloquear seleccionados',
    noFilesToDisplay: 'No hay archivos para mostrar',
    allPendingFiles: 'Todos los archivos pendientes',
    status: 'Estado',
    active: 'Activo',
    setPromotionPrice: 'Establecer precio de promoción',
    currentPriceIs: 'El precio actual es',
    promotionPrice: 'Precio de promoción',
    promotionPricePlaceholder: 'Ingresa el precio de promoción',
    promotionPriceErrorMsg: 'Por favor agrega el precio de promoción',
    setPrice: 'Establecer precio',
    transferContractOwnership: 'Transferir la propiedad del contrato',
    newOwner: 'Nuevo propietario',
    newOwnerPlaceholder: 'Ingresa la dirección del nuevo propietario',
    newOwnerErrorMsg: '¡Dirección no válida! Por favor verifica la dirección que has ingresado',
    transfer: 'Transferir',
    newOwnerSuccessHeading: 'Ya no eres el propietario de este contrato',
    newOwnerSuccessText: 'La propiedad pasa a otra cuenta, ya no tienes acceso a esta página. ¡Buena suerte!',
    updatePrice: 'Actualizar precio',

    newPrice: 'Nuevo precio',
    updateProductPrice: 'Actualizar precio del producto',
    equals: 'Igual',

    txLoadingHeading: 'Esto suele llevar algo de tiempo',
    txLoadingMsg: 'Estamos procesando tu transacción, por favor no actualices tu navegador',
    txLoadingSmText: 'Procesando transacción',
    rating: 'Calificación',
    reason: 'Motivo',
    enterRatingReason: 'Por favor ingresa el motivo de la calificación',
    reasonPleaceholder: "'ej: Calidad del código'",
    message: 'Mensaje',
    messagePlaceholder: 'Ingresa tu mensaje de reseña',
    ratingMsgError: 'Por favor ingresa el mensaje de calificación',
    submitRating: 'Enviar tu calificación',
    close: 'Cerrar',
    uploadingYourAsset: 'Subiendo tus activos',
    ipfsMsg: 'Esto puede tomar algún tiempo hasta que guardemos tus activos en IPFS...',
    cover: 'Portada',
    avatar: 'Avatar',
    screenshot: 'Captura de pantalla',
    appRunOn: 'Esta aplicación está configurada para ejecutarse en',
    connectToNetwork: 'Por favor conéctate a esta red',
    switchNetwork: 'Cambiar red',
    oneOfYourItem: 'Uno de tus elementos',

    err: '¡Ups! Algo salió mal',
    youHaveAccessNow: '¡Genial! Ahora tienes acceso',
    fillTheFields: 'Por favor completa los campos para poder enviar',
    addedToWatchlist: '¡Genial! Has agregado este archivo a tu lista de seguimiento.',
    claimedProfits: '¡Genial! Has reclamado tus ganancias',
    filePriceUpdated: '¡Genial! El precio de tu archivo ha sido actualizado',
    approvedSelectedFiles: '¡Genial! Has aprobado los archivos seleccionados',
    blockedSelectedFiles: '¡Genial! Has bloqueado los archivos seleccionados',
    promotionPriceAdded: '¡Genial! Se ha establecido el precio de promoción',
    ownerShipChanged: '¡Genial! La propiedad ha sido transferida a otra cuenta',
    fileIsPromoted: '¡Genial! Tu archivo ha sido promocionado',
    fileUploaded: '¡Genial! Has subido exitosamente un archivo',
    filesTooLarge: 'Los archivos son demasiado grandes',

    buy: 'Comprar',
    charts: 'Gráficos',
    audit: 'Auditoría',
    lottery: 'Lotería',

    frequAskedQuestions: 'Preguntas frecuentes',
    whoAreWe: '¿Quiénes somos?',
    whoWeAreAnsw:
        'Somos un equipo de desarrolladores Web3 que están implementando lo que será el futuro de Internet, la cadena de bloques y la descentralización. Este proyecto permite a cada usuario vender de forma segura sus archivos, directamente desde su PC o smartphone, y sin intermediarios.',
    maxTotalSupply: 'Suministro máximo/total',
    assetPipeLine: 'y tubería de activos para cadenas de bloques.',
    tax: 'Impuesto',
    sell: 'Vender',
    holderRewards: 'Recompensas para los titulares',
    marketing: 'Marketing',
    whitePaper: 'Libro blanco',
    trade: 'Comercio',
    official: 'Oficial',
    clickHere: 'Haz clic aquí',
    difiDetailsQuest: 'Detalles y enlaces de SOLE',
    functionsOfDifiMarket: '¿Cuáles son las funciones del mercado SOLE?',
    onDifiMarketYouCan: 'En el mercado SOLE puedes',
    difiMarketBullet1: 'Vender tus archivos, programas y cualquier tipo de archivo digital.',
    difiMarketBullet2: 'Comprar archivos de otros usuarios.',
    difiMarketBullet3: 'Dejar comentarios después de una compra.',
    difiMarketBullet4: 'Retirar tus SOLE de las ventas.',
    whatWalletSupportedQues: '¿Qué carteras son compatibles?',
    whatWalletSupportedAnsw:
        'Digital Files utiliza WalletConnect y admite docenas de carteras diferentes para interactuar con la tienda.',
    whatAreCommissionQuest: '¿Cuáles son las comisiones?',
    fees: 'Tarifas',
    onEachSale: 'en cada venta',
    whatAreCommissionAnsw:
        'Se aplica un impuesto del 1% a cada archivo vendido, el sistema utiliza la cadena de bloques y el IPFS, y el impuesto del 1% en cada venta nos permite mantener el sistema en funcionamiento de manera perfecta.',
    uploadLimit: 'Límites de carga',
    uploadLimitAnsw1: 'El límite de carga máximo para cada archivo es de aproximadamente 100 MB',
    uploadLimitAnsw2: 'No hay límites en la cantidad de archivos que un usuario puede cargar y vender.',
    whatDoIBuyWith: '¿Con qué compro los archivos?',
    whatDoIBuyWithAnsw:
        'Los archivos se pueden comprar utilizando el token SOLE, incluso se necesita un poco de NEON para gestionar las transacciones, ya que es la moneda nativa.',
    canIChangePrice: '¿Puedo cambiar el precio de un archivo?',
    canIChangePriceAnsw:
        'Actualmente no es posible cambiar el precio de un archivo, realiza los cálculos correctamente en función del precio del SOLE cuando vendas un archivo.',
    moreQuestions: '¿Más preguntas?',
    ifYouCantFindAnsw: 'Si no puedes encontrar las respuestas aquí',
    getInTouch: 'contáctanos',
    weWillHelp: 'Estaremos encantados de ayudarte',
    education: 'Materiales educativos',
    eBooks: 'eBooks',
    gDesign: 'Diseño gráfico',
    os: 'Sistemas operativos',
    programming: 'Programación',
    antiVirus: 'Antivirus y seguridad',
    db: 'Bases de datos',

    // Latest Translations keys --------------
    faqs: 'Preguntas frecuentes',
    contactUs: 'Contáctanos',
    aboutUs: 'Sobre nosotros',
    activity: 'Actividades',
    aboutUsHeading: 'Sobre nosotros',
    wordsAboutUsHeading: 'Algunas palabras sobre',
    appLauncingTime: 'SOLE lanzado el 28 de abril de 2024',
    aboutWord1:
        'El primer mercado de archivos digitales creado en la cadena de bloques en web3, impulsado por NEON EVM.',
    aboutWord2:
        'Compra, vende y promociona tus creaciones digitales de la manera más fácil y segura en el mercado. Sin registro, sin terceros involucrados. Esto es Descentralización SOLE.',
    creator: 'Creador',
    topFeatureHeading: 'Funciones principales',
    toPFeatureSubheading: '¡Las 3 funciones principales del Mercado SOLE son incomparables!',
    decentralized: 'Descentralizado',
    decentralizedDescription:
        'El mercado SOLE es 100% descentralizado, todo se guarda en la cadena de bloques de NEON EVM para lograr la máxima seguridad. Los usuarios interactúan conectando sus billeteras sin necesidad de gestión externa.',
    hackerFreeHeading: 'Libre de hackers',
    hackerFreeDescription:
        'El sistema no guarda nada en el servidor web, no hay datos en riesgo, los usuarios interactúan con su billetera cuando están conectados, nada se puede manipular y los administradores no tienen acceso a los fondos de los usuarios.',
    lowFeesHeading: 'Bajas comisiones',
    lowFeesDescription:
        'Probablemente las más bajas a nivel mundial, solo un 1% de comisión por ventas, sin otras tarifas ocultas, los usuarios pueden retirar SOLE de su cuenta en cualquier momento, sin esperar ni confirmaciones, los usuarios pagan en SOLE solo al momento de hacer el pedido.',
    aboutUsQuote: '"Con SOLE Market ya estamos en el futuro, ante todo."',
    token: 'Token',
    founder: 'Fundador',
    digitalFilesMarket: 'Mercado de Archivos Digitales',
    subscribeThanks: 'Gracias por suscribirte',
    toastError: '¡Ups! Algo salió mal.',
    resources: 'Recursos',
    newsletterSignup: 'Suscripción al boletín',
    newsletterText: 'Suscríbete al boletín y mantente actualizado',
    newsletterErrMsg: 'Por favor, ingresa tu correo electrónico',
    newsletterPlaceholder: 'Agrega tu correo electrónico',
    sitemap: 'Mapa del sitio',
    bestSellers: 'Mejores ventas',
    topRated: 'Mejor valorados',
    home: 'Inicio',
    files: 'Archivos',
    resetFilters: 'Restablecer filtros',
    searchForFiles: 'Buscar productos',
    noSearchResults: 'No hay productos que coincidan con tu búsqueda',
    noFilesAtTheMoment: 'No hay archivos en este momento.',
    emptyHere: 'Está vacío aquí',
    poromotionEndsAt: 'La promoción termina el',
    sortByRate: 'Ordenar por valoración',
    shopBrandDescription: 'Tienda descentralizada de archivos digitales Web3',
    uploadFileNav: 'Cargar archivo',
    account: 'Cuenta',
    wishlist: 'Lista de deseos',
    upload: 'Cargar',
    menulegal: 'Legal',
    menuprivacy: 'Política de privacidad',
    menutos: 'Términos de servicio',
    menulegaopinion: 'Opinión legal',
    share: 'Compartir',
};
