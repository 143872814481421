/* ------------------------------------------------------------------------------------------ 
      ENGLISH TRANSLATIONS
------------------------------------------------------------------------------------------ */

export const turkeyTranslation = {
    recentlyAdded: 'Son Eklenenler',
    topTrending: 'En Popüler',
    featuredItems: 'Öne Çıkan Dosyalarımız',
    noCategoryMatch: 'Bu kategoriye uyan dosya bulunamadı',
    noReviewsYet: 'Henüz yorum yok',
    accessNow: 'Şimdi Eriş',
    downloadNow: 'Şimdi İndir',
    accessFileFor: 'Bu dosyaya sadece',
    youHaveAccess: 'Bu dosyaya zaten erişiminiz var',
    viewScreenshots: 'Ekran Görüntülerini Görüntüle',
    promoted: 'Tanıtılan',
    addToList: 'Listeme ekle',
    inList: 'Listemde',
    fileReviews: 'Dosya Yorumları',
    fileGallery: 'Dosya Galerisi',
    pending: 'Beklemede',
    pendingMsg: 'Bu dosya admin onayını bekliyor',
    from: 'Kimden',
    ratings: 'Değerlendirmeler',
    promoteThisFile: 'Bu Dosyayı Tanıt',
    promoteFor: 'Bu dosyayı tanıtmak için',
    youHaveReview: 'Bu dosyayı zaten değerlendirdiniz',
    rateThisFile: 'Bu Dosyayı Değerlendir',
    fileHasNoReviews: 'Bu dosyanın henüz yorumları bulunmuyor',
    discoverAll: 'Tüm Dosyaları Keşfet',
    filterByCategory: 'Kategoriye Göre Filtrele',
    sortByDate: 'Tarihe Göre Sırala',
    sortByPrice: 'Fiyata Göre Sırala',
    loadMore: 'Daha Fazla Yükle',
    select: 'Seç',
    all: 'Tümü',
    noMatches: 'Bu filtreye uyan dosya bulunmuyor',
    newest: 'En Yeni',
    oldest: 'En Eski',
    highPrice: 'Fiyat: Yüksekten Düşüğe',
    lowPrice: 'Fiyat: Düşükten Yükseğe',
    headerLinks: 'Bağlantılar',
    headerDiscover: 'Keşfet',
    headerActivity: 'Aktivite',
    headerFaqs: 'SSS',
    headerContactUs: 'Bize Ulaşın',
    trackTransaction: 'İşlemleri Takip Et',
    headerMyAccount: 'Hesabım',
    headerMyWishList: 'İstek Listem',
    headerAdminPanel: 'Yönetici Paneli',
    headerAdminOnly: 'Sadece Yöneticiler',
    weAreSocial: 'Sosyal Medyada Bizi Takip Edin',
    createdBy: 'Oluşturan',
    copyRights: 'Tüm hakları saklıdır',
    activityHeading: 'Etkinlikler',
    tableActivityHeading: 'En Son Etkinlikler',
    tableNoRecords: 'Henüz görüntülenecek etkinlik bulunmamaktadır',
    tableUser: 'Kullanıcı',
    tableTime: 'Zaman',
    tableAction: 'Eylem',
    tableMintFile: 'Yüklenen Dosya',
    tableApproveMint: 'Onaylanan Dosya',
    tableBuyFile: 'Satın Alınan Dosya',
    tableWishList: 'İzleme Listesine Eklenen Dosya',
    tableNewSubscriber: 'Yeni Abone',
    tablePromote: 'Dosya tanıtıma eklendi',
    contactPageHeading: 'Herhangi bir konuda bize ulaşın',
    contactPageSubHeading: 'İhtiyacınızı belirten formu doldurun ve 24 saat içinde size e-posta ile geri dönelim',
    followUs: 'Bizi takip edin, güncel kalın.',
    yourNameLabel: 'Adınız',
    yourNamePlaceholder: 'Adınızı girin',
    yourNameErrorMsg: 'Lütfen adınızı girin',
    yourEmail: 'E-posta Adresiniz',
    yourEmailPlaceholder: 'E-posta adresinizi girin',
    yourEmailErrorMsg: 'Lütfen geçerli bir e-posta adresi girin',
    yourEmailInvalidPattern: 'Geçersiz e-posta adresi',
    yourMessage: 'Mesajınız',
    yourMessagePlaceholder: 'Herhangi bir talebiniz hakkında mesajınız',
    yourMessageErrorMsg: 'Lütfen mesajınızı girin',
    submitRequest: 'Talebinizi Gönderin',
    submitting: 'Gönderiliyor...',
    successSubmitHeading: 'Teşekkürler',
    successSubmitMsg: 'En kısa sürede size geri döneceğiz',
    returnHome: 'Ana Sayfaya Dön',
    utility: 'Faydalı',
    title: 'Başlık',
    titleErrorMsg: 'Lütfen video başlığını girin',
    titlePlaceHolder: 'Ürün başlığınızı girin',
    description: 'Açıklama',
    descriptionErrorMsg: 'Lütfen dosya açıklamanızı girin',
    descriptionPlaceholder: 'Dosyanız hakkında kısa bir açıklama ekleyin',
    category: 'Kategori',
    categoryPlaceholder: 'Bir kategori seçin',
    compatibleWith: 'Uyumlu',
    compatibleWithPlaceholder: 'Bir işletim sistemi uyumluluğu seçin',
    compatibleWithErrorMsg: 'Lütfen bu yazılımın hangi işletim sistemiyle uyumlu olduğunu seçin',
    price: 'Fiyat',
    pricePlaceholder: 'Dosya için istediğiniz fiyatı girin',
    priceErrorMsg: 'Lütfen dosya fiyatınızı girin',
    uploadFile: 'Dosyanızı yükleyin',
    uploadFileErrorMsg: 'Lütfen dosyanızı yükleyin',
    uploadFileThumb: 'Dosya küçük resmini yükleyin',
    uploadFileThumbErrorMsg: 'Lütfen dosya küçük resmini yükleyin',
    uploadFileScreenshots: 'Dosya Ekran Görüntülerini Yükleyin',
    uploadFileScreenshotsErrorMsg: 'Lütfen dosya ekran görüntüsünü yükleyin',
    wishlistPageHeading: 'İstek Listem',
    myAccountHeading: 'Hesabım',
    sellNewProduct: 'Yeni Ürün Sat',
    salesProfits: 'Satış Karları',
    noProfitsYet: 'Henüz herhangi bir karınız yok',
    claimProfits: 'Karları Talep Et',
    accTableFile: 'Dosya',
    accTableCreator: 'Oluşturan',
    accTableCreatedAt: 'Oluşturma Tarihi',
    filesIHaveUploaded: 'Yüklediğim Dosyalar',
    accNoUploadedFiles: 'Görüntülenecek yüklenmiş dosya yok',
    accPendingFiles: 'Bekleyen Dosyalarım',
    accNoPendingFiles: 'Görüntülenecek bekleyen dosya yok',
    accPurchasedFiles: 'Satın Aldığım Dosyalar',
    accNoPurchasedFiles: 'Görüntülenecek satın alınmış dosya yok',
    adminPanel: 'Yönetici Paneli',
    appProfits: 'Uygulama Karları',
    approveSelected: 'Seçilenleri Onayla',
    blockSelected: 'Seçilenleri Engelle',
    noFilesToDisplay: 'Görüntülenecek dosya yok',
    allPendingFiles: 'Tüm Bekleyen Dosyalar',
    status: 'Durum',
    active: 'Aktif',
    setPromotionPrice: 'Promosyon Fiyatını Ayarla',
    currentPriceIs: 'Mevcut fiyat',
    promotionPrice: 'Promosyon Fiyatı',
    promotionPricePlaceholder: 'Promosyon fiyatını girin',
    promotionPriceErrorMsg: 'Lütfen promosyon fiyatı ekleyin',
    setPrice: 'Fiyatı Ayarla',
    transferContractOwnership: 'Sözleşme Sahipliğini Devret',
    newOwner: 'Yeni Sahip',
    newOwnerPlaceholder: 'Yeni sahip adresini girin',
    newOwnerErrorMsg: 'Geçersiz adres! Lütfen girdiğiniz adresi kontrol edin',
    transfer: 'Transfer',
    newOwnerSuccessHeading: 'Artık bu sözleşmenin sahibi değilsiniz',
    newOwnerSuccessText: 'Sahiplik başka bir hesaba geçti, artık bu sayfaya erişiminiz yok. İyi şanslar',
    updatePrice: 'Fiyatı Güncelle',
    newPrice: 'Yeni Fiyat',
    updateProductPrice: 'Ürün Fiyatını Güncelle',
    equals: 'Eşittir',
    txLoadingHeading: 'Bu genellikle biraz zaman alır',
    txLoadingMsg: 'İşleminiz işleniyor, lütfen tarayıcınızı yenilemeyin',
    txLoadingSmText: 'İşlem İşleniyor',
    rating: 'Değerlendirme',
    reason: 'Neden',
    enterRatingReason: 'Lütfen değerlendirme nedenini girin',
    reasonPleaceholder: "'Örn: Kod Kalitesi'",
    message: 'Mesaj',
    messagePlaceholder: 'İnceleme mesajınızı girin',
    ratingMsgError: 'Lütfen değerlendirme mesajını girin',
    submitRating: 'Değerlendirmenizi Gönderin',
    close: 'Kapat',
    uploadingYourAsset: 'Varlıklarınız Yükleniyor',
    ipfsMsg: "Varlıklarınızı IPFS'ye kaydetmek biraz zaman alabilir...",
    cover: 'Kapak',
    avatar: 'Avatar',
    screenshot: 'Ekran Görüntüsü',
    appRunOn: 'Bu uygulama şu ağda çalışacak şekilde ayarlandı',
    connectToNetwork: 'Lütfen bu ağa bağlanın',
    switchNetwork: 'Ağı Değiştir',
    oneOfYourItem: 'Öğelerinizden biri',
    err: 'Oops! Bir hata oluştu',
    youHaveAccessNow: 'Harika! Şimdi erişiminiz var',
    fillTheFields: 'Lütfen gönderebilmek için alanları doldurun',
    addedToWatchlist: 'Harika! Bu dosyayı izleme listesine eklediniz',
    claimedProfits: 'Harika! Karlarınızı talep ettiniz',
    filePriceUpdated: 'Harika! Dosya fiyatınız güncellendi',
    approvedSelectedFiles: 'Harika! Seçilen Dosyaları onayladınız',
    blockedSelectedFiles: 'Harika! Seçilen Dosyaları engellediniz',
    promotionPriceAdded: 'Harika! Promosyon fiyatı ayarlandı',
    ownerShipChanged: 'Harika! Sahiplik başka bir hesaba devredildi',
    fileIsPromoted: 'Harika! Dosyanız tanıtıldı',
    fileUploaded: 'Harika! Başarıyla bir dosya yüklediniz',
    filesTooLarge: 'Dosyalar çok büyük',
    buy: 'Satın Al',
    charts: 'Grafikler',
    audit: 'Denetim',
    lottery: 'Loto',
    frequAskedQuestions: 'Sıkça Sorulan Sorular',
    whoAreWe: 'Biz kimiz?',
    whoWeAreAnsw:
        'Biz, blockchain ve merkezi olmayanın geleceği olacak olanı uygulayan bir Web3 geliştirici ekibiyiz. Bu proje, her kullanıcının dosyalarını güvenli bir şekilde PC veya akıllı telefonlarından doğrudan ve üçüncü taraflar olmadan satmasını sağlar.',
    maxTotalSupply: 'Maksimum/Toplam Arz',
    assetPipeLine: 've blok zincirleri için varlık boru hattı.',
    tax: 'Vergi',
    sell: 'Sat',
    holderRewards: 'Sahip Ödülleri',
    marketing: 'Pazarlama',
    whitePaper: 'Beyaz Kağıt',
    trade: 'Ticaret',
    official: 'Resmi',
    clickHere: 'Buraya tıklayın',
    difiDetailsQuest: 'SOLE Detayları ve Bağlantılar',
    functionsOfDifiMarket: "SOLE Market'ın İşlevleri Nelerdir?",
    onDifiMarketYouCan: 'SOLE pazarında şunları yapabilirsiniz',
    difiMarketBullet1: 'Dosyalarınızı, programlarınızı ve her türlü dijital dosyayı satmak.',
    difiMarketBullet2: 'Diğer kullanıcıların Dosyalarını satın almak.',
    difiMarketBullet3: 'Satın alma işleminden sonra geri bildirim bırakmak.',
    difiMarketBullet4: "Satışların SOLE'sini çekmek.",
    whatWalletSupportedQues: 'Hangi cüzdanlar desteklenmektedir?',
    whatWalletSupportedAnsw:
        "Digital Files, WalletConnect'i kullanır ve Dükkan ile etkileşim için onlarca farklı cüzdanı destekler.",
    whatAreCommissionQuest: 'Komisyonlar nelerdir?',
    fees: 'Ücretler',
    onEachSale: 'her Satışta',
    whatAreCommissionAnsw:
        'Her satışta %1 vergi uygulanır, sistem blockchain ve IPFS kullanır ve her satışta %1 vergi, sistemi mükemmel bir şekilde çalışır durumda tutmamıza olanak sağlar.',
    uploadLimit: 'Yükleme Sınırları?',
    uploadLimitAnsw1: 'Her dosya için maksimum yükleme sınırı yaklaşık 100 MBdır.',
    uploadLimitAnsw2: 'Bir kullanıcının yükleyebileceği ve satabileceği dosya sayısına sınırlama yoktur.',
    whatDoIBuyWith: 'Dosyaları neyle satın alıyorum?',
    whatDoIBuyWithAnsw:
        "Dosyalar SOLE Token kullanılarak satın alınabilir, işlemleri yönetmek için birkaç NEON'ye ihtiyaç duyulur çünkü bunlar yerel para birimidir.",
    canIChangePrice: 'Bir Dosyanın fiyatını değiştirebilir miyim?',
    canIChangePriceAnsw:
        "Şu anda bir dosyanın fiyatını değiştirmek mümkün değildir, bir dosya satarken SOLE'nin fiyatına dayanarak doğru hesaplamalar yapın.",
    moreQuestions: 'Daha fazla soru mu?',
    ifYouCantFindAnsw: 'Eğer burada cevapları bulamazsanız',
    getInTouch: 'iletişime geçin',
    weWillHelp: 'Yardımcı olmaktan mutluluk duyarız',
    education: 'Eğitim Materyalleri',
    eBooks: 'eKitaplar',
    gDesign: 'Grafik tasarım',
    os: 'İşletim sistemleri',
    programming: 'Programlama',
    antiVirus: 'Antivirüs ve güvenlik',
    db: 'Veritabanı',
    mutimedia: 'Multimedya',
    gaming: 'Oyun',
    openSource: 'Açık Kaynak',
    adult: 'Yetişkinler 18+',

    // Latest Translations keys --------------
    faqs: 'SSS',
    contactUs: 'Bize Ulaşın',
    aboutUs: 'Hakkımızda',
    activity: 'Etkinlikler',
    aboutUsHeading: 'Hakkımızda',
    wordsAboutUsHeading: 'Hakkımızda Bazı Kelimeler',
    appLauncingTime: 'SOLE 28 Nisan 2024 tarihinde başlatıldı',
    aboutWord1:
        "Web3'te NEON EVM tarafından desteklenen Blok Zincirinde oluşturulan ilk dijital dosya pazarı.",
    aboutWord2:
        "Piyasadaki en kolay ve en güvenli şekilde dijital yaratılışlarınızı satın alın, satın, ve tanıtın. Kayıt olmadan, üçüncü taraflar dahil edilmeden. Bu, Merkeziyetsizleştirme SOLE'dir.",
    creator: 'Yaratıcı',
    topFeatureHeading: 'En İyi Özellikler',
    toPFeatureSubheading: 'Rakipsiz SOLE Pazarında En İyi 3 Özellik!',
    decentralized: 'Merkeziyetsiz',
    decentralizedDescription:
        'SOLE Piyasası %100 Merkezi Olmayan bir yapıya sahiptir; her şey maksimum güvenlik için NEON EVM Blockchain üzerine kaydedilir. Kullanıcılar, harici yönetim olmaksızın cüzdanlarını bağlayarak etkileşimde bulunurlar.',
    hackerFreeHeading: 'Hackerlardan ÜCRETSİZ',
    hackerFreeDescription:
        'Sistem web sunucusunda hiçbir şey kaydetmez, hiçbir veri risk altında değildir, kullanıcılar cüzdanlarına bağlı olduklarında etkileşimde bulunurlar, hiçbir şey manipüle edilemez ve yöneticiler kullanıcı fonlarına erişimi yoktur.',
    lowFeesHeading: 'Düşük Ücretler',
    lowFeesDescription:
        'Muhtemelen küresel olarak en düşük, sadece satışlarda %1 komisyon, başka gizli ücret yok, kullanıcılar istedikleri zaman hesaplarından SOLE çekebilirler, bekleme veya onay beklemeksizin, kullanıcılar yalnızca sipariş anında SOLE ile ödeme yaparlar.',
    aboutUsQuote: '"SOLE Market ile zaten gelecekteyiz, en önemlisi."',
    token: 'Token',
    founder: 'Kurucu',
    digitalFilesMarket: 'Dijital Dosyalar Pazarı',
    subscribeThanks: 'Aboneliğiniz için teşekkür ederiz',
    toastError: 'Oops! Bir şeyler yanlış gitti!',
    resources: 'Kaynaklar',
    newsletterSignup: 'Bülten Aboneliği',
    newsletterText: 'Bültenimize abone olun ve güncel kalın',
    newsletterErrMsg: 'Lütfen e-posta adresinizi girin',
    newsletterPlaceholder: 'E-postanızı ekleyin',
    sitemap: 'Site Haritası',
    bestSellers: 'En İyi Satıcılar',
    topRated: 'En Yüksek Puan Alanlar',
    home: 'Ana Sayfa',
    files: 'Dosyalar',
    resetFilters: 'Filtreleri Sıfırla',
    searchForFiles: 'Ürün arayın',
    noSearchResults: 'Arama kriterlerinize uygun ürün bulunamadı',
    noFilesAtTheMoment: 'Şu anda dosya bulunmamaktadır.',
    emptyHere: 'Burada boş',
    poromotionEndsAt: 'Promosyon sona erer',
    sortByRate: 'Puana Göre Sırala',
    shopBrandDescription: 'Merkezi Olmayan Web3 Dijital Dosya Mağazası',
    uploadFileNav: 'Dosya Yükle',
    account: 'Hesap',
    wishlist: 'İstek Listesi',
    upload: 'Yükle',
    menulegal: 'Yasal',
    menuprivacy: 'Gizlilik Politikası',
    menutos: 'Kullanım Koşulları',
    menulegaopinion: 'Hukuki Görüş',
    share: 'Paylaşmak',
};
