/* -----------------------------------------------------------------------------------------

    THSI FILE CONTAINS SOME STATIC DATA TO FILL SOME DIFFERENT COMPONENTS

----------------------------------------------------------------------------------------- */

export const languages = [
    { code: 'en', key: 'English' },
    { code: 'fr', key: 'Français' },
    { code: 'it', key: 'Italiano' },
    { code: 'zh', key: '中文' },
    { code: 'ru', key: 'русский' },
    { code: 'es', key: 'Español' },
    { code: 'po', key: 'Polski' },
    { code: 'tk', key: 'Türkçe' },
    { code: 'vi', key: 'Tiếng Việt' },
    { code: 'pr', key: 'فارسی' },
    { code: 'ar', key: 'عربي' },
];
