import React, { useReducer } from 'react';
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import { readContract, getContract } from '@wagmi/core';
import Web3 from 'web3';

import StreamContext from './stream-context';

const defaultStreamState = {
    streamContract: null,
    streamAbi: null,
    watchList: [],
    userFiles: [],
    paymentTokenContract: null,
    paymentToken: null,
    tokenInUSD: 0,
};

const streamReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            streamContract: action.streamContract,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            streamAbi: action.streamAbi,
        };
    }
    if (action.type === 'GETPAYMENTTOKEN') {
        return {
            ...state,
            paymentToken: action.paymentToken,
        };
    }

    if (action.type === 'GETPAYMENTTOKENCONTRACT') {
        return {
            ...state,
            paymentTokenContract: action.paymentTokenContract,
        };
    }
    if (action.type === 'LOAD_WATCHLIST') {
        return {
            ...state,
            watchList: action.watchList?.map((file) => {
                return {
                    id: Number(file[0]),
                    metadata: {
                        title: file[1][0],
                        description: file[1][1],
                        category: file[1][2],
                        type: file[1][3],
                        file: file[1][4],
                        screenshots: file[1][5],
                        compatibility: `${file[1][6]?.map((os, i) =>
                            i < file[1][6]?.length - 1 ? `${os}` : os
                        )}`?.replaceAll(',', ', '),
                        thumbnail: file[1][7],
                    },
                    price: Number(
                        Web3.utils.fromWei(Number(file[2]).toLocaleString('fullwide', { useGrouping: false }), 'ether')
                    ),
                    creator: file[3],
                    userGenName: uniqueNamesGenerator({
                        dictionaries: [starWars],
                        seed: file[3],
                    }).replace('_', ' '),
                    approved: file[5],
                    createdAt: Number(file[6]) * 1000,
                    promoted: file[7],
                    reviews: file[8]?.map((rating) => ({
                        account: rating[0],
                        accountGenName: uniqueNamesGenerator({
                            dictionaries: [starWars],
                            seed: rating[0],
                        }).replace('_', ' '),
                        stars: Number(rating[2]),
                        message: rating[1],
                        reason: rating[3],
                    })),
                };
            }),
        };
    }
    if (action.type === 'GET_USER_FILES') {
        return {
            ...state,
            userFiles: action.userFiles,
        };
    }

    return defaultStreamState;
};

const StreamProvider = (props) => {
    const [streamState, dispatchStreamAction] = useReducer(streamReducer, defaultStreamState);

    const loadContractHandler = (streamContract) => {
        dispatchStreamAction({ type: 'CONTRACT', streamContract: streamContract });
        return streamContract;
    };

    const getStreamContractAbiHandler = (streamAbi) => {
        dispatchStreamAction({ type: 'GET_ABI', streamAbi: streamAbi });
    };

    const loadPaymentTokenAddressHandler = async (contract) => {
        const paymentToken = await readContract({
            address: contract.address,
            abi: streamState?.streamAbi,
            functionName: 'paymentToken',
        });

        dispatchStreamAction({ type: 'GETPAYMENTTOKEN', paymentToken: paymentToken });
        return paymentToken;
    };

    const loadPaymentTokenContractHandler = (token, abi) => {
        const paymentTokenContract = getContract({
            address: token,
            abi: abi,
        });

        dispatchStreamAction({ type: 'GETPAYMENTTOKENCONTRACT', paymentTokenContract: paymentTokenContract });
        return paymentTokenContract;
    };

    const loadUserWatchlistHandler = async (contract, address) => {
        const watchList = await readContract({
            address: contract.address,
            abi: streamState?.streamAbi,
            functionName: 'getWishLists',
            args: [address],
        });

        dispatchStreamAction({ type: 'LOAD_WATCHLIST', watchList: watchList });
        return watchList;
    };

    const loadUserFilesHandler = async (contract, address) => {
        const userFiles = await readContract({
            address: contract.address,
            abi: streamState?.streamAbi,
            functionName: 'getPlayList',
            args: [address],
        });

        dispatchStreamAction({ type: 'GET_USER_FILES', userFiles: userFiles });
        return userFiles;
    };

    const streamContext = {
        streamContract: streamState.streamContract,
        streamAbi: streamState.streamAbi,
        watchList: streamState.watchList,
        userFiles: streamState.userFiles,
        paymentTokenContract: streamState.paymentTokenContract,
        paymentToken: streamState.paymentToken,
        loadStreamContract: loadContractHandler,
        getStreamContractAbi: getStreamContractAbiHandler,
        loadUserFiles: loadUserFilesHandler,
        loadUserWatchlist: loadUserWatchlistHandler,
        loadPaymentTokenAddress: loadPaymentTokenAddressHandler,
        loadPaymentTokenContract: loadPaymentTokenContractHandler,
    };

    return <StreamContext.Provider value={streamContext}>{props.children}</StreamContext.Provider>;
};

export default StreamProvider;
