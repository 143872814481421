import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { englishTranslation } from '../translations/en';
import { frenchTranslation } from '../translations/fr';
import { italianTranslation } from '../translations/it';
import { chineseTranslation } from '../translations/zh';
import { spanishTranslation } from '../translations/es';
import { russianTranslation } from '../translations/ru';
import { polishTranslation } from '../translations/po';
import { turkeyTranslation } from '../translations/tk';
import { vietnamTranslation } from '../translations/vi';
import { persianTranslation } from '../translations/pr';
import { araboTranslation } from '../translations/ar';

i18n.use(initReactI18next).init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                ...englishTranslation,
            },
        },
        fr: {
            translation: {
                ...frenchTranslation,
            },
        },
        it: {
            translation: {
                ...italianTranslation,
            },
        },
        zh: {
            translation: {
                ...chineseTranslation,
            },
        },
        es: {
            translation: {
                ...spanishTranslation,
            },
        },
        ru: {
            translation: {
                ...russianTranslation,
            },
        },
        po: {
            translation: {
                ...polishTranslation,
            },
        },
        tk: {
            translation: {
                ...turkeyTranslation,
            },
        },
        vi: {
            translation: {
                ...vietnamTranslation,
            },
        },
        pr: {
            translation: {
                ...persianTranslation,
            },
        },
        ar: {
            translation: {
                ...araboTranslation,
            },
        },
    },
});

export default i18n;
