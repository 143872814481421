import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useFiles from '../../hooks/useFiles';
import Hero from './Hero';
import FileReviews from './FileReviews';

function FileSinglePage() {
    const { id } = useParams();
    const { files } = useFiles();

    const vidDetails = useMemo(() => {
        if (files.length > 0) {
            return files.filter((vid) => vid.id === Number(id))[0];
        } else {
            return null;
        }
    }, [files, id]);

    return (
        <>
            {vidDetails ? (
                <>
                    <Hero {...vidDetails} />
                    <FileReviews reviews={vidDetails?.reviews} />
                </>
            ) : (
                <p>Loading File</p>
            )}
        </>
    );
}

export default FileSinglePage;
