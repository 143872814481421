import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './scss/app.scss';
import 'aos/dist/aos.css';
import HomePage from './pages/home';
import FileSinglePage from './pages/file-single';
import FileSingleMockPage from './mock-components/FileSingleMock';
import MintVideoPage from './pages/mint';
import UserPage from './pages/account';
import Layout from './pages/Layout';
import AdminPage from './pages/admin';
import ActivitesPage from './pages/activity';
import FeaturesRequestsPage from './pages/features-requests';
import FAQsPage from './pages/faqs';
import SearchPage from './pages/search';
import MyListPage from './pages/watchlist';
import NotFound from './components/NotFound';
import BuyDifiPage from './pages/buyDifi';
import ContractInfoPage from './pages/contractinfo';

import * as bootstrap from 'bootstrap';
import useFiles from './hooks/useFiles';
import useWeb3 from './hooks/useWeb3';
import AboutUsPage from './pages/about-us';
import FilesPage from './pages/files';
import FilesCategoryPage from './pages/file-category';
window.bootstrap = bootstrap;

function App() {
    const { owner } = useFiles();
    const { account } = useWeb3();
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route exact path='/' element={<HomePage />} />
                    <Route path='/account' element={<UserPage />} />
                    <Route path='/mint' element={<MintVideoPage />} />
                    <Route path='/discover' element={<FilesPage />} />
                    <Route path='/files/category/:category' element={<FilesCategoryPage />} />
                    <Route path='/files/:id' element={<FileSinglePage />} />
                    <Route path='/file-mock' element={<FileSingleMockPage />} />
                    <Route path='/activities' element={<ActivitesPage />} />
                    <Route path='/watchlist' element={<MyListPage />} />
                    <Route path='/faqs' element={<FAQsPage />} />
                    <Route path='/search' element={<SearchPage />} />
                    <Route path='/about' element={<AboutUsPage />} />
                    <Route path='/features-requests' element={<FeaturesRequestsPage />} />
                    <Route path='/buydifi' element={<BuyDifiPage />} />
                    <Route path='/investors' element={<ContractInfoPage />} />
                    {owner === account && <Route path='/admin' element={<AdminPage />} />}
                    <Route path='/*' element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
